import { acceptHMRUpdate, defineStore } from 'pinia';
import * as MarketingApi from '@/api/marketing';
import { MarketingBanners } from '@pasta-evangelists/pasta-types';

interface StoreState {
  banners: MarketingBanners;
}

const filterActiveBannersByCategory = (
  banners: MarketingBanners,
  category: string
): MarketingBanners => {
  if (!banners.length) return [];
  return banners
    .filter(banner => category === banner.attributes.category && banner.attributes.active)
    .sort((a, b) => a.attributes.position - b.attributes.position);
};

const marketingBannersStore = defineStore({
  id: 'marketingBannersStore',
  state: (): StoreState => ({
    banners: [],
  }),
  getters: {
    getActiveProductBoxBanners(): MarketingBanners {
      return filterActiveBannersByCategory(this.banners, 'rk-menu');
    },
    getActiveMyAccountBanners(): MarketingBanners {
      return filterActiveBannersByCategory(this.banners, 'my-account');
    },
    getActiveCheckoutBanners(): MarketingBanners {
      return filterActiveBannersByCategory(this.banners, 'rk-checkout');
    },
    getActivePastaNowMenuBanners(): MarketingBanners {
      return filterActiveBannersByCategory(this.banners, 'pasta-now-menu');
    },
    getActivePastaNowCheckoutBanners(): MarketingBanners {
      return filterActiveBannersByCategory(this.banners, 'pasta-now-checkout');
    },
    getActiveAddressLookupBanners(): MarketingBanners {
      return filterActiveBannersByCategory(this.banners, 'address-lookup');
    },
  },
  actions: {
    async getMarketingBanners() {
      const result = await MarketingApi.getMarketingBanners();

      if (result.error) return;

      this.$patch(state => (state.banners = result.data));
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(marketingBannersStore, import.meta.hot));

export default marketingBannersStore;
