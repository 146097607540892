import { ApiResponse } from '@pasta-evangelists/pasta-types';
import axios from 'axios';

type ServerResponse = GeoLocationItem[];

export interface GeoLocationItem {
  addressline1: string;
  summaryline: string;
  organisation: string;
  addressline2: string;
  subbuildingname: string;
  number: string;
  uniquedeliverypointreferencenumber: string;
  premise: string;
  street: string;
  posttown: string;
  county: string;
  postcode: string;
  latitude: string;
  longitude: string;
  grideasting: string;
  gridnorthing: string;
  uprn: string;
  usrn: string;
}

interface AproxResponse {
  status: number;
  result: {
    postcode: string;
    quality: number;
    eastings: number;
    northings: number;
    country: string;
    nhs_ha: string;
    longitude: -1.439269;
    latitude: 55.011303;
    european_electoral_region: string;
    primary_care_trust: string;
    region: string;
    lsoa: string;
    msoa: string;
    incode: string;
    outcode: string;
    parliamentary_constituency: string;
    admin_district: string;
    parish: string;
    admin_county: null;
    date_of_introduction: string;
    admin_ward: 'Tynemouth';
    ced: null;
  };
}

const useGeoLocationFromAddress = () => {
  const client = axios.create({
    baseURL: `https://ws.postcoder.com/pcw/${
      import.meta.env.VITE_POSTCODER_API_KEY
    }/pafaddressbase/`,
  });

  const getLocationFromAddress = async (address: string) => {
    try {
      const result = await client.get<ServerResponse>(
        `${address}?format=json&lines=2&addtags=latitude,longitude`
      );

      if (result.data.length) {
        return result.data[0] || null;
      }
    } catch {
      return null;
    }
  };

  const getAproxLocationFromPostcode = async (
    postcode: string
  ): ApiResponse<{ latitude: string; longitude: string }> => {
    try {
      const response = await axios.get<AproxResponse>(
        `https://api.postcodes.io/postcodes/${postcode}`
      );
      const { latitude, longitude } = response.data.result;

      return {
        data: { latitude: latitude.toString(), longitude: longitude.toString() },
        error: null,
      };
    } catch {
      return { data: null, error: { message: `Can't find lat/long for postcode ${postcode}` } };
    }
  };

  return { getLocationFromAddress, getAproxLocationFromPostcode };
};

export default useGeoLocationFromAddress;
