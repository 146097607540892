import { axios, shopifyGraphQLClient } from '@/utils';
import {
  ObjectToCamel,
  ApiResponse,
  CustomerData,
  PromiseResponse,
  StoreCreditTransaction,
} from '@pasta-evangelists/pasta-types';
import { decamelizeKeys } from 'humps';
import { resetPassword } from '@/api/graphql/shopify/mutations/customer';
import { CustomerRecoverPayload, Maybe } from '@/api/graphql/generated/shopify';
import { trackCustomEvent } from '@/utils/analytics';

interface Login {
  email: string;
  password: string;
  redirectTo?: string;
}

interface LoginResponse {
  url: string;
}

interface SignUp {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  marketing: boolean;
}

interface SignUpData {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    email: string;
  };
}

interface SignUpResponse {
  data: SignUpData;
}

const addBaseUrlToString = (url: string) => {
  if (url.startsWith(import.meta.env.VITE_APP_BASE_URL)) return url;
  return `${import.meta.env.VITE_APP_BASE_URL}${url.startsWith('/') ? url : '/' + url}`;
};

export const login = async (credentials: Login): ApiResponse<LoginResponse> => {
  const redirectUrl = credentials.redirectTo
    ? addBaseUrlToString(credentials.redirectTo)
    : addBaseUrlToString('account');

  const params = {
    email: credentials.email,
    password: credentials.password,
    target_url: redirectUrl,
  };

  try {
    const { data } = await axios.post<ObjectToCamel<LoginResponse>>('sessions', params);
    return { data, error: null };
  } catch (e) {
    return { data: null, error: { message: "Email and Password don't match" } };
  }
};

export const register = async (user: SignUp): ApiResponse<SignUpResponse> => {
  const params = {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    password: user.password,
    accepts_emails: user.marketing,
    accepts_sms: user.marketing,
    accepts_mail: user.marketing,
  };
  try {
    const { data } = await axios.post<ObjectToCamel<SignUpResponse>>('customers', params);

    return { data: data, error: null };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message =
      e.response?.status === 400 && e.response?.data.error === 'invalid_email'
        ? 'Invalid email'
        : 'An error occurred while creating your account';
    return { data: null, error: { message: message } };
  }
};

export const logout = async (): ApiResponse<boolean> => {
  try {
    await axios.delete('sessions');
    trackCustomEvent('new_or_existing', {
      new_or_existing_TW: 'Undefined',
      new_or_existing_RK: 'Undefined',
      new_or_existing_Shopify: 'Undefined',
      shopify_id: null,
    });
    return { data: true, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Logout request failed' } };
  }
};

interface CustomerResponse {
  customer: {
    data: CustomerData;
  };
}

export const userDetails = async (): PromiseResponse<CustomerData> => {
  try {
    const result = await axios.get<ObjectToCamel<CustomerResponse>>('/me');
    return { ...result.data.customer.data };
  } catch (e) {
    throw new Error('Something went wrong');
  }
};

export interface MarketingParams {
  acceptsEmails: boolean;
  acceptsSms: boolean;
  acceptsMail: boolean;
}

export const updateMarketingPreferences = async (
  params: MarketingParams
): PromiseResponse<true> => {
  try {
    await axios.put('/me', decamelizeKeys(params));
    return true;
  } catch (e) {
    throw new Error('Something went wrong');
  }
};

interface PasswordParams {
  currentPassword: string;
  newPassword: string;
}

export const updatePassword = async (params: PasswordParams): ApiResponse<true> => {
  try {
    await axios.put('/customers/password', decamelizeKeys(params));
    return { data: true, error: null };
  } catch (e) {
    return { data: null, error: { message: "Couldn't update password" } };
  }
};

export const resetUserPassword = async (email: string): ApiResponse<true> => {
  try {
    await shopifyGraphQLClient.request<Maybe<CustomerRecoverPayload>>(resetPassword, {
      email: email,
    });

    return { data: true, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong' } };
  }
};

export const loginWithToken = async (token: string): ApiResponse<true> => {
  try {
    await axios.post('sessions/token', { token });
    return { data: true, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong' } };
  }
};

export const updatePaperlessOption = async (paperless: boolean): ApiResponse<true> => {
  try {
    await axios.put('/customers/paperless', { paperless });
    return { data: true, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong' } };
  }
};

interface SocialLoginParams {
  token: string;
  redirectTo?: string;
}

const generateRedirectUrl = (redirectTo?: string) => {
  return redirectTo ? addBaseUrlToString(redirectTo) : addBaseUrlToString('account');
};

const processParamsForSocialLogin = (params: SocialLoginParams) => {
  return {
    token: params.token,
    target_url: generateRedirectUrl(params.redirectTo),
  };
};

interface LoginWithGoogleParams {
  accessToken: string;
  redirectTo?: string;
}

export const loginWithGoogle = async (
  params: LoginWithGoogleParams
): ApiResponse<LoginResponse> => {
  try {
    const { data } = await axios.post('sessions/google', {
      access_token: params.accessToken,
      target_url: generateRedirectUrl(params.redirectTo),
    });
    return { data: data, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong' } };
  }
};

export const loginWithFacebook = async (params: SocialLoginParams): ApiResponse<LoginResponse> => {
  try {
    const { data } = await axios.post('sessions/facebook', processParamsForSocialLogin(params));
    return { data: data, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong' } };
  }
};

interface LoginWithAppleParams {
  code: string;
  redirectTo?: string;
  firstName?: string;
  lastName?: string;
}

export const loginWithApple = async (params: LoginWithAppleParams) => {
  const redirectUrl = params.redirectTo
    ? addBaseUrlToString(params.redirectTo)
    : addBaseUrlToString('account');

  try {
    const { data } = await axios.post<ObjectToCamel<LoginResponse>>('sessions/apple', {
      code: params.code,
      first_name: params.firstName,
      last_name: params.lastName,
      target_url: redirectUrl,
    });

    return { data, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong' } };
  }
};

export type UpdateNameParams =
  | {
      firstName: string;
    }
  | { lastName: string };

export const updateName = async (params: UpdateNameParams): PromiseResponse<true> => {
  try {
    await axios.put('/me', decamelizeKeys(params));
    return true;
  } catch (e) {
    throw new Error('Something went wrong');
  }
};

export const updatePhone = async (phone: string): PromiseResponse<true> => {
  try {
    await axios.put('customers/phone', { phone });
    return true;
  } catch (e) {
    throw new Error('Something went wrong');
  }
};

export const passwordReset = async (email: string): ApiResponse<true> => {
  try {
    await axios.post('customers/password_reset', { email });
    return { data: true, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong' } };
  }
};

type StoreCreditTransactionsResponse = { data: StoreCreditTransaction[] };
type StoreCreditTransactions = ObjectToCamel<StoreCreditTransaction>[];

export const getStoreCreditTransactions = async (): PromiseResponse<StoreCreditTransactions> => {
  try {
    const { data } = await axios.get<ObjectToCamel<StoreCreditTransactionsResponse>>(
      `store_credit_transactions`
    );
    return data.data;
  } catch (e) {
    throw new Error("Couldn't get store credit transaction history");
  }
};

export const setGuestCookie = async (token: string): ApiResponse<true> => {
  try {
    await axios.post('sessions/guest_token', { token });
    return { data: true, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong' } };
  }
};

export const requestInvite = async (customerId: string): PromiseResponse<true> => {
  try {
    await axios.post('customers/account_setup', { customer_id: customerId });
    return true;
  } catch (e) {
    throw new Error('Something went wrong');
  }
};
