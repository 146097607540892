<template>
  <button
    v-if="'children' in link && ((link.needsLogin && isLoggedIn) || !link.needsLogin)"
    class="inline-flex items-center justify-between"
    :class="{ 'text-burnt-sienna': !smallText, 'text-sm text-off-black': smallText }"
    @click="openSubmenu(link)"
  >
    {{ link.label }}
    <PlusIcon class="size-4 text-terracota-dark" />
  </button>
  <router-link
    v-else-if="'name' in link && ((link.needsLogin && isLoggedIn) || !link.needsLogin)"
    :to="{ name: link.name }"
    class="inline-flex items-center"
    :class="{ 'text-burnt-sienna': !smallText, 'text-sm text-off-black': smallText }"
    @click="$emit('close')"
  >
    {{ link.label }}
  </router-link>
  <a
    v-else-if="'path' in link"
    class="inline-flex items-center"
    :class="{ 'text-burnt-sienna': !smallText, 'text-sm text-off-black': smallText }"
    :href="link.path"
    target="_blank"
    @click="$emit('close')"
  >
    {{ link.label }}
  </a>
  <slot v-else-if="'slotName' in link" :name="link.slotName"></slot>
  <hr
    v-if="'hasDivider' in link && (('needsLogin' in link && isLoggedIn) || !('needsLogin' in link))"
    class="my-4 border-sienna-muted"
  />
</template>

<script lang="ts" setup>
  import { ChildLink, NavigationLink } from '@/types/navigation';
  import { PlusIcon } from '@heroicons/vue/solid';

  interface Props {
    link: NavigationLink;
    isLoggedIn: boolean;
    smallText?: boolean;
  }

  interface Emits {
    (e: 'close'): void;
    (e: 'openSubmenu', submenu: { title: string; links: ChildLink[] }): void;
  }

  defineProps<Props>();
  const emit = defineEmits<Emits>();

  const openSubmenu = (link: NavigationLink) => {
    if ('children' in link) {
      emit('openSubmenu', { title: link.label, links: link.children });
    }
  };
</script>
