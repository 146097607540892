import { useMutation, useQueryClient } from '@tanstack/vue-query';
import * as BasketApi from '@/api/basket';
import { MutationConfig } from '@/types';
import { queries } from '@/api/queries';

type SkipOptions = {
  config?: MutationConfig<typeof BasketApi.addItem>;
};

const useAddItem = ({ config }: SkipOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: BasketApi.addItem,
    onSuccess: (result, { basketId }) => {
      queryClient.setQueriesData(queries.baskets.byId(basketId), result);
      queryClient.invalidateQueries(queries.baskets.byId(basketId));
    },
  });
};

export default useAddItem;
