<template>
  <div class="flex flex-col justify-between min-h-full grow">
    <div>
      <NavBarMobileSubmenu
        :is-open="isSubmenuOpen"
        :title="submenu.title"
        :links="submenu.links"
        :is-logged-in="isLoggedIn"
        @close-submenu="closeSubmenu"
        @close-whole-menu="closeWholeMenu"
      />
      <div v-if="shouldShowMainMenu" class="flex flex-col mt-6 space-y-4">
        <NavBarMobileMenuItem
          :link="restaurantsLink"
          :is-logged-in="isLoggedIn"
          @open-submenu="openSubmenu($event)"
          @close="emit('close')"
        />
        <button
          id="menuTW_mobileNavBar_TWtoggle"
          class="inline-flex items-center justify-between text-burnt-sienna"
          :class="{ 'opacity-50': shouldShowTakeawayAsDisabled }"
          :disabled="goToPastaNowMutation.isPending.value"
          @click="handleTakeawayButtonClick"
        >
          <BaseLoadingIcon v-if="goToPastaNowMutation.isPending.value" class="size-5" />
          <span v-else>Takeaway menu</span>
        </button>

        <template v-for="navigationLink in mainLinks" :key="navigationLink.label">
          <NavBarMobileMenuItem
            :link="navigationLink"
            :is-logged-in="isLoggedIn"
            @open-submenu="openSubmenu($event)"
            @close="emit('close')"
          >
            <template #howItWorksBtn>
              <button class="inline-flex items-center text-burnt-sienna" @click="openModal">
                How recipe kits work
              </button>
            </template>
          </NavBarMobileMenuItem>
        </template>
      </div>
    </div>

    <div v-if="shouldShowMainMenu">
      <router-link
        v-if="!isLoggedIn"
        to="/social-login"
        class="flex items-center justify-center w-full px-6 py-4 text-white bg-primary-green hover:bg-cavolo-green"
        @click="$emit('close')"
      >
        Log in
      </router-link>

      <router-link
        v-if="!isLoggedIn"
        to="/social-login"
        class="flex items-center justify-center w-full px-6 py-4 mt-6 border bg-light-linen border-sienna-muted text-burnt-sienna hover:bg-linen"
        @click="$emit('close')"
      >
        Sign up
      </router-link>

      <button
        v-else
        class="flex items-center justify-center w-full px-6 py-4 border bg-light-linen border-sienna-muted text-burnt-sienna hover:bg-linen"
        @click="$emit('logout')"
      >
        Log out
      </button>
    </div>
  </div>
  <NavBarTakeawayUnavailable
    v-model:is-open="showTakeawayUnavailableModal"
    @click="closeWholeMenu"
  />
</template>

<script lang="ts" setup>
  import { experienceLinks, giftMenuLinks, guideLinks, storesLinks } from '@/constants/links';
  import router from '@/router';
  import { ChildLink, NavigationLink } from '@/types/navigation';
  import { trackCustomEvent } from '@/utils/analytics';
  import { useRoute } from 'vue-router';
  import usePastaNowBasket from '@/stores/pastaNowBasketStore';
  import useChangePastaNowAddress from '@/composables/useChangePastaNowAddress';
  import useGeoLocationFromAddress from '@/composables/useGeoLocationFromAddress';
  import * as RestaurantApi from '@/api/restaurants';
  import { useMutation } from '@tanstack/vue-query';
  import useBasketStore from '@/stores/basketStore';
  import useUpdateRecipeKitBasket from '@/api/mutations/pensa/updateRecipeKitBasket';

  interface Props {
    isLoggedIn?: boolean;
    showMyAccountLinks?: boolean;
  }

  interface Emits {
    (e: 'close'): void;
    (e: 'openModal'): void;
    (e: 'logout'): void;
  }

  withDefaults(defineProps<Props>(), { isLoggedIn: false });
  const emit = defineEmits<Emits>();

  const route = useRoute();
  const pastaNowBasket = usePastaNowBasket();
  const basketStore = useBasketStore();
  const { clearBasketAndCreateNewOne } = useChangePastaNowAddress();
  const { getAproxLocationFromPostcode } = useGeoLocationFromAddress();
  const updateBasketMutation = useUpdateRecipeKitBasket();

  const submenu = reactive<{ title: string; links: ChildLink[] }>({
    title: '',
    links: [],
  });
  const shouldShowMainMenu = ref(true);
  const showTakeawayUnavailableModal = ref(false);

  const shouldShowTakeawayAsDisabled = Boolean(route.query.dtw);

  const myAccountLinks = computed(() => {
    return (
      router
        .getRoutes()
        .find(r => r.name === 'my-account')
        ?.children.filter(c => c.name !== 'my-account-dashboard')
        .map(link => {
          return {
            label: link.meta ? (link.meta.label as string) : '',
            name: link.name ? (link.name as string) : '',
            needsLogin: true,
          };
        }) ?? []
    );
  });

  const mainLinks = computed((): NavigationLink[] => {
    const links = [
      { label: 'Recipe kit menu', name: 'product-box', hasDivider: true },
      { label: ' My account', hasDivider: true, needsLogin: true, children: myAccountLinks.value },
      { slotName: 'howItWorksBtn' },
      { label: 'Events', children: experienceLinks },
      { label: 'Gifts', children: giftMenuLinks },
      { label: 'Guides & inspiration', children: guideLinks },
      { label: 'FAQs', path: 'https://pastaevangelists.com/pages/contact-us', hasDivider: true },
    ];

    return links;
  });

  const restaurantsLink = computed(() => {
    return { label: 'Restaurants', children: storesLinks };
  });

  const openModal = () => {
    trackCustomEvent('howItWorksClicked');
    emit('openModal');
  };

  const openSubmenu = (menu: { title: string; links: ChildLink[] }) => {
    shouldShowMainMenu.value = false;
    submenu.title = menu.title;
    submenu.links = menu.links;
  };

  const isSubmenuOpen = computed(() => Boolean(submenu.links.length));

  const closeSubmenu = () => {
    submenu.links = [];
    submenu.title = '';
    shouldShowMainMenu.value = true;
  };

  const closeWholeMenu = () => {
    closeSubmenu();
    emit('close');
  };

  const goToPastaNow = async () => {
    if (
      basketStore.address?.zip &&
      pastaNowBasket.address?.zip === basketStore.address.zip &&
      pastaNowBasket.restaurantId
    ) {
      return true;
    }

    let lat: string | null = basketStore.address?.lat || null;
    let lon: string | null = basketStore.address?.lon || null;

    if ((basketStore.address && !basketStore.address?.lat) || basketStore.address?.lon) {
      const coordinatesResult = await getAproxLocationFromPostcode(basketStore.address.zip!);

      if (!coordinatesResult.data) {
        throw new Error('Could not get coordinates from address');
      }

      const { latitude, longitude } = coordinatesResult.data;

      lat = latitude;
      lon = longitude;

      if (basketStore.basketKey) {
        await updateBasketMutation.mutateAsync({
          address: {
            lat: latitude,
            lon: longitude,
          },
          id: basketStore.basketKey!,
        });
      }
    }

    const availableRestaurants = await RestaurantApi.getAvailableStores({
      lat: lat!,
      lon: lon!,
      fulfillmentType: 'delivery',
    });

    if (!availableRestaurants.length) {
      throw new Error('No restaurants available');
    }

    await clearBasketAndCreateNewOne({
      address: {
        address1: basketStore.address?.address1 || '',
        address2: basketStore.address?.address2 || '',
        city: basketStore.address?.city || '',
        country: basketStore.address?.country || '',
        company: basketStore.address?.company || '',
        region: basketStore.address?.region || '',
        zip: basketStore.address?.zip || '',
        regionCode: basketStore.address?.regionCode || '',
        countryCode: basketStore.address?.countryCode || '',
        firstName: basketStore.address?.firstName || '',
        lastName: basketStore.address?.lastName || '',
      },
      restaurantId: availableRestaurants[0].id,
      coordinates: {
        lat: lat!,
        lon: lon!,
      },
    });

    return true;
  };

  const goToPastaNowMutation = useMutation({
    mutationFn: goToPastaNow,
    onSuccess: () => {
      router.push('pasta-now');
    },
    onError: () => {
      router.push('pasta-now-search-address');
    },
    onSettled: () => {
      emit('close');
    },
  });

  const handleTakeawayButtonClick = () => {
    if (shouldShowTakeawayAsDisabled) {
      showTakeawayUnavailableModal.value = true;
      return;
    }
    goToPastaNowMutation.mutate();
  };
</script>
