import { useScriptTag } from '@vueuse/core';
import { useCookies } from '@vueuse/integrations/useCookies';
import dayjs from 'dayjs';

interface TokenResponse {
  access_token: string;
  expires_in: string;
  hd: string;
  prompt: string;
  token_type: string;
  scope: string;
  state: string;
  error: string;
  error_description: string;
  error_uri: string;
}

const gStateCookie = useCookies().get('g_state');
const isScriptFullyLoaded = ref(false);

export const useGoogleAuth = () => {
  const handleSignIn = (): Promise<TokenResponse> => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        window.google.accounts.oauth2
          .initTokenClient({
            client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
            scope: 'email profile',
            callback: response => {
              if (response.access_token) {
                resolve(response);
              } else {
                reject(response);
              }
            },
            error_callback: response => {
              reject(response);
            },
          })
          .requestAccessToken();
      } else {
        reject(new Error('Google is not loaded'));
      }
    });
  };

  return {
    isScriptFullyLoaded: readonly(isScriptFullyLoaded),
    handleSignIn,
  };
};

export default {
  install: () => {
    useScriptTag('https://accounts.google.com/gsi/client', () => {
      if (!gStateCookie || !gStateCookie.i_p || dayjs().isAfter(new Date(gStateCookie.i_p)))
        isScriptFullyLoaded.value = true;
    });
  },
};
