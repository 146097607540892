export const DELIVERY_LOCAL_STORE_KEY = 'delivery-store-key';

export const SELECTED_SUB_STORE_KEY = 'selected-sub-store-key';

export const PRODUCT_BOX_REDIRECT_KEY = 'productBoxRedirect';

export const NEW_CUSTOMER_CUSTOM_DIMENSION_KEY = 'new-customer-custom-dimension-key-v2';

export const SENTRY_ERRORS_TO_IGNORE = [
  /TypeError: Failed to fetch dynamically imported module.*/i,
  /TypeError: Failed to fetch.*/i,
  /Error: Unable to preload CSS for.*/i,
  /.*Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type.*/i,
  /TypeError: Load failed.*/i,
  /TypeError: Importing a module script failed.*/i,
  /Error: Unable to preload CSS for.*/i,
  "TypeError: 'text/html' is not a valid JavaScript MIME type.",
  /TypeError: Network request failed.*/i,
  /CustomEvent: Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target.*/i,
  "TypeError: undefined is not an object (evaluating 'r.scrollTop')",
  "ReferenceError: Can't find variable: IntersectionObserver",
  "TypeError: Promise.all(a.map((e=>this.fetchMenu(e)))).finally is not a function. (In 'Promise.all(a.map((e=>this.fetchMenu(e)))).finally((()=>{this.isFetchingFutureMenus=!1}))', 'Promise.all(a.map((e=>this.fetchMenu(e)))).finally' is undefined)",
  'TypeError: error loading dynamically imported module',
  'Error: InitializationError',
  'Error: Network Error',
  'Error: AbortError: Aborted',
  'TypeError: Illegal invocation',
  'TypeError: cancelled',
  'TypeError: Type error',
  'ReferenceError: globalThis is not defined',
  'TypeError: NetworkError when attempting to fetch resource.',
  /The operation is insecure/i,
  /Network connection error/i,
  /Failed to load Stripe.js/i,
  /Discount is not applicable to the products in your cart/i,
  /No usable automatic discounts for cart/i,
  /Subscription not active/i,
  /Failed to load the JS script of the agent/i,
];

export const APPLE_CREDENTIALS_KEY = 'apple-credentials-key';

export const REFER_A_FRIEND_REMINDER_KEY = 'refer-a-friend-reminder-key';

export const NEW_PREFERENCES_UI_REMINDER_KEY = 'new-preferences-ui-reminder-key';

export const SAVED_SUGGESTIONS_KEY = 'pasta-now-previous-suggestions-v2';

export const OOS_ALT_VOCHER = 'OOS-20';

export const RANDOM_UK_PHONE_NUMBER = '02079460340';

export const FRAUD_ALTERNATIVE_VOUCHER = '10EXTRA';
