const isMounted = ref(false);

const useMarketingObservable = () => {
  return {
    isMounted: readonly(isMounted),
    setIsMounted: (value: boolean) => {
      isMounted.value = value;
    },
  };
};
export default useMarketingObservable;
