import { getParamsFromObject } from '@/utils';
import { isIOS } from '@/utils/platform';
import { breakpointsTailwind, useBreakpoints, useScriptTag } from '@vueuse/core';
import { App } from 'vue';
import { useRoute } from 'vue-router';

interface Options {
  appId: string;
  xfbml?: boolean;
  cookie?: boolean;
  version?: string;
}

const getRedirectUrl = () => {
  const { protocol, host } = window.location;
  return `${protocol}//${host}`;
};

const isSdkLoaded = ref(false);

export const useFacebookLogin = () => {
  const breakpoints = useBreakpoints(breakpointsTailwind);

  const isMobile = breakpoints.isSmaller('lg');

  function checkLoginState(response: fb.StatusResponse) {
    if (response.authResponse) {
      return { data: response.authResponse, error: null };
    } else {
      return { data: null, error: response.status };
    }
  }

  type Error = fb.LoginStatus | 'redirected_client';

  const handleRedirect = ():
    | Promise<{ data: fb.AuthResponse | null; error: Error | null }>
    | undefined => {
    if (!isSdkLoaded.value) {
      return;
    }
    return new Promise(resolve =>
      window.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          resolve(checkLoginState(response));
        } else {
          window.FB.login(
            re => {
              resolve(checkLoginState(re));
            },
            {
              scope: 'public_profile,email',
              return_scopes: false,
            }
          );
        }
      })
    );
  };

  type HandleClickParams = { disableMobileRedirect?: boolean; extraInfo?: string } | undefined;

  const handleClick = (
    { disableMobileRedirect, extraInfo }: HandleClickParams = {
      disableMobileRedirect: true,
      extraInfo: '',
    }
  ): Promise<{ data: fb.AuthResponse | null; error: Error | null }> | undefined => {
    if (!isSdkLoaded.value) {
      return;
    }

    const params = {
      client_id: import.meta.env.VITE_FACEBOOK_APP_ID,
      redirect_uri: getRedirectUrl() + '/auth-facebook-cb',
      state: JSON.stringify({ from: 'facebookdirect', extraInfo }),
      return_scopes: false,
      scope: 'public_profile,email',
      response_type: 'code',
      auth_type: '',
    };

    // we need a different function for iOS because if we
    // wrap `window.FB.login` inside the `window.FB.getLoginStatus` callback
    // it doesn't trigger the popup on iOS
    if (isIOS()) {
      return new Promise(resolve => {
        window.FB.login(
          re => {
            resolve(checkLoginState(re));
          },
          {
            scope: 'public_profile,email',
            return_scopes: false,
          }
        );
      });
    }

    if (isMobile && !disableMobileRedirect) {
      window.location.href = `https://www.facebook.com/dialog/oauth${getParamsFromObject(params)}`;
      return new Promise(resolve => resolve({ data: null, error: 'redirected_client' }));
    }

    return new Promise(resolve =>
      window.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          resolve(checkLoginState(response));
        } else {
          window.FB.login(
            re => {
              resolve(checkLoginState(re));
            },
            {
              scope: 'public_profile,email',
              return_scopes: false,
            }
          );
        }
      })
    );
  };

  const isFacebookRedirect = () => {
    const route = useRoute();
    const code = route.query.code as string | undefined;
    const state = route.query.state as string | undefined;

    if (code && state) {
      try {
        const stateObj = JSON.parse(state);
        if (stateObj.from === 'facebookdirect') {
          return true;
        }
      } catch (e) {
        return false;
      }
    }
  };
  return {
    isSdkLoaded: readonly(isSdkLoaded),
    facebookLoginRequest: handleClick,
    handleRedirect,
    isFacebookRedirect,
  };
};

export default {
  install: (_: App, { appId, xfbml = true, cookie = true, version = '13.0' }: Options) => {
    const setFbAsyncInit = () => {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: appId,
          cookie,
          xfbml: xfbml,
          version: `v${version}`,
        });
        isSdkLoaded.value = true;
      };
    };

    if (isSdkLoaded.value) {
      return;
    }

    setFbAsyncInit();
    useScriptTag('https://connect.facebook.net/en_US/sdk.js', () => {
      isSdkLoaded.value = true;
    });
  },
};
