/* eslint-disable @typescript-eslint/no-explicit-any */
import { http, HttpResponse } from 'msw';
import {
  companyAddress,
  residentialAddress,
  addressSuggestions,
  addressSuggestionsForContainer,
} from './data/loqate';
import {
  LOQATE_ADDRESS_FINDER_BASE_URL,
  LOQATE_FIND_URL,
  LOQATE_RETRIEVE_URL,
} from '@/constants/loqate';
import axios from 'axios';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';
import { ObjectToSnake } from '@pasta-evangelists/pasta-types';
import type { CreateSubscriptionParams } from '@/api/checkout';
import type { SaveAddressParams } from '@/api/address';
import { EMAIL_VALIDATION_BASE_URL, EMAIL_VALIDATION_URL } from '@/constants/email';
import { basketBuilder, basketItemBuilder } from '../cypress/support/builders';

const getAddressSugestions = () => {
  return http.get(`${LOQATE_ADDRESS_FINDER_BASE_URL}/${LOQATE_FIND_URL}`, ({ params }) => {
    const { lastId } = params;

    if (lastId) {
      return HttpResponse.json(addressSuggestionsForContainer);
    }
    return HttpResponse.json(addressSuggestions);
  });
};

const getAddressInfo = () => {
  return http.get(`${LOQATE_ADDRESS_FINDER_BASE_URL}/${LOQATE_RETRIEVE_URL}`, ({ params }) => {
    const { id } = params;

    // id of address of a company
    if (id === 'GB|RM|A|14944808') {
      return HttpResponse.json(companyAddress);
    }
    return HttpResponse.json(residentialAddress);
  });
};

const data = qs.stringify({
  amount: '2000',
  currency: 'gbp',
  'payment_method_types[]': 'card',
});
const config = {
  method: 'post',
  url: 'https://api.stripe.com/v1/payment_intents',
  headers: {
    Authorization: `Bearer ${import.meta.env.VITE_STRIPE_PRIVATE_KEY}`,
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  data: data,
};

const createOrder = () => {
  return http.post(`${import.meta.env.VITE_API_BASE_URL}/orders/basket`, async () => {
    try {
      const result = await axios(config as any);
      return HttpResponse.json({
        payment_intent: (result.data as { client_secret: string }).client_secret,
        order_ids: [],
      });
    } catch (e) {
      return HttpResponse.json(e as any, { status: 500 });
    }
  });
};

const createAddress = () => {
  return http.post<never, ObjectToSnake<SaveAddressParams>>(
    `${import.meta.env.VITE_API_BASE_URL}/addresses`,
    async ({ request }) => {
      const body = await request.json();
      if (!request.body) {
        return new HttpResponse(null, { status: 400 });
      }
      const payload = {
        data: {
          id: uuidv4(),
          type: 'address',
          attributes: {
            address1: body.address1,
            address2: body.address2,
            city: body.city,
            zip: body.zip,
            country: 'United Kingdom',
            country_code: 'uk',
            phone: body.phone,
            first_name: 'John',
            last_name: 'Doe',
            default: true,
            region: body.region,
            region_code: body.region_code,
            company: body.company,
            ext_id: 1,
          },
        },
      };
      return HttpResponse.json(payload);
    }
  );
};

const createSubscription = () => {
  return http.post<never, ObjectToSnake<CreateSubscriptionParams>>(
    `${import.meta.env.VITE_API_BASE_URL}/subscriptions`,
    async ({ request }) => {
      const body = await request.json();
      if (
        !body.billing_address_id ||
        !body.delivery_note ||
        !body.schedule_attributes ||
        !body.shipping_address_id
      ) {
        return new HttpResponse(null, { status: 400 });
      }

      const subId = uuidv4();
      const scheduledId = uuidv4();

      const response = {
        data: {
          id: subId,
          type: 'subscription',
          attributes: {
            name: null,
            ext_id: null,
            state: 'created',
            number_of_recipes: 2,
            delivery_note: 'leave with concierge',
            cocktails: false,
            number_of_cocktails: 2,
            gluten_free: false,
            double_portion: false,
            pork: true,
            beef: true,
            lamb: true,
            game: true,
            crustaceans: true,
            fish: true,
            nuts: true,
            vegetarian: true,
            vegan: true,
            molluscs: true,
          },
          relationships: {
            schedule: { data: { id: scheduledId, type: 'subscription_schedule' } },
            customer: { data: {} },
            shipping_address: { data: {} },
            billing_address: { data: {} },
            payment_card: { data: null },
          },
        },
        included: [
          {
            id: scheduledId,
            type: 'subscription_schedule',
            attributes: { delivery_day: 'Wednesday', first_delivery: '2021-09-29', frequency: 2 },
            relationships: {
              subscription: { data: { id: subId, type: 'subscription' } },
              weeks: { data: [] },
            },
          },
        ],
      };

      return HttpResponse.json(response);
    }
  );
};

export const validateEmail = () => {
  return http.post(`${EMAIL_VALIDATION_BASE_URL}${EMAIL_VALIDATION_URL}`, ({ request }) => {
    const url = new URL(request.url);
    const email = url.searchParams.get('Email');

    if (email?.includes('invalid')) {
      return HttpResponse.json(
        {
          Items: [{ ResponseCode: 'Invalid' }],
        },
        { status: 200 }
      );
    }

    return HttpResponse.json(
      {
        Items: [{ ResponseCode: 'Valid' }],
      },
      { status: 200 }
    );
  });
};

export const getBasket = () => {
  return http.get(`${import.meta.env.BASE_URL}/baskets`, () => {
    const firstItem = basketItemBuilder({ overrides: { meal_week: '2021-05-31' } });
    const secondItem = basketItemBuilder({ overrides: { meal_week: '2021-05-31' } });

    const basket = basketBuilder([firstItem, secondItem]);

    return HttpResponse.json(basket);
  });
};

const devOnlyHandlers = import.meta.env.PROD
  ? []
  : [
      createOrder(),
      createAddress(),
      createSubscription(),
      getAddressSugestions(),
      getAddressInfo(),
    ];

const handlers = [validateEmail(), ...devOnlyHandlers];

export default handlers;
