<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 14c3.312 0 6-2.688 6-6s-2.688-6-6-6-6 2.688-6 6 2.688 6 6 6Zm2.143-7.5a2.144 2.144 0 0 1-4.286 0 2.142 2.142 0 0 1 4.287 0Zm-5.91 5.31a4.073 4.073 0 0 1 7.534 0A5.34 5.34 0 0 1 8 13.356a5.34 5.34 0 0 1-3.767-1.548Z"
      fill="currentColor"
    />
  </svg>
</template>
