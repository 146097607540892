import * as OrdersApi from '@/api/orders';
import { queries } from '@/api/queries';
import { MutationConfig } from '@/types';
import normalize from '@/utils/normalize';
import {
  NormalizedOrderWithAddressIncluded,
  NormalizedOrderWithPaymentInfo,
} from '@pasta-evangelists/pasta-types/dist/order';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { useCloned } from '@vueuse/core';
import merge from 'lodash.merge';

type CreateVoucherOptions = {
  config?: MutationConfig<typeof OrdersApi.updateUpcomingOrder>;
};

const useEditUpcomingOrder = ({ config }: CreateVoucherOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: OrdersApi.updateUpcomingOrder,
    onSuccess: result => {
      const normalizedResult = normalize<NormalizedOrderWithPaymentInfo>(result.data);
      queryClient.setQueriesData<NormalizedOrderWithAddressIncluded | null>(
        queries.orders.upcoming,
        orders => {
          const { cloned: tempOrders } = useCloned(orders);
          if (tempOrders.value) {
            tempOrders.value = merge({}, tempOrders.value, normalizedResult);
          }
          return tempOrders.value;
        }
      );
      queryClient.invalidateQueries(queries.orders.upcoming);
    },
  });
};

export default useEditUpcomingOrder;
