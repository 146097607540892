<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24" fill="none">
    <path stroke="#CDB4A1" d="M.5.5h39v23H.5z" />
    <path
      fill="#253B80"
      d="M16.217 9.976h-1.66a.23.23 0 0 0-.228.195l-.671 4.257a.138.138 0 0 0 .136.16h.793a.23.23 0 0 0 .228-.195l.181-1.149a.23.23 0 0 1 .228-.194h.525c1.094 0 1.725-.53 1.89-1.578.074-.46.003-.82-.212-1.072-.236-.277-.654-.424-1.21-.424Zm.192 1.555c-.091.596-.546.596-.986.596h-.251l.176-1.113a.138.138 0 0 1 .136-.117h.115c.3 0 .583 0 .729.171.087.102.114.254.08.463ZM21.18 11.512h-.795a.139.139 0 0 0-.137.117l-.035.222-.056-.08c-.172-.25-.556-.334-.939-.334-.878 0-1.629.665-1.775 1.599-.076.465.032.91.296 1.221.243.286.59.404 1.002.404.708 0 1.1-.455 1.1-.455l-.035.221a.138.138 0 0 0 .136.16h.716a.23.23 0 0 0 .228-.194l.43-2.722a.138.138 0 0 0-.136-.16Zm-1.108 1.547a.886.886 0 0 1-.898.76c-.23 0-.415-.075-.533-.215-.118-.14-.162-.338-.125-.559a.89.89 0 0 1 .89-.765c.227 0 .41.075.531.217.121.143.17.342.134.562ZM25.413 11.512h-.798a.231.231 0 0 0-.191.101l-1.102 1.623-.467-1.56a.231.231 0 0 0-.222-.164h-.785a.139.139 0 0 0-.131.183l.88 2.582-.827 1.168a.138.138 0 0 0 .113.219h.797a.23.23 0 0 0 .19-.1l2.657-3.835a.138.138 0 0 0-.114-.217Z"
    />
    <path
      fill="#179BD7"
      d="M28.059 9.976h-1.66a.23.23 0 0 0-.228.195l-.672 4.257a.138.138 0 0 0 .137.16h.852c.079 0 .147-.058.159-.137l.19-1.207a.23.23 0 0 1 .228-.194h.526c1.093 0 1.724-.53 1.89-1.578.074-.46.002-.82-.213-1.072-.235-.277-.654-.424-1.21-.424Zm.191 1.555c-.09.596-.545.596-.986.596h-.25l.176-1.113a.138.138 0 0 1 .136-.117h.115c.3 0 .583 0 .729.171.087.102.114.254.08.463ZM33.02 11.512h-.794a.138.138 0 0 0-.136.117l-.035.222-.056-.08c-.172-.25-.556-.334-.939-.334-.878 0-1.628.665-1.775 1.599-.075.465.032.91.296 1.221.243.286.59.404 1.002.404.708 0 1.1-.455 1.1-.455l-.035.221a.138.138 0 0 0 .137.16h.715a.23.23 0 0 0 .228-.194l.43-2.722a.139.139 0 0 0-.137-.16Zm-1.107 1.547a.886.886 0 0 1-.897.76c-.23 0-.416-.075-.534-.215-.117-.14-.162-.338-.125-.559a.89.89 0 0 1 .891-.765c.226 0 .41.075.53.217.122.143.17.342.135.562ZM33.958 10.093l-.681 4.335a.138.138 0 0 0 .136.16h.685a.23.23 0 0 0 .228-.196l.672-4.256a.138.138 0 0 0-.136-.16h-.768a.139.139 0 0 0-.136.117Z"
    />
    <path
      fill="#253B80"
      d="m6.763 15.415.127-.807-.283-.006h-1.35l.938-5.95a.077.077 0 0 1 .076-.065H8.55c.755 0 1.277.156 1.55.467.127.145.208.298.248.465.041.176.042.386.002.642l-.003.019v.164l.127.072a.897.897 0 0 1 .259.197c.109.125.18.283.21.47.03.194.02.423-.03.683-.059.3-.153.56-.28.773a1.59 1.59 0 0 1-.443.486c-.169.12-.37.21-.597.269-.22.057-.47.086-.745.086h-.178a.537.537 0 0 0-.527.45l-.013.072-.224 1.421-.01.053c-.003.016-.008.024-.015.03a.038.038 0 0 1-.023.009H6.763Z"
    />
    <path
      fill="#179BD7"
      d="M10.595 10.199a4.146 4.146 0 0 1-.024.133c-.3 1.542-1.327 2.075-2.64 2.075h-.667a.324.324 0 0 0-.321.275l-.342 2.169-.097.615a.17.17 0 0 0 .169.197h1.185c.14 0 .26-.102.281-.24l.012-.06.223-1.416.014-.078a.285.285 0 0 1 .282-.24h.177c1.148 0 2.047-.467 2.31-1.815.11-.564.052-1.034-.238-1.365a1.132 1.132 0 0 0-.324-.25Z"
    />
    <path
      fill="#222D65"
      d="M10.28 10.074a2.326 2.326 0 0 0-.292-.065 3.709 3.709 0 0 0-.589-.043H7.615a.283.283 0 0 0-.282.24l-.38 2.405-.01.07a.324.324 0 0 1 .32-.274h.669c1.312 0 2.339-.533 2.64-2.075l.023-.133a1.599 1.599 0 0 0-.315-.125Z"
    />
    <path
      fill="#253B80"
      d="M7.333 10.207a.284.284 0 0 1 .282-.241h1.784a3.706 3.706 0 0 1 .74.071 2.329 2.329 0 0 1 .209.058c.088.029.17.064.247.104.089-.57-.001-.958-.31-1.31-.339-.386-.952-.551-1.736-.551H6.272a.325.325 0 0 0-.322.275l-.948 6.011a.196.196 0 0 0 .193.227H6.6l.353-2.24.38-2.404Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>
