import { gql } from 'graphql-request';

export const getProductQuery = gql`
  query GetProduct($id: ID!) {
    product(id: $id) {
      title
      descriptionHtml
      id
      tags
      productType
      availableForSale
      images(first: 5) {
        edges {
          node {
            url
            id
            altText
          }
        }
      }
      variants(first: 2) {
        edges {
          node {
            id
            sku
            availableForSale
            selectedOptions {
              name
              value
            }
            availableForSale
            priceV2 {
              amount
            }
            quantityAvailable
          }
        }
      }
    }
  }
`;
