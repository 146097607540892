import { axios, shopifyGraphQLClient } from '@/utils';
import { getWeeklyMenusKeys } from '@/utils/date';
import { WeeklyReviewServerResponse } from '@pasta-evangelists/pasta-types';
import {
  GetCollectionByHandleQuery,
  GetCollectionHandlesQuery,
  GetCollectionQuery,
  GetProductQuery,
} from './graphql/generated/shopify';
import {
  getCollectionHandles,
  getCollectionQuery,
  getSingleCollectionQuery,
} from './graphql/shopify/queries/collection';
import { getProductQuery } from './graphql/shopify/queries/product';

export const getWeeklyMenus = () =>
  shopifyGraphQLClient.request<GetCollectionQuery>(getCollectionQuery, {
    query: `title:${getWeeklyMenusKeys().join(' OR ')}`,
  });

export const getWeeklyMenu = (week: string) =>
  shopifyGraphQLClient.request<GetCollectionByHandleQuery>(getSingleCollectionQuery, {
    handle: week,
  });

export const getAvailableMenus = () =>
  shopifyGraphQLClient.request<GetCollectionHandlesQuery>(getCollectionHandles);

export const getProductReviewForMenu = async (menu: string) => {
  const result = await axios.get<WeeklyReviewServerResponse>(`weekly_menus/${menu}/review_summary`);

  return result.data.included;
};

export const getProductById = async (id: string | number) => {
  const graphQLProductId =
    typeof id === 'string'
      ? id.startsWith('gid://shopify/Product/')
        ? id
        : `gid://shopify/Product/${id}`
      : `gid://shopify/Product/${id}`;

  return shopifyGraphQLClient.request<GetProductQuery>(getProductQuery, { id: graphQLProductId });
};
