<template>
  <TransitionRoot appear :show="modelValue" as="template">
    <Dialog as="div" @close="$emit('update:modelValue', false)">
      <div class="fixed inset-0 z-20">
        <div class="min-h-screen bg-sienna-muted/70 backdrop-blur-[6px]">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-300 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-bottom" aria-hidden="true">&#8203;</span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95 -translate-x-full"
            enter-to="opacity-100 scale-100"
            leave="duration-300 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95 -translate-x-full"
          >
            <div
              class="fixed top-0 left-0 flex flex-col w-full max-w-xs min-h-screen p-6 mx-auto overflow-y-auto text-left align-middle transition-all border-r border-dark-linen bg-linen"
            >
              <button class="text-burnt-sienna" @click="$emit('update:modelValue', false)">
                <XIcon class="size-6" />
              </button>
              <NavBarMobileMenu
                :is-logged-in="isLoggedIn"
                :show-my-account-links="showMyAccountLinks"
                @open-modal="openModal"
                @close="$emit('update:modelValue', false)"
                @logout="$emit('logout')"
              />
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue';
  import { XIcon } from '@heroicons/vue/outline';

  interface Props {
    modelValue?: boolean;
    isLoggedIn?: boolean;
    showMyAccountLinks?: boolean;
  }

  interface Emits {
    (e: 'update:modelValue', value: boolean): void;
    (e: 'openModal'): void;
    (e: 'logout'): void;
  }

  defineProps<Props>();
  const emit = defineEmits<Emits>();

  const openModal = () => {
    emit('update:modelValue', false);
    emit('openModal');
  };
</script>
