import { BasketRules } from '@/types/basket';
import { MINIMUM_MENU_VALUE } from '@/constants/menus';
import { formatToString } from '@/utils/money';
import currentCustomerStore from '@/stores/currentCustomerStore';
import { NormalizedBasket } from '@pasta-evangelists/pasta-types';

interface BasketParams {
  basketId: Ref<string | null>;
  basket: Ref<NormalizedBasket | null | undefined>;
}

const useBasketHelper = ({ basketId, basket }: BasketParams) => {
  const customerStore = currentCustomerStore();

  const basketRules = computed<BasketRules>(() => {
    if (!basketId.value || !basket.value?.basket?.[basketId.value])
      return {
        minimumSpend: MINIMUM_MENU_VALUE.toString(),
        minimumSpendPerOrder: MINIMUM_MENU_VALUE.toString(),
        requestCard: false,
      };
    const minimumSpend = basket.value?.basket?.[basketId.value].meta.minimumSpend;
    if (minimumSpend) {
      return {
        ...basket.value?.basket?.[basketId.value].meta,
        minimumSpend: minimumSpend,
      };
    }
    return {
      ...basket.value?.basket?.[basketId.value].meta,
      minimumSpend: MINIMUM_MENU_VALUE.toString(),
    };
  });

  const minimumSpend = computed(() => formatToString({ price: basketRules.value.minimumSpend }));

  const total = computed(() => {
    if (!basketId.value || !basket.value?.basket) return 0;
    if (!basket.value.basket[basketId.value].attributes.totalPrice) return 0;
    return parseFloat(basket.value.basket[basketId.value].attributes.totalPrice);
  });

  const totalPriceInPounds = computed(() => formatToString({ price: total.value }));

  const subtotal = computed(() => {
    if (!basketId.value || !basket.value?.basket) return 0;
    if (!basket.value.basket[basketId.value].attributes.subtotalPrice) return 0;
    return parseFloat(basket.value.basket[basketId.value].attributes.subtotalPrice);
  });

  const subtotalBeforeOrderDiscounts = computed(() => {
    if (!basketId.value || !basket.value?.basket) return 0;
    if (!basket.value.basket[basketId.value].attributes.totalLineItemsPrice) return 0;
    return parseFloat(basket.value.basket[basketId.value].attributes.totalLineItemsPrice);
  });

  const subtotalBeforeOrderDiscountsInPounds = computed(() =>
    formatToString({ price: subtotalBeforeOrderDiscounts.value })
  );

  const subtotalWithShipping = computed(() => {
    return subtotal.value + shippingPrice.value;
  });

  const subtotalWithShippingInPounds = computed(() =>
    formatToString({ price: subtotalWithShipping.value })
  );

  const voucher = computed(() => {
    if (!basketId.value || !basket.value) return;
    const voucherId = basket.value.basket?.[basketId.value].relationships.voucher?.data?.id;
    if (!voucherId) return;
    return basket.value.voucher[voucherId];
  });

  const hasVoucher = computed(() => !!voucher.value);

  const voucherCode = computed(() => {
    if (!voucher.value) return '';
    return voucher.value.attributes.code;
  });

  const shipment = computed(() => {
    if (!basketId.value) return null;
    const shipmentId = basket.value?.basket?.[basketId.value].relationships.shipment.data?.id;
    if (!shipmentId) return null;
    const shipment = basket.value?.basketShipment?.[shipmentId];
    if (!shipment) return null;
    return shipment;
  });

  const deliveryThreshold = computed(() => {
    if (!shipment.value) return 0;
    return parseFloat(shipment.value.attributes.threshold);
  });

  const shippingPrice = computed<number>(() => {
    if (!shipment.value) return 0;
    return parseFloat(shipment.value.attributes.total);
  });

  const shippingPriceInPounds = computed(() => formatToString({ price: shippingPrice.value }));

  const totalWithoutShipping = computed(() => {
    return total.value - shippingPrice.value;
  });

  const totalWithoutShippingInPounds = computed(() =>
    formatToString({ price: totalWithoutShipping.value })
  );

  const totalAmountToChargeOnCard = computed(() => {
    const amountInPounds = customerStore.storeCredits
      ? Math.max(0, total.value - parseFloat(customerStore.storeCredits || '0'))
      : total.value;
    return amountInPounds * 100;
  });

  const shouldAskForCard = computed(() => {
    return basketRules.value.requestCard || totalAmountToChargeOnCard.value > 0;
  });

  const amountLeftForFreeDelivery = computed(() => {
    if (!shipment.value || !basket.value?.basket || !basketId.value) return 0;
    return Math.max(
      parseFloat(shipment.value.attributes.threshold) -
        parseFloat(basket.value.basket[basketId.value].attributes.subtotalPrice),
      0
    );
  });

  const undiscountedDelivery = computed(() => {
    if (!shipment.value) return 0;
    return parseFloat(shipment.value.attributes.price) || 1.99;
  });

  const offersFreeDelivery = computed(() =>
    Boolean(
      shipment.value?.attributes.threshold && parseFloat(shipment.value?.attributes.threshold)
    )
  );

  return {
    amountLeftForFreeDelivery,
    basketId,
    basketRules,
    deliveryThreshold,
    hasVoucher,
    minimumSpend,
    offersFreeDelivery,
    shipment,
    shippingPrice,
    shippingPriceInPounds,
    shouldAskForCard,
    subtotal,
    subtotalBeforeOrderDiscounts,
    subtotalBeforeOrderDiscountsInPounds,
    subtotalWithShipping,
    subtotalWithShippingInPounds,
    total,
    totalPriceInPounds,
    totalAmountToChargeOnCard,
    undiscountedDelivery,
    voucher,
    voucherCode,
    totalWithoutShipping,
    totalWithoutShippingInPounds,
  };
};

export default useBasketHelper;
