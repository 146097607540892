export type ShopifySizeAttributes =
  | 'pico' // 16px
  | 'icon' // 32px
  | 'thumb' // 50px
  | 'small' // 100px
  | 'compact' // 160px
  | 'medium' // 240px
  | 'large' // 480px
  | 'grande' // 600px
  | number;

export const preload = (src: string | string[]) => {
  if (Array.isArray(src)) {
    src.forEach(imageSrc => {
      new Image().src = imageSrc;
    });
  } else {
    new Image().src = src;
  }
};

export const getSizedImage = (src: string, size?: ShopifySizeAttributes) => {
  if (!size) return src;

  const match = src.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif|webp)(\?v=\d+)?$/i);

  if (match) {
    const prefix = src.split(match[0]);
    const suffix = match[0];

    return `${prefix[0]}_${size}${typeof size === 'number' ? 'x' : ''}${suffix}`;
  }
  return src;
};
