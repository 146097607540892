import { Ref } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';

interface params {
  isOpen: Ref<boolean>;
  handleClose: () => void;
  isDisabled?: boolean;
}

const useNavigationCloseModal = ({ isOpen, handleClose, isDisabled }: params) => {
  onMounted(() => {
    if (!isDisabled) {
      onBeforeRouteUpdate((_, from, next) => {
        if (isOpen.value && window.screen.width <= 700 && from.hash === '#modal') {
          handleClose();
        }
        next();
      });
    }
  });

  watch(isOpen, newVal => {
    if (!isDisabled) {
      if (newVal) window.location.hash = 'modal';
    }
  });
};

export default useNavigationCloseModal;
