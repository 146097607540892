export const giftMenuLinks = [
  {
    label: 'Pasta making kits',
    path: 'https://pastaevangelists.com/collections/pasta-making-kits',
  },
  {
    label: 'Truffle emporium',
    path: 'https://pastaevangelists.com/collections/new-the-truffle-collection',
  },
  {
    label: 'Gift boxes',
    path: 'https://pastaevangelists.com/collections/gift-boxes',
  },
  {
    label: 'Gift subscriptions',
    path: 'https://pastaevangelists.com/collections/fresh-pasta-gift-plans',
  },
  {
    label: 'Gift vouchers',
    path: 'https://pastaevangelists.com/pages/gift-cards',
  },
  {
    label: 'Pasta academy vouchers',
    path: 'https://pastaevangelists.com/products/pasta-academy-gift-cards?variant=40333555892333',
  },
];

export const experienceLinks = [
  {
    label: 'Pasta academy',
    path: 'https://pastaevangelists.com/collections/pasta-academy',
  },
  {
    label: 'Pasta academy gift vouchers',
    path: 'https://pastaevangelists.com/products/pasta-academy-gift-cards?variant=40333555892333',
  },
  {
    label: 'Private events',
    path: 'https://pastaevangelists.com/pages/team-events',
  },
  {
    label: 'Catering',
    path: 'https://pastaevangelists.com/pages/catering',
  },
  {
    label: 'Event FAQs',
    path: 'https://pastaevangelists.com/pages/pasta-academy',
  },
];

export const guideLinks = [
  {
    label: 'Blog',
    path: 'https://pastaevangelists.com/blogs/blog',
  },
  {
    label: 'Italian cuisine',
    path: 'https://pastaevangelists.com/pages/italian-kitchen-hub',
  },
  {
    label: 'Pasta types & sauces',
    path: 'https://pastaevangelists.com/pages/types-of-pasta',
  },
  {
    label: 'Wine pairing wheel',
    path: 'https://pastaevangelists.com/pages/pasta-wine-pairing-guide',
  },
  {
    label: 'YouTube channel',
    path: 'https://www.youtube.com/channel/UCpvBXtywbSufwz9EyXqNBhQ',
  },
];

export const storesLinks = [
  {
    label: 'Harrods',
    path: 'https://pastaevangelists.com/pages/visit-us',
  },
  {
    label: 'Richmond',
    path: 'https://pastaevangelists.com/pages/pasta-evangelists-richmond',
  },
  {
    label: 'Greenwich',
    path: 'https://pastaevangelists.com/pages/pasta-evangelists-greenwich',
  },
  {
    label: 'Chiswick',
    path: 'https://pastaevangelists.com/pages/pasta-evangelists-chiswick',
  },
  {
    label: 'Manchester Airport',
    path: 'https://pastaevangelists.com/pages/pasta-evangelists-manchester-airport',
  },
];
