import { useQuery } from '@tanstack/vue-query';
import * as SubscriptionApi from '@/api/subscription';
import { queries } from '@/api/queries';
import currentCustomerStore from '@/stores/currentCustomerStore';

const useGetSubscriptions = () => {
  const customerStore = currentCustomerStore();
  const enabled = computed(() => !!customerStore.email);

  return useQuery({
    ...queries.subscriptions.all,
    queryFn: () => SubscriptionApi.getSubscriptions(),
    enabled,
  });
};

export default useGetSubscriptions;
