import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isTomorrowPlugin from 'dayjs/plugin/isTomorrow';

type CreateDeliveryDatesParam = {
  format?: string;
  excludeSunday?: boolean;
  from?: string;
  ignoreCutoff?: boolean;
};

const transformWeekday = (day: string | undefined) => {
  if (day) {
    switch (day.toLowerCase()) {
      case 'sunday':
        return 0;
      case 'monday':
        return 1;
      case 'tuesday':
        return 2;
      case 'wednesday':
        return 3;
      case 'thursday':
        return 4;
      case 'friday':
        return 5;
      case 'saturday':
        return 6;
    }
  }
};

const convertTo24hTime = (time: string | undefined) => {
  dayjs.extend(customParseFormat);
  dayjs.extend(utc);

  if (time) {
    const formattedTime = time.replace(/\s+/g, '#').toLowerCase();
    return dayjs(formattedTime, 'HH:mma').utc();
  }
};

const getMenuCutoff = () => {
  // set current cutoff Saturday 1PM as fallback
  const weekday = transformWeekday(import.meta.env.VITE_MENU_CUTOFF_WEEKDAY) ?? 6;
  const time = convertTo24hTime(import.meta.env.VITE_MENU_CUTOFF_TIME);
  const hour = time?.hour() ?? 13;
  const minutes = time?.minute() ?? 0;

  return { weekday, hour, minutes };
};

const CUTOFF_HOUR_UTC = convertTo24hTime(import.meta.env.VITE_MENU_CUTOFF_TIME)?.hour() ?? 13;

export const createDeliveryDateFormatted = (
  date?: string | number | Date | dayjs.Dayjs,
  format = 'dddd Do MMM'
) => {
  dayjs.extend(AdvancedFormat);
  return dayjs(date).format(format);
};

export const isWeekInFuture = (date: string | Date) => {
  dayjs.extend(isoWeek);
  const d1 = dayjs(date).startOf('isoWeek');
  const d2 = dayjs(new Date()).startOf('isoWeek');
  return d1.isAfter(d2, 'week');
};

export const createDeliveryDates = ({
  format = 'dddd Do MMM',
  excludeSunday = true,
  from = new Date().toISOString(),
  ignoreCutoff = false,
}: CreateDeliveryDatesParam = {}) => {
  dayjs.extend(AdvancedFormat);
  dayjs.extend(weekOfYear);
  const cutOffDateInUTC = new Date(from).setUTCHours(CUTOFF_HOUR_UTC);
  const isBeforeCutoff = dayjs().isBefore(cutOffDateInUTC);
  const isNextWeek = isWeekInFuture(from);

  let startAtDate = isNextWeek
    ? dayjs(from)
    : isBeforeCutoff || ignoreCutoff
    ? dayjs()
    : dayjs().add(1, 'days');

  const endAtDayOfWeek = excludeSunday ? 6 : 7;

  // if we can't fill this week we should display next week dates
  if ((startAtDate.day() || 7) > endAtDayOfWeek) {
    const incrementDays = startAtDate.day() ? 2 : 1;
    startAtDate = startAtDate.add(incrementDays, 'days');
  }

  const availableDates: { date: Date; formatted: string }[] = [];

  let currentDate = startAtDate;

  while ((currentDate.day() || 7) <= endAtDayOfWeek) {
    const date = dayjs(currentDate).toDate();
    const formatted = createDeliveryDateFormatted(date, format);
    availableDates.push({ date, formatted });
    // if the currentDate is sunday we stop the loop
    if (!currentDate.day()) {
      break;
    }
    currentDate = currentDate.add(1, 'days');
  }

  return availableDates;
};

export const convertMenuRangeToString = (menuWeek: string) => {
  dayjs.extend(isoWeek);
  let monday = dayjs(menuWeek);
  let sunday = dayjs(menuWeek).add(6, 'days');

  //TO DO revert after the holidays
  //check for Christmas holidays
  if (monday.month() === 11 && monday.date() < 27 && monday.date() > 24) {
    monday = monday.date(27);
  }
  if (sunday.month() === 11 && sunday.date() < 27 && sunday.date() > 24) {
    sunday = sunday.date(24);
  }

  const sundayFormat = 'DD MMM';
  const mondayFormat = monday.month() === sunday.month() ? 'DD' : 'DD MMM';

  const showFromToday = monday.isoWeek() === dayjs().isoWeek();

  const startRange = showFromToday ? dayjs().format(mondayFormat) : monday.format(mondayFormat);

  return `${startRange} - ${sunday.format(sundayFormat)}`;
};

export const isMenuPastCutoff = (menuWeek: string) => {
  dayjs.extend(utc);
  const cutoff = getMenuCutoff();
  return dayjs()
    .utc()
    .isAfter(dayjs(menuWeek).utc().day(cutoff.weekday).hour(cutoff.hour).minute(cutoff.minutes));
};

const isPastCutoff = () => {
  dayjs.extend(utc);
  const cutoff = getMenuCutoff();
  const cutoffAt = dayjs().utc().day(cutoff.weekday).hour(cutoff.hour).minute(cutoff.minutes);
  return dayjs().utc().isBefore(cutoffAt) || dayjs().day() === 0;
};

export const getWeeklyMenusKeys = () => {
  dayjs.extend(utc);
  const currentDay = dayjs().utc();
  const monday = isPastCutoff() ? currentDay.day(1) : currentDay.day(8);
  const nextMonday = monday.day(8);
  const thirdMonday = nextMonday.day(8);
  const format = 'YYYY-MM-DD';

  return [monday, nextMonday, thirdMonday].map(week => week.format(format));
};

interface WeekDayParams {
  value: string | Date;
  isShortForm?: boolean;
}

export const getWeekDay = ({ value, isShortForm }: WeekDayParams) => {
  const format = isShortForm ? 'ddd' : 'dddd';
  return dayjs(value).format(format);
};

export const findMenuForDate = (date: string | Date) => {
  const isDaySunday = dayjs(date).day() === 0;
  const correctedDay = isDaySunday ? dayjs(date).subtract(1, 'day') : dayjs(date);
  return correctedDay.day(1).format('YYYY-MM-DD');
};

export const getPastWeekMenu = () => {
  return dayjs().day(1).subtract(1, 'week').format('YYYY-MM-DD');
};

export const isWeekInPast = (date: Date | string) => {
  dayjs.extend(isoWeek);
  const d1 = dayjs(date).startOf('isoWeek');
  const d2 = dayjs(new Date()).startOf('isoWeek');
  return d1.isBefore(d2, 'week');
};

export const isWeekCurrent = (date: string | Date) => {
  dayjs.extend(isoWeek);
  const d1 = dayjs(date).startOf('isoWeek');
  const d2 = dayjs(new Date()).startOf('isoWeek');
  return d1.isSame(d2, 'week');
};

export const isFridayAndAfter5PM = () => {
  dayjs.extend(utc);
  dayjs.extend(isoWeek);
  const currentDay = dayjs().utc();
  const isFriday = currentDay.day() === 5;
  const isAfter5PM = currentDay.hour() >= 16;
  return isFriday && isAfter5PM;
};

export const isLessThan3DaysInTheFuture = (date: string) => {
  return dayjs().hour(0).diff(date, 'day') > -2;
};

export const isToday = (date: string) => {
  return dayjs(date).isSame(dayjs(), 'day');
};

export const isTomorrow = (date: string) => {
  dayjs.extend(isTomorrowPlugin);
  return dayjs(date).isTomorrow();
};

export const getHourFromDate = (date: string) => {
  return dayjs(date).format('HH:mm');
};

export const isSunday = (date: Date | string) => {
  return dayjs(date).day() === 0;
};
