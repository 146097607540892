import { useDixa } from '@/plugins/dixa';
import useCurrentCustomerStore from '@/stores/currentCustomerStore';

const useAssignDixaUser = () => {
  const customerStore = useCurrentCustomerStore();
  const { isLoaded, claimIdentity } = useDixa();

  watch([() => customerStore.email, isLoaded], ([customerEmail, dixaLoaded]) => {
    if (customerEmail && dixaLoaded) {
      claimIdentity({
        name: customerStore.firstName + ' ' + customerStore.lastName,
        email: customerEmail,
      });
    }
  });
};

export default useAssignDixaUser;
