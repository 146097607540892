import { axios } from '@/utils';
import {
  ApiResponse,
  MarketingBanners,
  MarketingBannersApiResponse,
  ObjectToCamel,
  PromiseResponse,
} from '@pasta-evangelists/pasta-types';

export const getMarketingBanners = async (): ApiResponse<MarketingBanners> => {
  try {
    const result = await axios.get<ObjectToCamel<MarketingBannersApiResponse>>(
      '/marketing_banners'
    );
    return { data: result.data.data, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong' } };
  }
};

interface PartnershipBlock {
  id: string;
  type: 'partnership_block';
  attributes: {
    name: string;
    position: number;
    imageUrl: string;
    linkUrl: string;
    active: boolean;
    category: string | null;
  };
}

interface PartnerShipBlockResponse {
  data: PartnershipBlock[];
}

export const getPartnershipBlocks = async (): PromiseResponse<PartnershipBlock[]> => {
  try {
    const { data } = await axios.get<ObjectToCamel<PartnerShipBlockResponse>>('reward_blocks');
    return data.data;
  } catch (e) {
    throw new Error('Something went wrong');
  }
};

interface MarketingOffersDataItem {
  attributes: {
    active: boolean;
    customerType: string;
    position: number;
    termsAndConditions: string;
    text: string;
    voucherCode: string;
    category: 'takeaway' | 'recipe_kits';
  };
  id: string;
  type: 'marketing_offer';
}

interface MarketingOffersPageResponse {
  data: MarketingOffersDataItem[];
  meta: {
    currentPage: number;
    lastPage: number;
    nextPage: number | null;
    perPage: number;
    prevPage: number | null;
    totalCount: number;
  };
}

export const getMarketingOffers = async () => {
  const result = await axios.get<MarketingOffersPageResponse>('/marketing_offers');
  return result.data;
};
