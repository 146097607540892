import * as stripeJs from '@stripe/stripe-js';
import { CanMakePaymentResult } from '@stripe/stripe-js';
// eslint-disable-next-line vue/prefer-import-from-vue
import { isPromise } from '@vue/shared';
import type { App, Ref } from 'vue';

type Options = {
  pk: string;
  elementOptions?: stripeJs.StripeElementsOptionsClientSecret;
};

const stripe = ref<stripeJs.Stripe | null>(null);
const elements = ref<stripeJs.StripeElements | null>(null);
const canMakePayment = ref<CanMakePaymentResult | null>(null);

const INVALID_STRIPE_ERROR =
  'Invalid prop `stripe` supplied to `Elements`. We recommend using the `loadStripe` utility from `@stripe/stripe-js`.';

export const isUnknownObject = (raw: unknown): raw is { [key in PropertyKey]: unknown } => {
  return raw !== null && typeof raw === 'object';
};

// We are using types to enforce the `stripe` prop in this lib,
// but in an untyped integration `stripe` could be anything, so we need
// to do some sanity validation to prevent type errors.
export const isStripe = (raw: unknown): raw is stripeJs.Stripe => {
  return (
    isUnknownObject(raw) &&
    typeof raw.elements === 'function' &&
    typeof raw.createToken === 'function' &&
    typeof raw.createPaymentMethod === 'function' &&
    typeof raw.confirmCardPayment === 'function'
  );
};

const validateStripe = (maybeStripe: unknown): null | stripeJs.Stripe => {
  if (maybeStripe === null || isStripe(maybeStripe)) {
    return maybeStripe;
  }

  throw new Error(INVALID_STRIPE_ERROR);
};

type ParsedStripeProp =
  | { tag: 'empty' }
  | { tag: 'sync'; stripe: stripeJs.Stripe }
  | { tag: 'async'; stripePromise: Promise<stripeJs.Stripe | null> };

const parseStripeProp = (raw: unknown): ParsedStripeProp => {
  if (isPromise(raw)) {
    return {
      tag: 'async',
      stripePromise: Promise.resolve(raw).then(validateStripe),
    };
  }

  const stripe = validateStripe(raw);

  if (stripe === null) {
    return { tag: 'empty' };
  }

  return { tag: 'sync', stripe };
};

export default {
  install: async (_: App, options: Options) => {
    const rawStripeProp = stripeJs.loadStripe(options.pk);
    const parsed = parseStripeProp(rawStripeProp);

    if (parsed.tag === 'sync') {
      stripe.value = parsed.stripe;
      elements.value = parsed.stripe.elements(options.elementOptions);
    }

    if (parsed.tag === 'async') {
      parsed.stripePromise.then(result => {
        if (result) {
          stripe.value = result;
          elements.value = result.elements(options.elementOptions);
        }
      });
    }
  },
};

export const useElements = (): Ref<stripeJs.StripeElements | null> => {
  return elements;
};

export const useStripe = (): Ref<stripeJs.Stripe | null> => {
  return stripe;
};

watch(stripe, async strp => {
  if (!strp) {
    return;
  }

  // we create a dummy payment option to have access to the `canMakePayment` method
  const pr = strp.paymentRequest({
    country: 'GB',
    currency: 'gbp',
    total: {
      label: 'Total amount',
      amount: 1200,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    disableWallets: ['link' as any],
  });

  const result = await pr.canMakePayment();
  canMakePayment.value = result;
});

export const useCanMakePayment = (): Ref<CanMakePaymentResult | null> => {
  return canMakePayment;
};
