<template>
  <img
    v-if="brand === 'mastercard'"
    src="https://cdn.shopify.com/shopifycloud/shopify/assets/themes_support/notifications/mastercard-c8d6f1c2e7b63ab95f49954c724c675678d205478e3de8d6f3da384fc068589d.png"
    width="30"
    height="18"
    class="w-[30px] h-[18px]"
    alt="master card logo"
  />
  <img
    v-else-if="brand === 'visa'"
    src="https://cdn.shopify.com/shopifycloud/shopify/assets/themes_support/notifications/visa-e96781bbd9d5a604ec37ca3959c7200b62b58790536de883a9f29852191da219.png"
    width="30"
    height="18"
    class="w-[30px] h-[18px]"
    alt="visa logo"
  />
  <img
    v-else-if="brand === 'amex'"
    src="https://cdn.shopify.com/shopifycloud/shopify/assets/themes_support/notifications/american_express-44152baf677c1090bbd5feb49c21ea85183a21c030f7489d8df470a48804d089.png"
    width="30"
    height="18"
    class="w-[30px] h-[18px]"
    alt="american express logo"
  />

  <ApplePayIcon v-else-if="brand === 'apple_pay'" class="w-8 h-5" />
  <GooglePayIcon v-else-if="brand === 'google_pay'" class="w-8 h-5" />
  <SmallPaypalIcon v-else-if="brand === 'paypal'" class="w-8 h-5" />
  <img
    v-else
    src="https://cdn.shopify.com/shopifycloud/shopify/assets/themes_support/notifications/generic-229db4a6cffbab8929ead62830dd63e0f6c42bcd74e5e55c8f2aef84fd97cf18.png"
    width="30"
    height="18"
    class="w-[30px] h-[18px]"
    alt="generic card"
  />
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';

  export default defineComponent({
    props: {
      brand: { type: String as PropType<string>, required: true },
    },
  });
</script>
