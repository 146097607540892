import { useQuery } from '@tanstack/vue-query';
import * as DeliveryApi from '@/api/delivery';
import { defineStore } from 'pinia';
import { queries } from '@/api/queries';

const useDeliveryConfig = defineStore('deliveryConfig', () => {
  const { data } = useQuery({
    ...queries.deliveryFees.all,
    queryFn: DeliveryApi.getDeliveryFees,
  });

  const config = computed(() => data);
  return { config };
});

export default useDeliveryConfig;
