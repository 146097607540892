import { useMutation } from '@tanstack/vue-query';
import * as TakeawayBasketApi from '@/api/takeawayBasket';
import { MutationConfig } from '@/types';

type SkipOptions = {
  config?: MutationConfig<typeof TakeawayBasketApi.getDeliveryEstimation>;
};

const useCheckDeliveryEstimation = ({ config }: SkipOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: TakeawayBasketApi.getDeliveryEstimation,
  });
};

export default useCheckDeliveryEstimation;
