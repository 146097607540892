import { useRouter } from 'vue-router';

const useProposition = () => {
  const router = useRouter();
  const proposition = computed(() => {
    if (router && router.currentRoute) {
      const routeName = router.currentRoute.value.name as string;
      if (routeName.startsWith('pasta-now')) return 'PN';
      else if (routeName.startsWith('upcoming-order')) return 'UO';
      else if (routeName.startsWith('one-off')) return 'OO';
      else if (routeName.startsWith('product-box')) return 'RK';
    }
    return '';
  });
  return {
    proposition,
  };
};

export default useProposition;
