<template>
  <TransitionRoot appear :show="modelValue" as="template">
    <Dialog as="div" :open="modelValue" @close="$emit('update:modelValue', false)">
      <div class="fixed inset-0 z-20">
        <div class="min-h-dvh text-center bg-sienna-muted/70 backdrop-blur-[3px]">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-bottom" aria-hidden="true">&#8203;</span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95 translate-y-full"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95 translate-y-full"
          >
            <div
              class="fixed inset-0 w-full h-screen max-w-6xl mx-auto overflow-hidden text-left align-middle transition-all bg-white shadow-xl md:h-4/5 md:inset-1/2 md:-translate-x-1/2 md:-translate-y-1/2 md:rounded"
            >
              <div
                class="absolute z-50 flex items-center justify-end flex-1 px-2 py-1 top-2 right-3"
              >
                <button
                  cy-data="modal-close-btn"
                  class="flex items-center p-1 rounded-full bg-linen group hover:bg-light-linen"
                  @click="$emit('update:modelValue', false)"
                >
                  <XIcon class="size-6 text-burnt-sienna group-hover:text-terracota-dark" />
                </button>
              </div>
              <HowItWorksModalContent @close="$emit('update:modelValue', false)" />
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from '@headlessui/vue';
  import { watch } from 'vue';
  import { XIcon } from '@heroicons/vue/solid';

  import HowItWorksModalContent from '@/components/HowItWorksModalContent.vue';
  import { trackCustomEvent } from '@/utils/analytics';

  interface Props {
    modelValue?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), { modelValue: false });

  interface Emits {
    (e: 'update:modelValue', value: boolean): void;
  }

  defineEmits<Emits>();

  watch(props, modelValue => {
    if (modelValue) trackCustomEvent('modal_shown', { modal_shown: 'How it works' });
  });
</script>
