import { acceptHMRUpdate, defineStore } from 'pinia';
import { PaymentMethod } from '@pasta-evangelists/pasta-types';
import useSubscriptionStore from './subscriptionStore';
import useGetPaymentMethods from '@/api/queries/pensa/useGetPaymentMethods';
import useDeletePaymentMethod from '@/api/mutations/pensa/deletePaymentMethod';

const usePaymentStore = defineStore('paymentStore', () => {
  const { data, isLoading, error, refetch: getPaymentMethods } = useGetPaymentMethods();
  const deletePaymentMethodMutation = useDeletePaymentMethod();

  const paymentMethods = computed(() => data.value || []);

  const defaultMethod = computed(() => {
    if (!paymentMethods.value) return null;
    return paymentMethods.value.find(payment => payment.attributes.default) || null;
  });

  const cards = computed<PaymentMethod[]>(() => {
    return paymentMethods.value?.filter(payment => payment.attributes.type === 'CreditCard') || [];
  });

  const paypalAccounts = computed<PaymentMethod[]>(() => {
    return (
      paymentMethods.value?.filter(payment => payment.attributes.type === 'PaypalAccount') || []
    );
  });

  const getPaymentMethodCopyFromId = (id: string) => {
    return paymentMethods.value?.find(paymentMethod => paymentMethod.id === id) || null;
  };

  const isPaymentMethodRemovable = (id: string) => {
    const subscriptionStore = useSubscriptionStore();
    const paymentMethodCopy = getPaymentMethodCopyFromId(id);

    const subscriptionsThatUseCard = subscriptionStore.findSubscriptionsWithPaymentMethodId(id);
    const hasNonCancelledSubscriptions = Boolean(
      subscriptionsThatUseCard.length > 0 &&
        subscriptionsThatUseCard.find(
          sub => sub.attributes.state && sub.attributes.state !== 'cancelled'
        )
    );

    return !(
      (paymentMethodCopy &&
        paymentMethods.value &&
        paymentMethods.value.length > 1 &&
        paymentMethodCopy.attributes.default) ||
      (paymentMethods.value && paymentMethods.value.length == 1 && hasNonCancelledSubscriptions)
    );
  };

  const deletePaymentMethod = async (id: string) => {
    if (!paymentMethods.value) return;

    const cardCopy = getPaymentMethodCopyFromId(id);

    if (!cardCopy || !isPaymentMethodRemovable(id)) return;

    deletePaymentMethodMutation.mutate(id);
  };

  return {
    paymentMethods,
    isLoading,
    error,
    defaultMethod,
    cards,
    paypalAccounts,
    deletePaymentMethod,
    getPaymentMethodCopyFromId,
    getPaymentMethods,
    isPaymentMethodRemovable,
  };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(usePaymentStore, import.meta.hot));

export default usePaymentStore;
