import { useMutation } from '@tanstack/vue-query';
import * as AddressApi from '@/api/address';
import { MutationConfig } from '@/types';

type SkipOptions = {
  config?: MutationConfig<typeof AddressApi.updateAddress>;
};

const useUpdateAddress = ({ config }: SkipOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: AddressApi.updateAddress,
  });
};

export default useUpdateAddress;
