import { axios } from '@/utils';
import { ApiResponse } from '@pasta-evangelists/pasta-types';
import { decamelizeKeys } from 'humps';

interface RegisterOptimizeParams {
  experimentId: string;
  variantId: string;
}
export const registerOptimizeExperiment = async (
  params: RegisterOptimizeParams
): ApiResponse<true> => {
  try {
    await axios.post('/customer_google_experiments', decamelizeKeys(params));
    return { data: true, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong' } };
  }
};
