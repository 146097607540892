import { useMutation, useQueryClient } from '@tanstack/vue-query';
import * as SubscriptionApi from '@/api/subscription';
import { MutationConfig } from '@/types';
import { queries } from '@/api/queries';
import { useCloned } from '@vueuse/core';
import { NormalizedSubscriptions } from '@pasta-evangelists/pasta-types';

type SkipOptions = {
  config?: MutationConfig<typeof SubscriptionApi.updateSubscription>;
};

const useUpdateSubscription = ({ config }: SkipOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: SubscriptionApi.updateSubscription,
    onSuccess: (result, { subId }) => {
      queryClient.setQueriesData<NormalizedSubscriptions>(
        queries.subscriptions.all,
        subscriptions => {
          const { cloned: tempSubscriptions } = useCloned(subscriptions, { manual: true });
          if (tempSubscriptions.value && tempSubscriptions.value.subscription[subId]) {
            tempSubscriptions.value.subscription[subId] = result;
          }
          return tempSubscriptions.value;
        }
      );
      queryClient.invalidateQueries(queries.subscriptions.all);
    },
  });
};

export default useUpdateSubscription;
