import { useQuery } from '@tanstack/vue-query';
import * as BasketApi from '@/api/basket';
import { queries } from '@/api/queries';

const getBaskets = async () => {
  const result = await BasketApi.getBaskets();
  if (result.data) {
    return result.data;
  }
  throw new Error(result.error.message);
};

const useGetBaskets = () => {
  return useQuery({ ...queries.baskets.all, queryFn: getBaskets, retry: 0 });
};

export default useGetBaskets;
