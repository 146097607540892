import { useQuery, keepPreviousData } from '@tanstack/vue-query';
import * as RestaurantApi from '@/api/restaurants';
import { queries } from '@/api/queries';

const useGetMenu = (
  restaurantId: Ref<string | number | null>,
  fulfillmentType?: Ref<'delivery' | 'pickup' | 'eat_in' | null>
) => {
  const enabled = computed(() => !!restaurantId.value);

  return useQuery({
    ...queries.takeaway.restaurant(restaurantId)._ctx.menu(fulfillmentType),
    queryFn: () =>
      RestaurantApi.getTakeawayMenu(restaurantId.value!, fulfillmentType?.value || undefined),
    enabled,
    refetchInterval: 60 * 1000,
    placeholderData: keepPreviousData,
  });
};

export default useGetMenu;
