import { useRoute } from 'vue-router';

const useTransparentNavbar = () => {
  const route = useRoute();
  const pages = ['pasta-now', 'product-box'];

  const hasTransparentNavbar = computed(() => pages.includes(route.name as string));

  return { hasTransparentNavbar };
};

export default useTransparentNavbar;
