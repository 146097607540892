import { useMutation, useQueryClient } from '@tanstack/vue-query';
import * as SubscriptionApi from '@/api/subscription';
import { MutationConfig } from '@/types';
import { queries } from '@/api/queries';
import { useCloned } from '@vueuse/core';
import { NormalizedSubscriptions } from '@pasta-evangelists/pasta-types';

type SkipOptions = {
  config?: MutationConfig<typeof SubscriptionApi.updateSubscriptionSchedule>;
};

const useUpdateSubscriptionSchedule = ({ config }: SkipOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: SubscriptionApi.updateSubscriptionSchedule,
    onSuccess: result => {
      queryClient.setQueriesData<NormalizedSubscriptions>(
        queries.subscriptions.all,
        subscriptions => {
          const { cloned: tempSubscriptions } = useCloned(subscriptions, { manual: true });
          const currentScheduleId = result.data.id;
          if (
            tempSubscriptions.value &&
            tempSubscriptions.value.subscriptionSchedule[currentScheduleId]
          ) {
            tempSubscriptions.value.subscriptionSchedule[currentScheduleId].attributes.deliveryDay =
              result.data.attributes.deliveryDay;

            tempSubscriptions.value.subscriptionSchedule[currentScheduleId].attributes.frequency =
              result.data.attributes.frequency;
          }
          return tempSubscriptions.value;
        }
      );
      queryClient.invalidateQueries(queries.subscriptions.all);
    },
  });
};

export default useUpdateSubscriptionSchedule;
