<template>
  <div class="px-4 mx-auto lg:px-6">
    <div class="flex justify-between h-16">
      <div class="flex items-center justify-between w-full">
        <div class="flex items-stretch shrink-0">
          <div class="flex items-center mr-4 lg:hidden">
            <button
              type="button"
              class="z-10 inline-flex items-center justify-center p-4 -mx-4 focus:outline-none"
              aria-controls="mobile-menu"
              aria-expanded="false"
              @click="$emit('openMobile')"
            >
              <span class="sr-only">Open main menu</span>
              <BurgerIcon class="size-6 text-burnt-sienna" />
            </button>
          </div>
          <a href="https://pastaevangelists.com/" aria-label="Go to homepage" class="-mt-0.5">
            <ThePastaLogo class="h-auto py-4 cursor-pointer w-[100px] text-terracota-dark" />
          </a>
          <div class="hidden ml-12 space-x-8 lg:flex">
            <div class="relative flex items-center h-full group">
              <button
                :id="`menu${proposition}_header_TWtoggle`"
                :class="{
                  'text-burnt-sienna group-hover:text-terracota-dark': !shouldShowTakeawayAsDisabled,
                  'text-burnt-sienna/30': shouldShowTakeawayAsDisabled,
                }"
                :disabled="goToPastaNowMutation.isPending.value"
                @click="handleTakeawayButtonClick"
              >
                <BaseLoadingIcon v-if="goToPastaNowMutation.isPending.value" class="size-5" />
                <span v-else>Takeaway</span>
              </button>
              <div
                v-if="!goToPastaNowMutation.isPending.value"
                class="absolute hidden group-hover:block -bottom-px left-5 self-center w-8 h-0.5 bg-burnt-sienna"
              />
            </div>
            <div class="relative flex items-center justify-center h-full">
              <div class="text-terracota-dark">Recipe kits</div>
              <div class="absolute -bottom-px mx-auto self-center w-8 h-0.5 bg-terracota-dark" />
            </div>
          </div>
        </div>
        <div class="flex items-center lg:hidden">
          <button
            :id="`menu${proposition}_header_Howitworks`"
            class="inline-flex items-center px-2 py-1 text-xs border rounded-sm border-sienna-muted bg-light-linen text-burnt-sienna"
            @click="openModal"
          >
            How it works
          </button>
        </div>
        <div class="items-center hidden lg:space-x-4 lg:-my-px lg:flex">
          <button
            :id="`menu${proposition}_header_Howitworks`"
            class="inline-flex items-center px-4 py-2 mr-2 border rounded-sm text-burnt-sienna bg-light-linen hover:border-sienna-muted hover:bg-dark-linen"
            @click="openModal"
          >
            How it works
          </button>

          <div class="flex group">
            <div
              class="absolute hidden group-hover:block -bottom-px mx-auto w-8 h-0.5 bg-burnt-sienna right-7"
            />
            <router-link
              v-if="!isLoggedIn"
              :id="`menu${proposition}_header_Login`"
              to="/social-login"
            >
              <div class="p-1 rounded-full bg-sandstone">
                <UserIcon class="size-8 text-burnt-sienna group-hover:text-terracota-dark" />
              </div>
            </router-link>

            <router-link v-if="isLoggedIn" :id="`menu${proposition}_header_Account`" to="/account">
              <div class="p-1 rounded-full bg-sandstone">
                <UserIcon class="size-8 text-burnt-sienna group-hover:text-terracota-dark" />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <NavBarTakeawayUnavailable v-model:is-open="showTakeawayUnavailableModal" />
</template>

<script lang="ts" setup>
  import ThePastaLogo from '@/components/ThePastaLogo.vue';
  import useProposition from '@/composables/useProposition';
  import { useRoute, useRouter } from 'vue-router';
  import { trackCustomEvent } from '../utils/analytics';
  import usePastaNowBasket from '@/stores/pastaNowBasketStore';
  import useChangePastaNowAddress from '@/composables/useChangePastaNowAddress';
  import useGeoLocationFromAddress from '@/composables/useGeoLocationFromAddress';
  import * as RestaurantApi from '@/api/restaurants';
  import { useMutation } from '@tanstack/vue-query';
  import useBasketStore from '@/stores/basketStore';
  import useUpdateRecipeKitBasket from '@/api/mutations/pensa/updateRecipeKitBasket';

  interface Props {
    isLoggedIn?: boolean;
  }

  interface Emits {
    (e: 'openMobile'): void;
    (e: 'openModal'): void;
    (e: 'logout'): void;
  }

  defineProps<Props>();
  const emit = defineEmits<Emits>();

  const { proposition } = useProposition();
  const router = useRouter();
  const route = useRoute();
  const pastaNowBasket = usePastaNowBasket();
  const basketStore = useBasketStore();
  const { clearBasketAndCreateNewOne } = useChangePastaNowAddress();
  const { getAproxLocationFromPostcode } = useGeoLocationFromAddress();
  const updateBasketMutation = useUpdateRecipeKitBasket();

  const showTakeawayUnavailableModal = ref(false);

  const shouldShowTakeawayAsDisabled = Boolean(route.query.dtw);

  const openModal = () => {
    emit('openModal');
    trackCustomEvent('howItWorksClicked');
  };

  const goToPastaNow = async () => {
    if (
      basketStore.address?.zip &&
      pastaNowBasket.address?.zip === basketStore.address.zip &&
      pastaNowBasket.restaurantId
    ) {
      return true;
    }

    let lat: string | null = basketStore.address?.lat || null;
    let lon: string | null = basketStore.address?.lon || null;

    if ((basketStore.address && !basketStore.address?.lat) || basketStore.address?.lon) {
      const coordinatesResult = await getAproxLocationFromPostcode(basketStore.address.zip!);

      if (!coordinatesResult.data) {
        throw new Error('Could not get coordinates from address');
      }

      const { latitude, longitude } = coordinatesResult.data;

      lat = latitude;
      lon = longitude;

      if (basketStore.basketKey) {
        await updateBasketMutation.mutateAsync({
          address: {
            lat: latitude,
            lon: longitude,
          },
          id: basketStore.basketKey!,
        });
      }
    }

    const availableRestaurants = await RestaurantApi.getAvailableStores({
      lat: lat!,
      lon: lon!,
      fulfillmentType: 'delivery',
    });

    if (!availableRestaurants.length) {
      throw new Error('No restaurants available');
    }

    await clearBasketAndCreateNewOne({
      address: {
        address1: basketStore.address?.address1 || '',
        address2: basketStore.address?.address2 || '',
        city: basketStore.address?.city || '',
        country: basketStore.address?.country || '',
        company: basketStore.address?.company || '',
        region: basketStore.address?.region || '',
        zip: basketStore.address?.zip || '',
        regionCode: basketStore.address?.regionCode || '',
        countryCode: basketStore.address?.countryCode || '',
        firstName: basketStore.address?.firstName || '',
        lastName: basketStore.address?.lastName || '',
      },
      restaurantId: availableRestaurants[0].id,
      coordinates: {
        lat: lat!,
        lon: lon!,
      },
    });

    return true;
  };

  const goToPastaNowMutation = useMutation({
    mutationFn: goToPastaNow,
    onSuccess: () => {
      router.push('pasta-now');
    },
    onError: () => {
      router.push('pasta-now-search-address');
    },
  });

  const handleTakeawayButtonClick = () => {
    if (shouldShowTakeawayAsDisabled) {
      showTakeawayUnavailableModal.value = true;
      return;
    }

    goToPastaNowMutation.mutate();
  };
</script>
