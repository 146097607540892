import { useQuery } from '@tanstack/vue-query';
import * as RestaurantApi from '@/api/restaurants';
import { queries } from '@/api/queries';

const useGetSnoozedProducts = (
  restaurantId: Ref<string | number | null>,
  fulfillmentType?: Ref<'delivery' | 'pickup' | 'eat_in' | null>
) => {
  const enabled = computed(() => !!restaurantId.value);
  const type = fulfillmentType?.value ?? undefined;
  return useQuery({
    ...queries.takeaway.restaurant(restaurantId)._ctx.snoozedProducts(fulfillmentType),
    queryFn: () => RestaurantApi.getSnoozedProducts(restaurantId.value!, type),
    enabled,
    refetchInterval: 30 * 1000,
  });
};

export default useGetSnoozedProducts;
