import { PRODUCT_BOX_REDIRECT_KEY } from '@/constants/utils';
import useSubscriptionStore from '@/stores/subscriptionStore';
import dayjs from 'dayjs';

const useExistingCustomerStorage = () => {
  const subscriptionStore = useSubscriptionStore();

  watch(
    () => subscriptionStore.selectableSubscriptions,
    selectableSubs => {
      if (selectableSubs) {
        const timestamp = window.localStorage.getItem(PRODUCT_BOX_REDIRECT_KEY);
        if (!timestamp || (timestamp && dayjs().isAfter(dayjs(timestamp)))) {
          window.localStorage.setItem(PRODUCT_BOX_REDIRECT_KEY, new Date().toISOString());
        }
      }
    },
    { immediate: true }
  );
};

export default useExistingCustomerStorage;
