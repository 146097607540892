import { useScriptTag, whenever } from '@vueuse/core';
import { App } from 'vue';

interface Options {
  clientId: string;
  scope?: string;
  redirectURI?: string;
  usePopup?: boolean;
  state?: string;
}

const isSdkLoaded = ref(false);

const getRedirectUrl = () => {
  const { protocol, host } = window.location;
  return `${protocol}//${host}`;
};

export const useAppleLogin = () => {
  const handleClick = () => {
    if (!isSdkLoaded.value) {
      throw new Error('Apple Login SDK not loaded');
    }
    return window.AppleID.auth.signIn();
  };
  return { isSdkLoaded: readonly(isSdkLoaded), appleLoginRequest: handleClick };
};

export default {
  install: (
    _: App,
    {
      clientId,
      scope = 'name email',
      redirectURI = getRedirectUrl(),
      usePopup = true,
      state = 'origin:web',
    }: Options
  ) => {
    useScriptTag(
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
      () => {
        isSdkLoaded.value = true;
      }
    );

    whenever(isSdkLoaded, () => {
      window.AppleID.auth.init({
        clientId,
        scope,
        redirectURI,
        state,
        usePopup,
      });
    });
  },
};
