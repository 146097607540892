import { acceptHMRUpdate, defineStore } from 'pinia';
import { Product } from '@pasta-evangelists/pasta-types';
import { GetCollectionByHandleQuery } from '@/api/graphql/generated/shopify';
import * as WeeklyMenusApi from '@/api/products';
import { foodFilterDictionary } from '@/constants/menus';
import { getWeeklyMenusKeys, isMenuPastCutoff, isWeekInPast } from '@/utils/date';
import dayjs from 'dayjs';

interface WeeklyMenusState {
  menus: Record<string, { loading: boolean; data: Product[]; error: boolean }>;
  isFetchingFutureMenus: boolean;
}

type ProductVariant = Omit<Product, 'variants'> & Product['variants'][0];

const transformEdge = (node: GetCollectionByHandleQuery['collection']): Product[] => {
  if (!node) return [];
  return node.products.edges
    .map(product => {
      const productVariants = product.node.variants.edges.map(variant => {
        const {
          node: { availableForSale, id, priceV2, selectedOptions, quantityAvailable, sku },
        } = variant;

        const isDouble = Boolean(selectedOptions.find(option => option.value.includes('Double')));

        // to get the ID we need to decode the BASE64 string
        const idList = id.split('/');

        return {
          id: idList[idList.length - 1],
          availableForSale,
          price: priceV2.amount,
          isDouble,
          quantityAvailable: quantityAvailable as number,
          sku,
        };
      });
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        node: { variants, id, descriptionHtml, ...rest },
      } = product;

      // to get the ID we need to decode the BASE64 string
      const idList = id.split('/');
      return {
        ...rest,
        descriptionHtml,
        id: idList[idList.length - 1],
        variants: productVariants.filter(variant => variant.sku),
        featuredImageUrl: product.node.images.edges[0]?.node?.url || '',
        images: product.node.images.edges.map(image => image.node.url),
      };
    })
    .filter(product => product.variants.length);
};

const createInitialState = () => {
  const weeklyMenus = getWeeklyMenusKeys();
  const keys = [weeklyMenus[0], dayjs(weeklyMenus[0]).subtract(1, 'week').format('YYYY-MM-DD')];
  return keys.reduce((acc, curr) => {
    acc[curr] = {
      loading: false,
      error: false,
      data: [],
    };
    return acc;
  }, {} as Record<string, { loading: boolean; data: Product[]; error: boolean }>);
};

const useWeeklyMenuStore = defineStore({
  id: 'weeklyMenu',
  state: (): WeeklyMenusState => ({
    menus: createInitialState(),
    isFetchingFutureMenus: false,
  }),
  getters: {
    products(): Record<string, Product> | null {
      if (!this.menus) return null;
      return Object.values(this.menus).reduce((acc, curr) => {
        curr.data?.forEach(product => {
          if (!acc[product.id]) {
            acc[product.id] = JSON.parse(JSON.stringify(product));
          }
        });
        return acc;
      }, {} as Record<string, Product>);
    },
    productVariants(): Record<string, ProductVariant> | null {
      if (!this.menus || !this.products) return null;
      return Object.values(this.products).reduce((acc, curr) => {
        const { variants, ...rest } = curr;
        variants.forEach(variant => {
          if (!acc[variant.id]) {
            acc[variant.id] = {
              ...rest,
              ...variant,
            };
          }
        });
        return acc;
      }, {} as Record<string, ProductVariant>);
    },
    availableWeeks: state =>
      state.menus
        ? Object.keys(state.menus)
            .filter(week => !isWeekInPast(week) && !isMenuPastCutoff(week))
            .sort((a, b) => +new Date(a) - +new Date(b))
        : [],
    productsWithWeeklyMenus: state => {
      if (!state.menus) return null;
      return Object.entries(state.menus).reduce((acc, [menu, { data }]) => {
        for (const product of data) {
          for (const variant of product.variants) {
            if (acc[variant.id]) {
              acc[variant.id].push(menu);
            } else {
              acc[variant.id] = [menu];
            }
          }
        }
        return acc;
      }, {} as Record<string, string[]>);
    },
    getOneOffMenuProductVariantIds(): string[] {
      if (!this.menus || !this.availableWeeks) return [];
      const menuIndex = dayjs().day() < 5 ? 0 : 1;
      return this.menus[this.availableWeeks[menuIndex]].data.reduce((acc, curr) => {
        curr.variants.forEach(variant => acc.push(variant.id));
        return acc;
      }, [] as string[]);
    },
  },
  actions: {
    async fetchMenus() {
      // We assume the menu from the current week and the past week must exist
      Object.keys(this.menus).forEach(this.fetchMenu);
      this.isFetchingFutureMenus = true;

      // Fetch all available menus and exclude past and fetched menus
      const allAvailableHandles = await WeeklyMenusApi.getAvailableMenus();
      const futureHandlesExcludingAlreadyAssumed = allAvailableHandles.collections.edges
        .filter(
          edge =>
            dayjs(edge.node.handle).isAfter(dayjs()) &&
            !Object.keys(this.menus).includes(edge.node.handle)
        )
        .map(edge => edge.node.handle)
        .sort((a, b) => +new Date(a) - +new Date(b));

      if (!futureHandlesExcludingAlreadyAssumed.length) {
        this.isFetchingFutureMenus = false;
      }

      // Since we know those menus exists on shopify we can safely save them
      // into the store.
      futureHandlesExcludingAlreadyAssumed.forEach(handle => {
        this.menus[handle] = {
          loading: true,
          error: false,
          data: [],
        };
      });

      // Prioritize fetching menus from week 2 and week 3
      const priorityHandles = futureHandlesExcludingAlreadyAssumed.slice(0, 2);
      await Promise.all(priorityHandles.map(handle => this.fetchMenu(handle)));

      // Fetch the rest of the menus
      const remainingHandles = futureHandlesExcludingAlreadyAssumed.slice(2);
      Promise.all(remainingHandles.map(handle => this.fetchMenu(handle))).finally(() => {
        this.isFetchingFutureMenus = false;
      });
    },
    async fetchMenu(key: string) {
      if (!this.menus[key]) return;

      this.menus[key].loading = true;
      try {
        const result = await WeeklyMenusApi.getWeeklyMenu(key);
        this.menus[key].data = transformEdge(result.collection);
        this.menus[key].error = false;
      } catch (e) {
        this.menus[key].error = true;
      } finally {
        this.menus[key].loading = false;
      }
    },
    getLengthForFilter(week: string, filter?: string) {
      if (!filter || !(filter in foodFilterDictionary)) return this.menus[week].data.length;
      return this.menus[week].data.filter(prod =>
        prod.tags.some(tag =>
          foodFilterDictionary[filter as keyof typeof foodFilterDictionary].includes(tag)
        )
      ).length;
    },
  },
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useWeeklyMenuStore, import.meta.hot));

export default useWeeklyMenuStore;
