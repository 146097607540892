import { acceptHMRUpdate, defineStore } from 'pinia';
import * as AuthApi from '@/api/auth';
import debounce from 'lodash.debounce';
import useGetUser from '@/api/queries/pensa/useGetUser';
import { useQueryClient } from '@tanstack/vue-query';
import { queries } from '@/api/queries';
import { useCloned } from '@vueuse/core';
import { ObjectToCamel } from '@pasta-evangelists/pasta-types/dist/utils';
import { CustomerData } from '@pasta-evangelists/pasta-types/dist/customer';

const handlePaperlessTagChange = debounce(
  async (paperless: boolean) => {
    const queryClient = useQueryClient();
    const customerStore = currentCustomerStore();
    const localTagsCopy = [...customerStore.tags];
    const result = await AuthApi.updatePaperlessOption(paperless);
    if (!result.error) {
      queryClient.setQueriesData<ObjectToCamel<CustomerData>>(
        queries.customer.details,
        customer => {
          const { cloned: tempCustomer } = useCloned(customer);
          if (tempCustomer.value) {
            tempCustomer.value.attributes.tags = localTagsCopy;
          }
          return tempCustomer.value;
        }
      );
    } else {
      let tagsCopy: string[];
      if (paperless) {
        tagsCopy = [...customerStore.tags.filter(tag => tag !== 'paperless'), 'paperless'];
      } else {
        tagsCopy = customerStore.tags.filter(tag => tag !== 'paperless');
      }

      queryClient.setQueriesData<ObjectToCamel<CustomerData>>(
        queries.customer.details,
        customer => {
          const { cloned: tempCustomer } = useCloned(customer);
          if (tempCustomer.value) {
            tempCustomer.value.attributes.tags = tagsCopy;
          }
          return tempCustomer.value;
        }
      );
    }
    queryClient.invalidateQueries(queries.customer.details);
  },
  300,
  { leading: true, trailing: true }
);

const currentCustomerStore = defineStore('currentCustomerStore', () => {
  const queryClient = useQueryClient();
  const { data: customer, isLoading: loading, error, refetch: getUserDetails } = useGetUser();

  const hasCredits = computed(
    () =>
      customer.value?.attributes.storeCredits &&
      parseFloat(customer.value?.attributes.storeCredits) > 0
  );

  const isMarketingTurnedOff = computed(
    () =>
      !customer.value?.attributes.acceptsEmails &&
      !customer.value?.attributes.acceptsSms &&
      !customer.value?.attributes.acceptsMail
  );

  const email = computed(() => customer.value?.attributes.email || '');

  const firstName = computed(() => customer.value?.attributes.firstName || '');

  const lastName = computed(() => customer.value?.attributes.lastName || '');

  const phone = computed(() => customer.value?.attributes.phone || '');

  const storeCredits = computed(() => customer.value?.attributes.storeCredits || '');

  const tags = computed(() => customer.value?.attributes.tags || []);

  const hasShopifyOrders = computed(() => !!customer.value?.attributes.hasShopifyOrders);

  const hasSubscriptionOrders = computed(() => !!customer.value?.attributes.hasSubscriptionOrders);

  const hasTakeawayOrders = computed(() => !!customer.value?.attributes.hasTakeawayOrders);

  const extId = computed(() => customer.value?.attributes.extId || '');

  const newCustomer = computed(() => !!customer.value?.attributes.newCustomer);

  const socialLogin = computed(() => customer.value?.attributes.socialLogin);

  const acceptsEmails = computed(() => !!customer.value?.attributes.acceptsEmails);

  const acceptsMail = computed(() => !!customer.value?.attributes.acceptsMail);

  const acceptsSms = computed(() => !!customer.value?.attributes.acceptsSms);

  const reduceOrderCredits = (amount: number) => {
    queryClient.setQueriesData<ObjectToCamel<CustomerData>>(queries.customer.details, customer => {
      const { cloned: tempCustomer } = useCloned(customer);
      if (tempCustomer.value) {
        tempCustomer.value.attributes.storeCredits = Math.max(
          parseFloat(tempCustomer.value.attributes.storeCredits) - amount,
          0
        ).toFixed(2);
      }
      return tempCustomer.value;
    });
    queryClient.invalidateQueries(queries.customer.details);
  };

  const updatePaperlessTag = async (paperless: boolean) => {
    let tagsCopy: string[];
    if (paperless) {
      tagsCopy = [...tags.value.filter(tag => tag !== 'paperless'), 'paperless'];
    } else {
      tagsCopy = tags.value.filter(tag => tag !== 'paperless');
    }

    queryClient.setQueriesData<ObjectToCamel<CustomerData>>(queries.customer.details, customer => {
      const { cloned: tempCustomer } = useCloned(customer);
      if (tempCustomer.value) {
        tempCustomer.value.attributes.tags = tagsCopy;
      }
      return tempCustomer.value;
    });

    handlePaperlessTagChange(paperless);
  };

  return {
    id: customer.value?.id || '',
    email,
    firstName,
    lastName,
    phone,
    storeCredits,
    tags,
    hasShopifyOrders,
    hasSubscriptionOrders,
    hasTakeawayOrders,
    extId,
    newCustomer,
    socialLogin,
    acceptsEmails,
    acceptsMail,
    acceptsSms,
    loading,
    error,
    hasCredits,
    isMarketingTurnedOff,
    getUserDetails,
    reduceOrderCredits,
    updatePaperlessTag,
  };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(currentCustomerStore, import.meta.hot));

export default currentCustomerStore;
