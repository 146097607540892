import { useMutation, useQueryClient } from '@tanstack/vue-query';
import * as AuthApi from '@/api/auth';
import { MutationConfig } from '@/types';
import { queries } from '@/api/queries';
import useDeliveryStore from '@/stores/deliveryStore';

type SkipOptions = {
  config?: MutationConfig<typeof AuthApi.logout>;
};

const useLogout = ({ config }: SkipOptions = {}) => {
  const queryClient = useQueryClient();
  const deliveryStore = useDeliveryStore();
  return useMutation({
    ...config,
    mutationFn: AuthApi.logout,
    onSuccess: () => {
      queryClient.setQueriesData(queries.customer.details, null);
      queryClient.invalidateQueries(queries.orders.scheduleWeeks);
      queryClient.invalidateQueries(queries.orders.upcoming);
      queryClient.invalidateQueries(queries.orders.past);
      queryClient.invalidateQueries(queries.addresses.all);
      queryClient.invalidateQueries(queries.payments.all);
      queryClient.invalidateQueries(queries.subscriptions.all);
      queryClient.invalidateQueries(queries.baskets.all);
      deliveryStore.resetStore();
    },
  });
};

export default useLogout;
