<template>
  <BaseModal
    :is-open="isOpen"
    class="rounded-none sm:rounded-none bg-sandstone"
    @update:is-open="close"
  >
    <template #closeBtn>
      <div />
      <button
        class="flex items-center self-end justify-center p-2 rounded-full bg-linen"
        @click="close"
      >
        <XIcon class="size-4 text-terracota-dark" />
      </button>
    </template>
    <template #default>
      <div class="px-2 lg:px-6">
        <div class="text-3xl text-center font-brand text-terracota-dark">
          Takeaway is not available at your delivery address.
        </div>
        <p class="mt-2 text-center text-burnt-sienna">
          But you can still get fresh pasta recipe kits delivered next day (order before 5:00pm).
        </p>
        <div class="flex flex-col items-center mt-6">
          <button class="primary-cta" @click="goToRecipeKits">Get a recipe kit</button>
          <button class="mt-6 underline text-terracota-dark" @click="goToAddressSearch">
            Or check a different address
          </button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
  import { XIcon } from '@heroicons/vue/solid';
  import { useRouter } from 'vue-router';

  interface Props {
    isOpen: boolean;
  }

  interface Emits {
    (e: 'update:isOpen', value: boolean): void;
    (e: 'click'): void;
  }

  defineProps<Props>();
  const emits = defineEmits<Emits>();

  const router = useRouter();

  const goToAddressSearch = () => {
    router.push({ name: 'pasta-now-search-address' });
    emits('click');
    close();
  };

  const goToRecipeKits = () => {
    router.push({ name: 'product-box', query: { dtw: 'true' } });
    emits('click');
    close();
  };

  const close = () => {
    emits('update:isOpen', false);
  };
</script>
