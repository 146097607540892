<template>
  <div :class="twMerge('pb-2 border-b border-dark-linen group', classes)">
    <button
      type="button"
      class="relative block w-full touch-hightlight-none"
      @click="toggleCollapse(question)"
    >
      <div :class="twMerge('flex items-center justify-between w-full text-off-black', textClasses)">
        <div>
          {{ question }}
        </div>
        <div>
          <ChevronDownIcon v-if="!isCollapsed" :class="twMerge('size-5', iconClasses)" />
          <ChevronUpIcon v-else :class="twMerge('size-5', iconClasses)" />
        </div>
      </div>
    </button>
    <p v-if="isCollapsed" class="pb-2 mt-4 text-sm text-brown-grey">
      {{ answer }}
    </p>
  </div>
</template>

<script setup lang="ts">
  import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/outline';
  import { trackCustomEvent } from '@/utils/analytics';
  import { twMerge } from 'tailwind-merge';

  interface Props {
    question: string;
    answer: string;
    classes?: string;
    iconClasses?: string;
    textClasses?: string;
  }

  defineProps<Props>();

  const isCollapsed = ref(false);

  const toggleCollapse = (text: string) => {
    if (!isCollapsed.value)
      trackCustomEvent('CTA_clicked', {
        click_id: 'menuRK_FAQs_expandbutton',
        click_text: text,
        click_url: window.location.href,
      });
    isCollapsed.value = !isCollapsed.value;
  };
</script>
