<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="m18.082 14.9 1.618-.436-.286-1.076-2.683.726L13.104 12l3.627-2.114 2.683.726.287-1.076-1.619-.437L20 7.981l-.552-.963-1.918 1.117.434-1.632-1.065-.288-.72 2.707-3.627 2.114V6.809l1.965-1.983-.78-.787-1.185 1.195V3h-1.104v2.234L10.264 4.04l-.78.787 1.964 1.983v4.227L7.821 8.922 7.1 6.214l-1.065.288.433 1.632-1.918-1.116-.55.964L5.918 9.1 4.3 9.536l.286 1.076 2.683-.726L10.896 12 7.27 14.114l-2.683-.726-.286 1.076 1.618.437L4 16.018l.552.964 1.918-1.117-.434 1.632 1.065.288.72-2.707 3.627-2.114v4.228l-1.964 1.982.78.787 1.184-1.195V21h1.104v-2.234l1.184 1.195.78-.787-1.964-1.982v-4.228l3.627 2.114.72 2.707 1.065-.288-.433-1.631 1.918 1.116.55-.964-1.917-1.118Z"
      fill="currentcolor"
    />
  </svg>
</template>
