import useCurrentCustomerStore from '@/stores/currentCustomerStore';
import { getAllUserInfo, sha256 } from '@/utils';
import { watch, onBeforeMount } from 'vue';
import { trackCustomEvent } from '@/utils/analytics';

const useRefreshDetails = () => {
  const customerStore = useCurrentCustomerStore();

  const hasTokenParam = () => {
    const params = new URLSearchParams(window.location.search);
    return Boolean(params.get('token'));
  };

  onBeforeMount(async () => {
    if (hasTokenParam()) {
      return;
    }
    await customerStore.getUserDetails();
    if (!customerStore.email)
      trackCustomEvent('new_or_existing', {
        new_or_existing_TW: 'Undefined',
        new_or_existing_RK: 'Undefined',
        new_or_existing_Shopify: 'Undefined',
        shopify_id: null,
      });
  });

  const trackEmailAddress = async (email: string) => {
    trackCustomEvent('user_email_updated', {
      em: email,
    });

    const hashedEmail = await sha256(email);

    trackCustomEvent('user_email_updated', {
      email_address: hashedEmail,
    });
  };

  watch(
    () => customerStore.email,
    (newValue, oldValue) => {
      if (newValue && newValue !== oldValue && !hasTokenParam()) {
        getAllUserInfo();
        trackCustomEvent('new_or_existing', {
          new_or_existing_TW: customerStore.hasTakeawayOrders ? 'Existing' : 'New',
          new_or_existing_RK: customerStore.hasSubscriptionOrders ? 'Existing' : 'New',
          new_or_existing_Shopify: customerStore.hasShopifyOrders ? 'Existing' : 'New',
          shopify_id: customerStore.extId,
        });
        trackEmailAddress(newValue);
      }
    },
    { immediate: true }
  );
};

export default useRefreshDetails;
