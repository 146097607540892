import { acceptHMRUpdate, defineStore } from 'pinia';
import * as ApiCheckout from '@/api/checkout';
import useAddressStore from './addressStore';
import useSubscriptionStore from './subscriptionStore';
import useCustomerStore from '@/stores/currentCustomerStore';
import { DELIVERY_LOCAL_STORE_KEY } from '@/constants/utils';
import dayjs from 'dayjs';
import { serializeAddress } from '@/utils/address';
import * as PaymentApi from '@/api/payment';
import { CheckoutAddress } from '@pasta-evangelists/pasta-types';
import useBasketStore from './basketStore';

interface DeliveryStoreState {
  billingAddress: CheckoutAddress | null;
  billingAddressId: string;
  createdSubId: string | null;
}

export const serializeToLocalStorage = (state: DeliveryStoreState): Partial<DeliveryStoreState> => {
  const { billingAddress, billingAddressId } = state;
  return {
    billingAddress,
    billingAddressId,
  };
};

const loadDeliveryStateFromStorage = () => {
  const localStorageState = localStorage.getItem(DELIVERY_LOCAL_STORE_KEY);
  if (localStorageState) {
    const parsedState = JSON.parse(localStorageState);

    const { updatedAt, data } = parsedState;
    const isNotExpired = dayjs(updatedAt).isAfter(dayjs().subtract(1, 'week'));
    if (isNotExpired) {
      return data;
    } else {
      localStorage.removeItem(DELIVERY_LOCAL_STORE_KEY);
    }
    return null;
  }
  return null;
};

const useDeliveryStore = defineStore({
  id: 'deliveryStore',
  state(): DeliveryStoreState {
    const savedState = loadDeliveryStateFromStorage() || {};
    return {
      instructions: {
        deliveryNote: '',
        firstDelivery: '',
      },
      deliveryFrequency: 1,
      billingAddress: null,
      shippingAddressId: '',
      billingAddressId: '',
      createdSubId: null,
      ...savedState,
    };
  },
  actions: {
    saveAddress(address: Partial<CheckoutAddress>, type: 'billingAddress') {
      this[type] = {
        address1: '',
        address2: '',
        city: '',
        zip: '',
        company: '',
        region: '',
        regionCode: '',
        country: '',
        countryCode: '',
        firstName: '',
        lastName: '',
        phone: '',
        lat: null,
        lon: null,
        ...address,
      };

      this.billingAddressId = '';
    },
    resetAddress() {
      this.billingAddressId = '';
      this.billingAddress = null;
    },
    resetCreatedSubId() {
      this.createdSubId = null;
    },
    async makePayment(paymentMethodId?: string) {
      const addressStore = useAddressStore();
      const subscriptionStore = useSubscriptionStore();
      const customerStore = useCustomerStore();
      const currentBasketStore = useBasketStore();

      const serializedBillingAddressFound =
        !this.billingAddressId &&
        this.billingAddress &&
        addressStore.addressesByEncoding?.[serializeAddress(this.billingAddress)];

      if (serializedBillingAddressFound) this.billingAddressId = serializedBillingAddressFound.id;

      if (!this.billingAddressId && this.billingAddress) {
        const billingAddressPayload = {
          ...this.billingAddress,
        };

        const result = await addressStore.saveAddress(billingAddressPayload);
        if (result.error) {
          return result;
        } else {
          this.billingAddressId = result.data.id;
        }
      }

      const orderCreationResult = await ApiCheckout.createOrderForBasket({
        basketId: currentBasketStore.basketKey || '',
        paymentMethodId,
      });

      if (orderCreationResult.data?.subscription) {
        subscriptionStore.selectSub(orderCreationResult.data.subscription.data.id);
      }

      customerStore.reduceOrderCredits(currentBasketStore.total);

      if (
        !paymentMethodId &&
        orderCreationResult.data &&
        !orderCreationResult.data.paymentIntentClientSecret
      ) {
        const result = await PaymentApi.getSetupIntent();
        if (result.data?.setupIntent) {
          return {
            data: {
              ...orderCreationResult.data,
              paymentIntentClientSecret: result.data.setupIntent,
            },
            error: null,
          };
        }
      }

      return orderCreationResult;
    },
    loadFromLocalstorage() {
      const result = loadDeliveryStateFromStorage();
      if (result) {
        this.$patch(state => {
          state = { ...result };
          return state;
        });
      }
    },
    resetStore() {
      localStorage.removeItem(DELIVERY_LOCAL_STORE_KEY);
      this.$reset();
    },
  },
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useDeliveryStore, import.meta.hot));

export default useDeliveryStore;
