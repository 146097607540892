<template>
  <TransitionRoot
    :show="!!message"
    enter="transition transition-opacity transform ease-in-out duration-300"
    enter-from="opacity-0 -translate-y-20"
    enter-to="opacity-100 translate-y-0"
    leave="transition transition-opacity transform ease-in-out duration-300"
    leave-from="opacity-100 translate-y-0"
    leave-to="opacity-0 -translate-y-20"
  >
    <div ref="flashMessage" class="absolute z-10 flex w-full px-4 mt-4">
      <div class="w-full p-4 rounded shadow bg-message-green">
        <div class="flex justify-between">
          <div class="flex items-center flex-1 w-0 ml-3 space-x-2 sm:space-x-4">
            <CheckCircleIcon class="size-6" />
            <p class="text-sm font-medium">{{ message }}</p>
          </div>
          <button class="flex items-center" aria-label="close" @click="close">
            <XIcon class="size-5" />
          </button>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>

<script setup lang="ts">
  import useFlashMessage from '@/composables/useFlashMessage';
  import { onClickOutside } from '@vueuse/core';
  import { XIcon } from '@heroicons/vue/solid';
  import { CheckCircleIcon } from '@heroicons/vue/outline';
  import { TransitionRoot } from '@headlessui/vue';

  const { message, close } = useFlashMessage();

  const flashMessage = ref<HTMLElement>();

  onClickOutside(flashMessage, close);
</script>
