import { DIXA_CONTROLS, DIXA_LOADED } from '@/constants/injections';
import { App } from 'vue';

interface Options {
  key: string;
  hideOnLoad?: boolean;
  disabled?: boolean;
}

interface InitPayload {
  messengerToken: string;
  hideToggler?: boolean;
  language?: string;
  showWidgetCloseButton?: boolean;
  userIdentity?:
    | {
        type: 'claim';
        name: string;
        email: string;
      }
    | {
        type: 'anonymous';
      };
}

export type DixaInvokeFunc = {
  (action: 'setWidgetOpen', variable: boolean): void;
  (action: 'init', initPayload: InitPayload): void;
};

const NOOP = () => {};

export const useDixa = () => {
  const { show, hide, open, close, claimIdentity, load } = inject(DIXA_CONTROLS, {
    show: NOOP,
    hide: NOOP,
    open: NOOP,
    close: NOOP,
    claimIdentity: NOOP,
    load: NOOP,
  });
  const isLoaded = inject(DIXA_LOADED, ref(false));
  return { show, hide, open, close, claimIdentity, load, isLoaded };
};

export default {
  install: (app: App, options: Options) => {
    const isLoaded = ref(false);

    if (isLoaded.value || options.disabled || window._dixa_) return;

    const load = () => {
      window._dixa_ = {
        invoke: function (m: unknown, a: unknown) {
          if (window._dixa_) window._dixa_.pendingCalls = window._dixa_.pendingCalls || [];
          if (window._dixa_) window._dixa_.pendingCalls.push([m, a]);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;

      const dixaScript = document.createElement('script');
      dixaScript.id = 'ze-snippet';
      dixaScript.type = 'text/javascript';
      dixaScript.async = true;
      dixaScript.src = 'https://messenger.dixa.io/bootstrap.js';

      document.head.appendChild(dixaScript);

      dixaScript.onload = () => {
        isLoaded.value = true;
        if (options.hideOnLoad && window._dixa_) {
          window._dixa_.invoke('init', {
            messengerToken: options.key,
            hideToggler: options.hideOnLoad,
          });
        }
      };
    };

    const show = () => {
      if (window._dixa_) {
        window._dixa_.invoke('setWidgetOpen', true);
      }
    };
    const hide = () => {
      if (window._dixa_) {
        window._dixa_.invoke('setWidgetOpen', false);
      }
    };
    const open = () => {
      show();
    };
    const close = () => {
      hide();
    };

    const claimIdentity = ({ name, email }: { name: string; email: string }) => {
      if (window._dixa_) {
        window._dixa_.invoke('init', {
          messengerToken: options.key,
          userIdentity: {
            type: 'claim',
            name,
            email,
          },
          hideToggler: options.hideOnLoad,
        });
      }
    };

    app.provide(DIXA_CONTROLS, { show, hide, open, close, claimIdentity, load });
    app.provide(DIXA_LOADED, isLoaded);
  },
};
