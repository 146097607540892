import { NormalizedBasket, ObjectToCamel } from '@pasta-evangelists/pasta-types';
import * as BasketApi from '@/api/basket';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { MutationConfig } from '@/types';
import { queries } from '@/api/queries';

type CreateBasketOptions = {
  config?: MutationConfig<typeof BasketApi.createBasket>;
};

const useCreateBasket = ({ config }: CreateBasketOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    onSuccess: result => {
      queryClient.setQueryData<ObjectToCamel<NormalizedBasket>>(
        queries.baskets.all.queryKey,
        result
      );
      return queryClient.invalidateQueries(queries.baskets.all);
    },
    ...config,
    mutationFn: BasketApi.createBasket,
  });
};

export default useCreateBasket;
