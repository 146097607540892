import { gql } from 'graphql-request';

export const getCollectionQuery = gql`
  query GetCollection($query: String!) {
    collections(first: 3, query: $query) {
      edges {
        node {
          handle
          products(first: 100) {
            edges {
              node {
                title
                descriptionHtml
                id
                tags
                productType
                availableForSale
                images(first: 5) {
                  edges {
                    node {
                      url
                      id
                      altText
                    }
                  }
                }
                variants(first: 2) {
                  edges {
                    node {
                      id
                      sku
                      availableForSale
                      selectedOptions {
                        name
                        value
                      }
                      availableForSale
                      priceV2 {
                        amount
                      }
                      quantityAvailable
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getSingleCollectionQuery = gql`
  query getCollectionByHandle($handle: String!) {
    collection(handle: $handle) {
      handle
      products(first: 100) {
        edges {
          node {
            title
            descriptionHtml
            id
            tags
            productType
            availableForSale
            images(first: 5) {
              edges {
                node {
                  url
                  id
                  altText
                }
              }
            }
            variants(first: 2) {
              edges {
                node {
                  id
                  sku
                  availableForSale
                  selectedOptions {
                    name
                    value
                  }
                  availableForSale
                  priceV2 {
                    amount
                  }
                  quantityAvailable
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getCollectionHandles = gql`
  query GetCollectionHandles {
    collections(first: 20, reverse: true) {
      edges {
        node {
          handle
        }
      }
    }
  }
`;
