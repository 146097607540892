/* eslint-disable @typescript-eslint/no-explicit-any */
import { build, fake, sequence, bool } from '@jackfranklin/test-data-bot';
import type { Basket, BasketItem } from '@pasta-evangelists/pasta-types';
import * as uuid from 'uuid';
import {
  Product,
  ProductVariant,
  BasketVoucher,
  Schedule,
  Subscription,
  PaymentMethod,
} from '@pasta-evangelists/pasta-types';
import { Address } from '@/stores/addressStore';

export const addressBuilder = build('Address', {
  fields: {
    address1: fake(f => f.address.streetAddress()),
    address2: fake(f => f.address.secondaryAddress()),
    city: fake(f => f.address.city()),
    zip: fake(f => f.address.zipCode()),
  },
});

export const basketItemBuilder = build<BasketItem>('BasketItem', {
  fields: {
    id: sequence(),
    type: 'basket_item',
    attributes: {
      discount: '0.0',
      image_url: fake(f => f.image.food()),
      is_double: bool(),
      meal_week: fake(f => f.date.future().toUTCString()),
      price: fake(f => f.commerce.price(10, 20)),
      quantity: fake(f => f.datatype.number({ min: 1, max: 5 })),
      title: fake(f => f.commerce.productName()),
      product_variant_id: fake(f => f.datatype.number()),
      product_variant_ext_id: fake(f => f.datatype.uuid()),
    },
  },
});

export const basketBuilder = (items: BasketItem[], voucher?: BasketVoucher): Basket => {
  const relationshipItems = items.map(item => {
    return { id: item.id, type: 'basket_item' };
  });
  const relationshipVoucher = voucher ? { id: voucher.id, type: 'voucher' } : null;
  const includedItems = voucher ? [...items, voucher] : items;
  return {
    data: {
      id: uuid.v4(),
      attributes: {
        totalDiscount: '0.0',
        totalPrice: '0.0',
        subtotalPrice: '0.0',
        totalLineItemsPrice: '0.0',
        deliveryDate: null,
        type: 'SubscriptionBasket',
        timeSlot: null,
        address: {
          address1: null,
          address2: null,
          city: null,
          firstName: null,
          lastName: null,
          lat: null,
          lon: null,
          phone: null,
          zip: null,
          company: null,
          country: null,
          countryCode: null,
          region: null,
          regionCode: null,
        },
        fulfillmentType: null,
        asap: false,
        timeSlotEndTime: null,
        customerEmail: null,
      },
      relationships: {
        customer: { data: null },
        items: { data: relationshipItems },
        voucher: { data: relationshipVoucher },
        shipment: {
          data: {
            id: uuid.v4(),
            type: 'basket_shipment',
          },
        },
      },
      type: 'basket',
      meta: {
        minimumSpend: '10.00',
        minimumSpendPerOrder: '10.00',
        requestCard: false,
      },
    },
    included: includedItems,
  };
};

export const variantBuilder = build<ProductVariant>('ProductVariant', {
  fields: {
    availableForSale: fake(() => true),
    id: fake(f => f.datatype.uuid()),
    isDouble: fake(f => f.datatype.boolean()),
    price: fake(f => f.commerce.price(10, 20)),
    quantityAvailable: fake(f => f.datatype.number(1000)),
  },
});

export const productBuilder = build<Product>('Product', {
  fields: {
    id: sequence(),
    descriptionHtml: fake(f => f.lorem.paragraph()),
    availableForSale: fake(() => true),
    featuredImageUrl: fake(f => f.image.food()),
    images: [fake(f => f.image.food())],
    productType: 'product',
    tags: [],
    variants: [
      variantBuilder({ overrides: { isDouble: false as any } }),
      variantBuilder({ overrides: { isDouble: true as any } }),
    ],
    title: fake(f => f.commerce.productName()),
  },
});

export const voucherBuilder = build<BasketVoucher>('BasketVoucher', {
  fields: {
    id: fake(f => f.datatype.uuid()),
    type: 'voucher',
    attributes: {
      code: 'VALIDCODE',
      startDate: fake(f => f.date.past().toUTCString()),
      endDate: fake(f => f.date.future().toUTCString()),
      type: fake(f => f.random.arrayElement(['DiscountVoucher', 'AddonVoucher'])),
      valueType: fake(f => f.random.arrayElement(['fixed_amount', 'percentage'])),
      productVariantIds: [],
      value: fake(f => f.commerce.price(1, 10)),
    },
  },
});

export const subscriptionBuilder = build<Subscription>('Subscription', {
  fields: {
    id: sequence(),
    type: 'subscription',
    attributes: {
      extId: sequence(),
      state: 'active',
      numberOfRecipes: fake(f => f.datatype.number({ min: 2, max: 12 })),
      deliveryNote: fake(f =>
        f.random.arrayElement(['leave with the neighbor', 'leave with the dog'])
      ),
      beef: fake(f => f.datatype.boolean()),
      cocktails: fake(f => f.datatype.boolean()),
      crustaceans: fake(f => f.datatype.boolean()),
      doublePortion: fake(f => f.datatype.boolean()),
      fish: fake(f => f.datatype.boolean()),
      game: fake(f => f.datatype.boolean()),
      glutenFree: fake(f => f.datatype.boolean()),
      lamb: fake(f => f.datatype.boolean()),
      name: fake(f => f.random.word()),
      numberOfCocktails: fake(f => f.datatype.number({ min: 1, max: 8 })),
      nuts: fake(f => f.datatype.boolean()),
      pork: fake(f => f.datatype.boolean()),
      vegan: fake(f => f.datatype.boolean()),
      vegetarian: fake(f => f.datatype.boolean()),
      molluscs: fake(f => f.datatype.boolean()),
    },
    relationships: {
      schedule: {
        data: {
          id: sequence(),
          type: 'subscription_schedule',
        },
      },
      customer: {
        data: {
          id: sequence(),
          type: 'customer',
        },
      },
      shippingAddress: {
        data: {
          id: sequence(),
          type: 'address',
        },
      },
      billingAddress: {
        data: {
          id: sequence(),
          type: 'address',
        },
      },
      paymentMethod: {
        data: {
          id: sequence(),
          type: 'payment_method',
        },
      },
      customerVouchers: {
        data: null,
      },
    },
  },
});

export const scheduleBuilder = build<Schedule>('Subscription', {
  fields: {
    id: sequence(),
    type: 'subscription_schedule',
    attributes: {
      deliveryDay: fake(f =>
        f.random.arrayElement(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'])
      ),
      firstDelivery: fake(f => f.date.past().toUTCString()),
      frequency: fake(f => f.datatype.number({ min: 1, max: 4 })),
    },
    relationships: {
      subscription: {
        data: {
          id: sequence(),
          type: 'subscription',
        },
      },
      weeks: [],
    },
  },
});

export const cardBuilder = build<PaymentMethod>('PaymentMethod', {
  fields: {
    id: sequence(),
    type: 'payment_method',
    attributes: {
      last4: fake(f => f.random.arrayElement(['4242', '4444', '0005'])),
      brand: fake(f => f.random.arrayElement(['visa', 'mastercard', 'amex'])),
      expMonth: fake(f => f.datatype.number({ min: 1, max: 12 })),
      expYear: fake(f => f.datatype.number({ min: 2022, max: 2045 })),
      processorId: fake(f => f.datatype.number({ min: 2022, max: 2045 })).toString(),
      default: false,
      type: 'CreditCard',
    },
    relationships: {
      customer: {
        data: {
          id: sequence(),
          type: 'customer',
        },
      },
      paymentProcessor: {
        data: {
          id: sequence(),
          type: 'payment_processor',
        },
      },
    },
  },
});

export const fullAddressBuilder = build<Address>('Address', {
  fields: {
    id: sequence(),
    address1: fake(f => f.address.streetAddress()),
    address2: fake(f => f.address.secondaryAddress()),
    city: fake(f => f.address.city()),
    zip: fake(f => f.address.zipCode()),
    country: 'United Kingdom',
    countryCode: 'GB',
    phone: '07911 123456',
    isDefault: fake(f => f.datatype.boolean()),
    region: 'City of London',
    regionCode: 'GB-LND',
    company: '',
    firstName: 'Joe',
    lastName: 'Tester',
  },
});
