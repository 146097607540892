import { keepPreviousData, useInfiniteQuery } from '@tanstack/vue-query';
import * as OrderApi from '@/api/orders';
import { queries } from '@/api/queries';
import currentCustomerStore from '@/stores/currentCustomerStore';

const getOrderHistory = async (page: number) => {
  return await OrderApi.getPastOrders({ page: page, perPage: 4 });
};

const useGetOrderHistory = () => {
  const customerStore = currentCustomerStore();
  const enabled = computed(() => !!customerStore.email);

  return useInfiniteQuery({
    ...queries.orders.past,
    queryFn: ({ pageParam }) => getOrderHistory(pageParam),
    getNextPageParam: lastPage => lastPage.meta.nextPage || 1,
    getPreviousPageParam: lastPage => lastPage.meta.prevPage || 1,
    initialPageParam: 1,
    placeholderData: keepPreviousData,
    enabled,
  });
};

export default useGetOrderHistory;
