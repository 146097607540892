import { useMutation, useQueryClient } from '@tanstack/vue-query';
import * as AddressApi from '@/api/address';
import { MutationConfig } from '@/types';
import { queries } from '@/api/queries';
import { useCloned } from '@vueuse/core';
import { Address } from '@pasta-evangelists/pasta-types';
import { convertAddressIntoState } from '@/stores/addressStore';

type SkipOptions = {
  config?: MutationConfig<typeof AddressApi.saveAddress>;
};

const useSaveAddress = ({ config }: SkipOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...config,
    mutationFn: AddressApi.saveAddress,
    onSuccess: result => {
      queryClient.setQueriesData<Address[]>(queries.addresses.all, addresses => {
        const { cloned: tempAddresses } = useCloned(addresses, { manual: true });
        if (tempAddresses.value) {
          tempAddresses.value.push(convertAddressIntoState(result));
        }
        return tempAddresses.value;
      });
      queryClient.invalidateQueries(queries.addresses.all);
    },
  });
};

export default useSaveAddress;
