import { axios } from '@/utils/index';
import {
  Address,
  ApiResponse,
  BasketVoucher,
  ObjectToCamel,
  PromiseResponse,
} from '@pasta-evangelists/pasta-types';
import { Basket, Baskets, NormalizedBasket } from '@pasta-evangelists/pasta-types';
import { Menu, Product, ProductVariant } from '@pasta-evangelists/pasta-types';
import normalize from '@/utils/normalize';
import { decamelizeKeys } from 'humps';
import { TakeawaySubItems } from './takeawayBasket';

export interface ItemParams {
  basketId: string;
  productVariantId: number | string;
  quantity: number;
  subItems?: TakeawaySubItems[];
}

export const getBaskets = async (): ApiResponse<NormalizedBasket> => {
  try {
    const { data } = await axios.get<Baskets>('baskets');
    return { data: normalize<NormalizedBasket>(data), error: null };
  } catch (e) {
    return { data: null, error: { message: "Couldn't retrieve basket" } };
  }
};

export const addItem = async (itemParams: ItemParams): Promise<NormalizedBasket> => {
  const { data } = await axios.post<Basket>(
    `baskets/${itemParams.basketId}/items`,
    decamelizeKeys(itemParams)
  );
  return normalize<NormalizedBasket>(data);
};

interface QuantityParams {
  basketId: string;
  itemId: string;
  quantity: number;
}

export const changeQuantity = async (
  itemParams: QuantityParams
): PromiseResponse<NormalizedBasket> => {
  const params = {
    quantity: itemParams.quantity,
  };
  try {
    const { data } = await axios.put<Basket>(
      `baskets/${itemParams.basketId}/items/${itemParams.itemId}`,
      params
    );
    return normalize<NormalizedBasket>(data);
  } catch (e) {
    throw new Error('Something went wrong!');
  }
};

export const removeItem = async (params: {
  basketId: string;
  itemId: string;
}): PromiseResponse<NormalizedBasket> => {
  try {
    const { data } = await axios.delete<Basket>(
      `baskets/${params.basketId}/items/${params.itemId}`
    );
    return normalize<NormalizedBasket>(data);
  } catch (e) {
    throw new Error('Something went wrong!');
  }
};

export interface BasketItemPayload {
  mealWeek?: string;
  productVariantId: string;
  quantity: number;
}

export type CreateBasketParams =
  | {
      type: 'RecipeKitBasket';
      items?: BasketItemPayload[];
      delivery_date?: string;
      delivery_note?: string;
      frequency?: number;
      oneOffFeeApplicable?: boolean;
      startSubscription?: boolean;
    }
  | {
      type: 'TakeawayBasket';
      deliveryDate: string;
      restaurantId: string;
      fulfillmentType: 'delivery' | 'pickup' | 'eat_in';
      timeSlot?: 'asap' | string;
      address?: Omit<Address, 'id' | 'isDefault'>;
      items?: Omit<ItemParams, 'basketId'>[];
      tableNumber?: string;
    };

export const createBasket = async (params: CreateBasketParams): Promise<NormalizedBasket> => {
  const { data } = await axios.post<Basket>('baskets', decamelizeKeys(params));
  return normalize<NormalizedBasket>(data);
};
export interface VoucherParams {
  basketId: string;
  code: string;
  validate?: boolean;
}

export const addVoucher = async (params: VoucherParams): PromiseResponse<NormalizedBasket> => {
  const { basketId, ...voucherParams } = params;
  try {
    const { data } = await axios.post<Basket>(`baskets/${basketId}/vouchers`, {
      ...voucherParams,
      code: voucherParams.code.toUpperCase(),
    });
    return normalize<NormalizedBasket>(data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message = e.response?.status === 400 ? e.response.data.message : 'Invalid code';
    throw new Error(message);
  }
};

export const deleteVoucher = async (params: {
  basketId: string;
  voucherId: string;
}): PromiseResponse<NormalizedBasket> => {
  try {
    const { data } = await axios.delete<Basket>(
      `baskets/${params.basketId}/vouchers/${params.voucherId}`
    );
    return normalize<NormalizedBasket>(data);
  } catch (e) {
    throw new Error("Couldn't delete applied code");
  }
};

interface ValidateVoucherParams {
  basketId: string;
  phone?: string;
  fingerprint?: string;
}

export const validateVoucher = async (
  params: ValidateVoucherParams
): PromiseResponse<NormalizedBasket> => {
  const { basketId, ...validationParams } = params;
  try {
    const { data } = await axios.get<Basket>(`baskets/${basketId}/vouchers`, {
      params: validationParams,
    });
    return normalize<NormalizedBasket>(data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message =
      e.response?.status === 400 ? e.response.data.message : "Couldn't validate voucher";
    throw new Error(message);
  }
};

interface GetMenuServerResponse {
  data: Menu[];
  included: Array<Product | ProductVariant>;
}

export const getMenus = async (): Promise<ObjectToCamel<GetMenuServerResponse>> => {
  const result = await axios.get<ObjectToCamel<GetMenuServerResponse>>('weekly_menus');
  return result.data;
};

export const registerFingerprint = async (fingerprint: string): ApiResponse<true> => {
  try {
    await axios.post('customers/fingerprint', { fingerprint });
    return { data: true, error: null };
  } catch (e) {
    return { data: null, error: { message: "Couldn't register fingerprint" } };
  }
};

interface ChangeBasketDeliveryDateParams {
  deliveryDate: string;
  id: string;
}

export const changeBasketDeliveryDate = async ({
  deliveryDate,
  id,
}: ChangeBasketDeliveryDateParams): ApiResponse<NormalizedBasket> => {
  try {
    const { data } = await axios.put<Basket>(`baskets/${id}`, decamelizeKeys({ deliveryDate }));
    return { data: normalize(data), error: null };
  } catch (e) {
    return { data: null, error: { message: "Couldn't delivery date" } };
  }
};

export const getBasketById = async (id: string): PromiseResponse<NormalizedBasket> => {
  try {
    const { data } = await axios.get<Basket>(`baskets/${id}`);
    return normalize(data);
  } catch (e) {
    throw new Error("Couldn't retrieve basket");
  }
};

export const deleteBasket = async (id: string): PromiseResponse<boolean> => {
  try {
    await axios.delete<Basket>(`baskets/${id}`);
    return true;
  } catch (e) {
    return false;
  }
};

type UpdateBasketParams = Partial<Omit<CreateBasketParams, 'type' | 'address'>> & {
  id: string;
  validateFully?: boolean;
  address?: Partial<Omit<Address, 'id' | 'isDefault'>>;
  oneOffFeeApplicable?: boolean;
  startSubscription?: boolean;
  deliveryNote?: string;
  frequency?: number;
  deliveryDate?: string;
  deliveryDay?: string;
};

export const updateBasket = async ({
  id,
  ...rest
}: UpdateBasketParams): PromiseResponse<NormalizedBasket> => {
  const { data } = await axios.put<Baskets>(`baskets/${id}`, decamelizeKeys(rest));

  return normalize(data);
};

type PreviewOneOffBasketFeeServerResponse = ObjectToCamel<{
  total_with_fee: string;
  total_without_fee: string;
}>;

export const previewOneOffBasketFee = async (basketId: string) => {
  const { data } = await axios.post<PreviewOneOffBasketFeeServerResponse>(
    `baskets/${basketId}/preview_one_off_fee`
  );

  return data;
};

export const deleteBasketByType = async (type: 'TakeawayBasket' | 'RecipeKitBasket') => {
  await axios.delete(`baskets/delete_by_type/${type}`);
  return true;
};

export const getVoucherInfo = async (code: string) => {
  const result = await axios.get<{ data: BasketVoucher }>(`vouchers/${code}`);
  return result.data.data;
};
