<template>
  <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6342 3.82645L11.7314 8.1638L11.7508 8.72345C11.7508 9.32309 11.537 9.86276 11.2067 10.2026L10.4876 10.9221L11.1872 21.0559C11.2455 21.8154 10.6625 22.435 9.94343 22.435H9.80739C9.08832 22.435 8.50529 21.7954 8.56359 21.0559L9.26323 10.9221L8.54416 10.2026C8.19434 9.86276 8 9.3031 8 8.72345V8.1638L8.09717 3.82645C8.09717 3.64656 8.21378 3.48666 8.38869 3.44668C8.6219 3.38672 8.83567 3.56662 8.83567 3.80647C8.83567 3.80647 8.91341 7.00451 8.93285 7.92394C8.93285 8.06386 9.03002 8.1638 9.16606 8.1638C9.28267 8.1638 9.39926 8.06386 9.39926 7.92394L9.49643 3.82645C9.49643 3.64656 9.61304 3.48666 9.78795 3.44668C10.0212 3.38672 10.2349 3.56662 10.2349 3.80647C10.2349 3.80647 10.3127 7.00451 10.3321 7.92394C10.3321 8.06386 10.4293 8.1638 10.5653 8.1638C10.6819 8.1638 10.7985 8.06386 10.7985 7.92394C10.818 7.00451 10.8957 3.80647 10.8957 3.80647C10.8957 3.5866 11.1095 3.4067 11.3427 3.44668C11.5176 3.48666 11.6342 3.64656 11.6342 3.82645Z"
      fill="currentColor"
    />
    <path
      d="M16.2013 9.28312L16.9981 21.0359C17.037 21.7955 16.4734 22.4151 15.7543 22.4351H15.6183C14.8992 22.4351 14.3162 21.7954 14.3745 21.0559L14.9769 12.2413H14.666C13.7526 12.2413 13.0529 11.4018 13.189 10.4824L14.0441 4.74589C14.1413 4.02633 14.7437 3.50666 15.4434 3.50666C15.696 3.50666 15.8903 3.68654 15.9098 3.94638L16.2013 9.28312Z"
      fill="currentColor"
    />
  </svg>
</template>
