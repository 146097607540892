import { useQuery } from '@tanstack/vue-query';
import * as AuthApi from '@/api/auth';
import { queries } from '@/api/queries';

const useGetUser = () => {
  return useQuery({
    ...queries.customer.details,
    queryFn: () => AuthApi.userDetails(),
    retry: false,
  });
};

export default useGetUser;
