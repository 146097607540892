import { axios } from '@/utils/index';
import {
  PromiseResponse,
  NormalizedRestaurantMenu,
  ObjectToCamel,
  RestaurantMenuServerResponse,
  RestaurantTimeslotServerResponse,
  RestaurantTimeslots,
  TakeawayMenuProduct,
  Restaurant,
} from '@pasta-evangelists/pasta-types';
import normalize from '@/utils/normalize';
import { decamelizeKeys } from 'humps';

export const getAvailableStores = async (params: {
  lat: string;
  lon: string;
  fulfillmentType: string;
}): PromiseResponse<Restaurant[]> => {
  try {
    const { data } = await axios.get(`restaurants/nearest`, {
      params: decamelizeKeys(params),
    });
    return data.data;
  } catch (e) {
    throw new Error("Couldn't fetch stores");
  }
};

export const getTakeawayMenu = async (
  restaurantId: string | number,
  fulfillmentType?: 'delivery' | 'pickup' | 'eat_in'
): PromiseResponse<NormalizedRestaurantMenu> => {
  try {
    const { data } = await axios.get<ObjectToCamel<RestaurantMenuServerResponse>>(
      `restaurants/${restaurantId}/menu`,
      { params: { fulfillment_type: fulfillmentType } }
    );
    return normalize<NormalizedRestaurantMenu>(data);
  } catch (e) {
    throw new Error("Couldn't fetch menu");
  }
};

export const getSnoozedProducts = async (
  restaurantId: string | number,
  fulfillmentType?: 'delivery' | 'pickup' | 'eat_in'
): PromiseResponse<TakeawayMenuProduct[]> => {
  try {
    const { data } = await axios.get<ObjectToCamel<{ data: TakeawayMenuProduct[] }>>(
      `restaurants/${restaurantId}/snoozed_products`,
      { params: { fulfillment_type: fulfillmentType } }
    );
    return data.data;
  } catch (e) {
    throw new Error("Couldn't fetch products");
  }
};

interface timeslotParams {
  restaurantId: string | number;
  fulfillmentType: string;
}

export const getTimeslotsForRestaurant = async ({
  restaurantId,
  fulfillmentType,
}: timeslotParams): PromiseResponse<RestaurantTimeslots> => {
  try {
    const { data } = await axios.get<ObjectToCamel<RestaurantTimeslotServerResponse>>(
      `restaurants/${restaurantId}/time_slots?fulfillment_type=${fulfillmentType}`
    );
    return data;
  } catch (e) {
    throw new Error("Couldn't fetch timeslots");
  }
};

export const getRestaurantById = async (id: string): PromiseResponse<Restaurant> => {
  try {
    const { data } = await axios.get(`restaurants/${id}`);
    return data.data;
  } catch (e) {
    throw new Error("Couldn't fetch store");
  }
};
