import { acceptHMRUpdate, defineStore } from 'pinia';
import * as AddressApi from '@/api/address';
import { ObjectToCamel } from '@pasta-evangelists/pasta-types';
import useDeliveryStore from './deliveryStore';
import { serializeAddress } from '@/utils/address';
import useGetAddresses from '@/api/queries/pensa/useGetAddresses';
import useUpdateAddress from '@/api/mutations/pensa/updateAddress';
import { useQueryClient } from '@tanstack/vue-query';
import { useCloned } from '@vueuse/core';
import { queries } from '@/api/queries';
import useSaveAddress from '@/api/mutations/pensa/saveAddress';
import { isAxiosError } from 'axios';
import { SaveAddressParams } from '@/api/address';

export interface Address {
  id: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  country: string;
  countryCode: string;
  phone: string;
  isDefault: boolean;
  region: string;
  regionCode: string;
  company: string;
  firstName: string;
  lastName: string;
}

export const convertAddressIntoState = (
  serverAddress: ObjectToCamel<AddressApi.ServerAddressResponse>
) => {
  const { id } = serverAddress;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { extId, ...address } = serverAddress.attributes;
  return { id, ...address };
};

const useAddressStore = defineStore('addressStore', () => {
  const queryClient = useQueryClient();
  const deliveryStore = useDeliveryStore();
  const updateAddressMutation = useUpdateAddress();
  const saveAddressMutation = useSaveAddress();
  const { data, isLoading: loading, error, refetch: loadAddresses } = useGetAddresses();

  const addresses = computed(() => data.value || []);

  const defaultAddress = computed<Address | undefined>(() => {
    return addresses.value.find(address => address.isDefault);
  });

  const normalizedAddresses = computed<Record<string, Address> | null>(() => {
    if (!addresses.value.length) return null;
    return addresses.value.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {} as Record<string, Address>);
  });

  const ukAddresses = computed<Address[]>(() => {
    return addresses.value.filter(address => address.countryCode === 'GB');
  });

  const addressesByEncoding = computed<Record<string, Address> | null>(() => {
    if (!addresses.value.length) return null;
    return addresses.value.reduce((acc, curr) => {
      acc[serializeAddress(curr)] = curr;
      return acc;
    }, {} as Record<string, Address>);
  });

  const addressesEncodedWithoutExtraFields = computed<Record<string, Address> | null>(() => {
    if (!addresses.value.length) return null;
    return addresses.value.reduce((acc, curr) => {
      acc[serializeAddress(curr, ['firstName', 'lastName', 'phone'])] = curr;
      return acc;
    }, {} as Record<string, Address>);
  });

  const updateAddress = async (payload: Address) => {
    const addressIndex = addresses.value.findIndex(address => address.id === payload.id);
    if (addressIndex > -1) {
      const address = { ...addresses.value[addressIndex] };
      Object.assign(address, payload);
      try {
        await updateAddressMutation.mutateAsync(address, {
          onSuccess: result => {
            queryClient.setQueryData<Address[]>(queries.addresses.all.queryKey, addresses => {
              const { cloned: tempAddresses } = useCloned(addresses, { manual: true });
              if (tempAddresses.value) {
                tempAddresses.value[addressIndex] = convertAddressIntoState(result);
              }
              return tempAddresses.value;
            });
            queryClient.invalidateQueries(queries.addresses.all);
          },
        });
      } catch (e) {
        return;
      }
    }
  };

  const validateDeliveryStoreAddresses = () => {
    if (deliveryStore.billingAddressId) {
      const foundBillingAddress = addresses.value.find(
        address => address.id === deliveryStore.billingAddressId
      );
      if (!foundBillingAddress) {
        deliveryStore.billingAddressId = '';
      }
    }
  };

  const saveAddress = async (address: SaveAddressParams) => {
    try {
      const result = await saveAddressMutation.mutateAsync(address);
      return { data: result, error: null };
    } catch (e) {
      if (isAxiosError(e) || e instanceof Error)
        return { data: null, error: { message: e.message } };
      else return { data: null, error: { message: "Couldn't save  your billing address" } };
    }
  };

  watch(addresses, addressList => {
    if (addressList.length) {
      validateDeliveryStoreAddresses();

      if (!deliveryStore.billingAddressId && !deliveryStore.billingAddress) {
        deliveryStore.billingAddressId = defaultAddress.value?.id ?? '';
      }
    }
  });

  return {
    addresses,
    error,
    loading,
    defaultAddress,
    normalizedAddresses,
    ukAddresses,
    addressesByEncoding,
    addressesEncodedWithoutExtraFields,
    loadAddresses,
    saveAddress,
    validateDeliveryStoreAddresses,
    updateAddress,
  };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAddressStore, import.meta.hot));

export default useAddressStore;
