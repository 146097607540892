import { axios } from '@/utils/index';
import {
  ObjectToCamel,
  ApiResponse,
  PaymentMethod,
  PromiseResponse,
} from '@pasta-evangelists/pasta-types';
import { decamelizeKeys } from 'humps';

interface Intent {
  setup_intent: string;
}

type GetSetupIntentParams = {
  type?: 'CreditCard' | 'PaypalAccount';
};

export const getSetupIntent = async ({
  type = 'CreditCard',
}: GetSetupIntentParams = {}): ApiResponse<{ setupIntent: string }> => {
  try {
    const result = await axios.post<ObjectToCamel<Intent>>('payment_methods/setup', {
      type,
      payment_processor_name: 'Stripe',
    });
    return { data: result.data, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Request failed' } };
  }
};

export interface CreatePaymentMethodParams {
  type?: 'CreditCard' | 'PaypalAccount';
  paymentProcessorName?: 'Stripe';
  setupIntentId: string;
}

export const createPaymentMethod = async ({
  setupIntentId,
  paymentProcessorName = 'Stripe',
  type = 'CreditCard',
}: CreatePaymentMethodParams): PromiseResponse<PaymentMethod> => {
  const params = {
    setupIntentId,
    paymentProcessorName,
    type,
  };
  try {
    const { data } = await axios.post<ObjectToCamel<{ data: PaymentMethod }>>(
      'payment_methods',
      decamelizeKeys(params)
    );
    return data.data;
  } catch (e) {
    throw new Error("Couldn't create payment method");
  }
};

export const getPaymentMethods = async (): PromiseResponse<PaymentMethod[]> => {
  try {
    const { data } = await axios.get<ObjectToCamel<{ data: PaymentMethod[] }>>('payment_methods');
    return data.data;
  } catch (e) {
    throw new Error("Couldn't get payment methods");
  }
};

interface AuthenticateSetupResponse {
  client_secret: string;
  payment_method_id: string;
  amount: number | null;
  delivery_date: string;
  statusCode: 'card_declined' | 'card_required' | 'sca_required' | null;
}
export type AuthenticateSetup = ObjectToCamel<AuthenticateSetupResponse>;

export const deletePaymentMethod = async (id: string): PromiseResponse<true> => {
  try {
    await axios.delete(`/payment_methods/${id}`);
    return true;
  } catch (e) {
    throw new Error("Couldn't delete card");
  }
};

export const makePaymentMethodDefault = async (id: string): PromiseResponse<true> => {
  try {
    await axios.post(`/payment_methods/${id}/default`);
    return true;
  } catch (e) {
    throw new Error("Couldn't make card default");
  }
};

export const getPaymentDetails = async (paymentId: string): ApiResponse<AuthenticateSetup> => {
  try {
    const result = await axios.get<ObjectToCamel<AuthenticateSetupResponse>>(
      `payment_details/${paymentId}`
    );
    return { data: result.data, error: null };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return { data: null, error: { message: e?.response?.data?.error ?? 'something_went_wrong' } };
  }
};
