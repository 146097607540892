import { acceptHMRUpdate, defineStore } from 'pinia';
import {
  Schedule,
  Subscription,
  NormalizedSubscriptions,
  TransformedSubscription,
  ScheduledOrder,
} from '@pasta-evangelists/pasta-types';
import useSelectedSubStorage from '@/composables/useSelectedSubStorage';
import useOrdersStore from './ordersStore';
import { sortSubscriptionByState } from '@/utils';
import useGetSubscriptions from '@/api/queries/pensa/useGetSubscriptions';
import useUpdateSubscription from '@/api/mutations/pensa/updateSubscription';
import useUpdateSubscriptionSchedule from '@/api/mutations/pensa/updateSubscriptionSchedule';
import { useQueryClient } from '@tanstack/vue-query';
import { queries } from '@/api/queries';
import { useCloned } from '@vueuse/core';

export interface ScheduleParams {
  deliveryDay?: string;
  frequency?: number;
}

export interface UpdateSubParams extends ScheduleParams {
  name?: string;
  deliveryNote?: string;
  shippingAddressId?: string;
  billingAddressId?: string;
  paymentMethodId?: string;
  numberOfRecipes?: number;
  cocktails?: boolean;
  numberOfCocktails?: number;
  glutenFree?: boolean;
  doublePortion?: boolean;
  pork?: boolean;
  beef?: boolean;
  lamb?: boolean;
  game?: boolean;
  crustaceans?: boolean;
  fish?: boolean;
  nuts?: boolean;
  vegetarian?: boolean;
  vegan?: boolean;
  molluscs?: boolean;
  recipeVariety?: boolean;
}

const transformSubscription = (
  sub: Subscription,
  schedules: Record<string, Schedule>
): TransformedSubscription => {
  const subscriptionStore = useSubscriptionStore();
  const billingAddressId = sub.relationships.billingAddress.data?.id ?? null;
  const shippingAddressId = sub.relationships.shippingAddress.data?.id ?? null;
  const paymentMethodId = sub.relationships.paymentMethod.data?.id ?? null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { extId, ...attributes } = sub.attributes;

  const schedule = schedules[sub.relationships.schedule.data.id];
  const deliveryDay = schedule.attributes.deliveryDay;
  const frequency = schedule.attributes.frequency;

  let customerVoucher = null;

  if (
    sub.relationships.customerVouchers.data &&
    subscriptionStore.normalizedSubscriptions?.customerVoucher &&
    subscriptionStore.normalizedSubscriptions.voucher
  ) {
    customerVoucher = [];
    sub.relationships.customerVouchers.data.forEach(customerVoucherData => {
      const customerVoucherId = customerVoucherData.id;
      const voucherId =
        subscriptionStore.normalizedSubscriptions?.customerVoucher?.[customerVoucherId]
          .relationships.voucher.data.id;

      if (voucherId && subscriptionStore.normalizedSubscriptions?.voucher?.[voucherId]) {
        const localCustomerVoucher = {
          id: customerVoucherId,
          attributes:
            subscriptionStore.normalizedSubscriptions?.customerVoucher?.[customerVoucherId]
              .attributes,
          voucher: {
            id: voucherId,
            attributes: subscriptionStore.normalizedSubscriptions.voucher[voucherId].attributes,
          },
        };

        customerVoucher?.push(localCustomerVoucher);
      }
    });
  }

  return {
    id: sub.id,
    ...attributes,
    billingAddressId,
    shippingAddressId,
    paymentMethodId,
    deliveryDay,
    frequency,
    customerVoucher,
  };
};

const useSubscriptionStore = defineStore('subscriptionStore', () => {
  const selectedId = ref<string>('');
  const {
    data: normalizedSubscriptions,
    isLoading: loading,
    error,
    refetch: getSubscriptions,
  } = useGetSubscriptions();

  const queryClient = useQueryClient();
  const updateSubscriptionMutation = useUpdateSubscription();
  const updateSubscriptionScheduleMutation = useUpdateSubscriptionSchedule();

  const selectedSubscription = computed(() => {
    if (
      selectedId.value &&
      normalizedSubscriptions.value &&
      normalizedSubscriptions.value.subscription[selectedId.value]
    ) {
      return transformSubscription(
        normalizedSubscriptions.value.subscription[selectedId.value],
        normalizedSubscriptions.value.subscriptionSchedule
      );
    }
  });

  const selectableSubscriptions = computed(() => {
    const orderStore = useOrdersStore();
    const activeSubsRecord = normalizedSubscriptions.value?.subscription
      ? Object.values(normalizedSubscriptions.value.subscription).reduce(
          (acc: Record<string, Subscription>, curr: Subscription) => {
            if (
              curr.attributes.state &&
              (['active', 'paused', 'failed'].includes(curr.attributes.state) ||
                (curr.attributes.state === 'created' &&
                  Object.values(orderStore.ordersForSubscriptions?.[curr.id] ?? []).length > 0))
            ) {
              acc[curr.id] = curr;
            }
            return acc;
          },
          {} as Record<string, Subscription>
        )
      : {};
    return Object.keys(activeSubsRecord).length ? activeSubsRecord : null;
  });

  const cancelledSubscriptions = computed<Record<string, Subscription> | null>(() => {
    if (!normalizedSubscriptions.value) return null;
    return Object.values(normalizedSubscriptions.value.subscription)
      .filter(sub => sub.attributes.state === 'cancelled')
      .reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {} as Record<string, Subscription>);
  });

  const activeSubscriptions = computed<number>(() => {
    if (!normalizedSubscriptions.value?.subscription) return 0;
    return Object.values(normalizedSubscriptions.value.subscription).filter(
      sub => sub.attributes.state === 'active'
    ).length;
  });

  const subscriptionScheduleWeekWithLoyaltyForSelectedSub = computed<Record<string, string> | null>(
    () => {
      if (!normalizedSubscriptions.value) return null;
      const foundSchedule = Object.values(normalizedSubscriptions.value.subscriptionSchedule).find(
        schedule => schedule.relationships.subscription.data?.id === selectedId.value
      );

      if (!foundSchedule) return null;

      return foundSchedule.relationships.weeks.meta.loyalty.weekIds.reduce((acc, curr) => {
        acc[curr] = foundSchedule.relationships.weeks.meta.loyalty.voucherId;
        return acc;
      }, {} as Record<string, string>);
    }
  );

  watch(normalizedSubscriptions, subscriptions => {
    if (!subscriptions?.subscription) selectedId.value = '';
    if (subscriptions?.subscription && !selectedId.value) {
      const savedSelectedId = useSelectedSubStorage();
      const newestSub = Object.values(subscriptions.subscription).reduce(
        (acc: Subscription, curr: Subscription) => {
          if (Object.keys(acc).length) {
            if (curr.attributes.state === acc.attributes.state) {
              acc = curr.id > acc.id ? curr : acc;
            } else {
              acc = [acc, curr].sort(sortSubscriptionByState)[0];
            }
            return acc;
          }
          return curr;
        },
        {} as Subscription
      );

      selectedId.value =
        savedSelectedId.value && subscriptions.subscription[savedSelectedId.value]
          ? savedSelectedId.value
          : Object.keys(newestSub).length
          ? newestSub.id
          : '';
    }
  });

  const updateSelectedSubscription = async (params: UpdateSubParams) => {
    if (
      !selectedSubscription.value ||
      !Object.keys(params).length ||
      !normalizedSubscriptions.value
    )
      return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, state, ...rest } = selectedSubscription.value;

    const subscriptionParams = {
      ...rest,
      ...params,
    };

    try {
      await updateSubscriptionMutation.mutateAsync({
        subId: selectedId.value,
        params: subscriptionParams,
      });
    } catch (e) {
      return;
    }
  };

  const updateSubscriptionSchedule = async (params: ScheduleParams) => {
    if (
      !selectedSubscription.value ||
      !Object.keys(params).length ||
      !normalizedSubscriptions.value
    )
      return;

    const scheduleParams = {
      deliveryDay: selectedSubscription.value.deliveryDay,
      frequency: selectedSubscription.value.frequency,
      ...params,
    };
    try {
      await updateSubscriptionScheduleMutation.mutateAsync({
        subId: selectedId.value,
        params: scheduleParams,
      });
    } catch (e) {
      return;
    }
  };

  const selectSub = (id: string) => {
    if (normalizedSubscriptions.value && normalizedSubscriptions.value.subscription?.[id])
      selectedId.value = id;
  };

  const getTransformedSubscription = (id: string) => {
    return normalizedSubscriptions.value?.subscription?.[id]
      ? transformSubscription(
          normalizedSubscriptions.value.subscription[id],
          normalizedSubscriptions.value.subscriptionSchedule
        )
      : null;
  };

  const findSubscriptionsWithAddressId = (addressId: string) => {
    if (!normalizedSubscriptions.value) return [];
    return Object.values(normalizedSubscriptions.value.subscription).filter(
      sub =>
        sub.relationships.billingAddress.data?.id === addressId ||
        sub.relationships.shippingAddress.data?.id === addressId
    );
  };

  const findSubscriptionsWithPaymentMethodId = (paymentMethodId: string) => {
    if (!normalizedSubscriptions.value) return [];
    return Object.values(normalizedSubscriptions.value.subscription).filter(
      sub => sub.relationships.paymentMethod.data?.id === paymentMethodId
    );
  };

  const transformSub = (subscription: Subscription) => {
    if (!normalizedSubscriptions.value) return null;
    return transformSubscription(subscription, normalizedSubscriptions.value.subscriptionSchedule);
  };

  const updateSubscriptionPaymentMethod = ({
    paymentMethodId,
    subscriptionId,
  }: {
    paymentMethodId: string;
    subscriptionId: string;
  }) => {
    if (
      !normalizedSubscriptions.value ||
      !normalizedSubscriptions.value.subscription ||
      !normalizedSubscriptions.value.subscription[subscriptionId]
    )
      return;

    queryClient.setQueriesData<NormalizedSubscriptions>(
      queries.subscriptions.all,
      subscriptions => {
        const { cloned: tempSubscriptions } = useCloned(subscriptions, { manual: true });
        if (tempSubscriptions.value && tempSubscriptions.value.subscription[subscriptionId]) {
          if (
            tempSubscriptions.value.subscription[subscriptionId].relationships.paymentMethod.data
          ) {
            tempSubscriptions.value.subscription[
              subscriptionId
            ].relationships.paymentMethod.data!.id = paymentMethodId;
          } else {
            tempSubscriptions.value.subscription[
              subscriptionId
            ].relationships.paymentMethod.data = {
              id: paymentMethodId,
              type: 'payment_method',
            };
          }
        }
        return tempSubscriptions.value;
      }
    );
    queryClient.invalidateQueries(queries.subscriptions.all);
  };

  const updateSubscriptionAddressId = ({
    oldAddressId,
    newAddressId,
    subscriptionId,
  }: {
    oldAddressId: string;
    newAddressId: string;
    subscriptionId: string;
  }) => {
    if (
      !normalizedSubscriptions.value ||
      !normalizedSubscriptions.value.subscription ||
      !normalizedSubscriptions.value.subscription[subscriptionId]
    )
      return;

    queryClient.setQueriesData<NormalizedSubscriptions>(
      queries.subscriptions.all,
      subscriptions => {
        const { cloned: tempSubscriptions } = useCloned(subscriptions, { manual: true });
        if (tempSubscriptions.value && tempSubscriptions.value.subscription[subscriptionId]) {
          const propertiesToChange = ['shippingAddress', 'billingAddress'] as const;
          propertiesToChange.forEach(type => {
            if (tempSubscriptions.value!.subscription[subscriptionId].relationships[type].data) {
              if (
                tempSubscriptions.value!.subscription[subscriptionId].relationships[type].data!
                  .id === oldAddressId
              ) {
                tempSubscriptions.value!.subscription[subscriptionId].relationships[
                  type
                ].data!.id = newAddressId;
              }
            } else {
              tempSubscriptions.value!.subscription[subscriptionId].relationships[type].data = {
                id: newAddressId,
                type: 'address',
              };
            }
          });
        }
        return tempSubscriptions.value;
      }
    );
    queryClient.invalidateQueries(queries.subscriptions.all);
  };

  const subscriptionScheduleWeeksLoyalty = (scheduleWeek: ScheduledOrder) => {
    if (!normalizedSubscriptions.value || !normalizedSubscriptions.value.subscriptionSchedule)
      return null;
    const foundSchedule = Object.values(normalizedSubscriptions.value.subscriptionSchedule).find(
      schedule =>
        schedule.relationships.subscription.data?.id ===
        scheduleWeek.relationships.subscription.data.id
    );

    if (!foundSchedule) return null;

    return foundSchedule.relationships.weeks.meta.loyalty.weekIds.reduce((acc, curr) => {
      acc[curr] = foundSchedule.relationships.weeks.meta.loyalty.voucherId;
      return acc;
    }, {} as Record<string, string>);
  };

  return {
    normalizedSubscriptions,
    error,
    loading,
    selectedId,
    selectedSubscription,
    selectableSubscriptions,
    cancelledSubscriptions,
    activeSubscriptions,
    subscriptionScheduleWeekWithLoyaltyForSelectedSub,
    updateSelectedSubscription,
    updateSubscriptionSchedule,
    selectSub,
    getTransformedSubscription,
    findSubscriptionsWithAddressId,
    findSubscriptionsWithPaymentMethodId,
    transformSub,
    subscriptionScheduleWeeksLoyalty,
    updateSubscriptionPaymentMethod,
    updateSubscriptionAddressId,
    getSubscriptions,
  };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useSubscriptionStore, import.meta.hot));

export default useSubscriptionStore;
