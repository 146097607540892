import { useQuery } from '@tanstack/vue-query';
import * as BasketApi from '@/api/basket';
import { queries } from '@/api/queries';

const useGetBasket = (basketId: Ref<string | null | undefined>) => {
  const enabled = computed(() => !!basketId.value);
  return useQuery({
    ...queries.baskets.byId(basketId),
    queryFn: () => BasketApi.getBasketById(basketId.value!),
    enabled,
  });
};

export default useGetBasket;
