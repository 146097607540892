import { chain, setStyle } from '@/utils';
import { onBeforeUnmount, Ref, watch } from 'vue';

//TODO iOS 16 broke our scroll prevention
// for now we are going to disabled it to allow scrolling
// we will need to revisit this!!

interface Options {
  disabled?: boolean;
}

/**
 * This composable was based on the React implementation of usePreventScroll.
 * Original source: https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/overlays/src/usePreventScroll.ts
 */

const usePreventScroll = (value: Ref<boolean>, { disabled }: Options = {}) => {
  let currentScrollPosition: number;
  let scrollX: number;
  let scrollY: number;

  const resetBodyStyles = () => {
    setStyle(document.body, 'overflow', 'auto');
    setStyle(document.body, 'position', 'relative');
    setStyle(document.body, 'top', `auto`);
    setStyle(document.body, 'width', 'auto');
  };

  const applyBodyStyles = () => {
    chain(
      setStyle(document.body, 'overflow', 'hidden'),
      setStyle(document.body, 'position', 'fixed'),
      setStyle(document.body, 'top', `-${currentScrollPosition}px`),
      setStyle(document.body, 'width', '100%')
    );
  };

  watch(value, newValue => {
    if (newValue && !disabled) {
      scrollX = window.scrollX;
      scrollY = window.scrollY;

      currentScrollPosition = window.scrollY;

      applyBodyStyles();
    } else if (!disabled) {
      resetBodyStyles();

      nextTick(() => {
        window.scrollTo(scrollX, scrollY);
      });
    }
  });
  onBeforeUnmount(() => {
    if (!disabled) {
      resetBodyStyles();
      setStyle(document.body, 'top', `auto`);
      window.scrollTo(scrollX, scrollY);
    }
  });
};

export default usePreventScroll;
