import { useQuery } from '@tanstack/vue-query';
import * as OrdersApi from '@/api/orders';
import { queries } from '@/api/queries';
import currentCustomerStore from '@/stores/currentCustomerStore';

const useGetScheduleWeeks = () => {
  const customerStore = currentCustomerStore();
  const enabled = computed(() => !!customerStore.email);

  return useQuery({
    ...queries.orders.scheduleWeeks,
    queryFn: () => OrdersApi.getScheduleWeeks(),
    enabled,
  });
};

export default useGetScheduleWeeks;
