import { PiniaPluginContext } from 'pinia';

interface Options {
  key: string;
  storeToWatch: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parser: (state: any) => Object;
}

const localStoragePiniaState = (options: Options) => (context: PiniaPluginContext) => {
  if (context.store.$id === options.storeToWatch) {
    context.store.$subscribe(
      () => {
        localStorage.setItem(
          options.key,
          JSON.stringify({
            updatedAt: new Date().toISOString(),
            data: options.parser(context.store.$state),
          })
        );
      },
      { deep: true }
    );
  }
};
export default localStoragePiniaState;
