<template>
  <div v-if="currentBanner" id="marketing-banner" class="relative w-full">
    <div
      class="flex items-center justify-center w-full px-6 py-1 text-center line-clamp-2"
      :style="{
        'background-color': backgroundColor,
        color: currentBanner.attributes.fgColor || '#fff',
      }"
    >
      <a
        v-if="currentBanner.attributes.link"
        :href="currentBanner.attributes.link"
        class="hover:underline"
      >
        <span class="hidden md:inline">{{ currentBanner.attributes.text }}</span>
        <span class="md:hidden">
          {{ currentBanner.attributes.mobileText || currentBanner.attributes.text }}
        </span>
      </a>
      <p v-else>
        <span class="hidden sm:inline">
          {{ currentBanner.attributes.text }}
        </span>
        <span class="sm:hidden">
          {{ currentBanner.attributes.mobileText }}
        </span>
      </p>
    </div>
    <div v-if="hasMultipleBanners" class="absolute top-1.5 left-1.5">
      <button @click="swapToPreviousBanner">
        <ChevronLeftIcon
          class="size-5"
          :style="{
            color: currentBanner.attributes.fgColor || '#fff',
          }"
        />
      </button>
    </div>
    <div v-if="hasMultipleBanners" class="absolute top-1.5 right-1.5">
      <button @click="swapToNextBanner">
        <ChevronRightIcon
          class="size-5"
          :style="{
            color: currentBanner.attributes.fgColor || '#fff',
          }"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import currentCustomerStore from '@/stores/currentCustomerStore';
  import useMarketingBannersStore from '@/stores/marketingBannersStore';
  import { useRouter } from 'vue-router';
  import { MarketingBanner } from '@pasta-evangelists/pasta-types';
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/outline';
  import useBasketStore from '@/stores/basketStore';
  import useMarketingObservable from '@/composables/useMarketingObservable';

  const marketingBannersStore = useMarketingBannersStore();
  const customerStore = currentCustomerStore();
  const basketStore = useBasketStore();
  const router = useRouter();
  const { setIsMounted } = useMarketingObservable();

  const currentBanner = ref<MarketingBanner | null>(null);
  const swapInterval = ref();

  const isExistingCustomer = computed(() => {
    const currentRouteName = router.currentRoute.value.name;

    if (currentRouteName && typeof currentRouteName === 'string') {
      if (
        (currentRouteName.startsWith('pasta-now') || currentRouteName === 'pasta-type') &&
        currentRouteName !== 'pasta-now-search-address'
      )
        return customerStore.hasTakeawayOrders;
      if (
        currentRouteName.startsWith('checkout') ||
        currentRouteName === 'delivery-instructions' ||
        currentRouteName === 'delivery-address' ||
        currentRouteName === 'product-box'
      )
        return customerStore.hasSubscriptionOrders;
      return customerStore.hasSubscriptionOrders || customerStore.hasTakeawayOrders;
    } else return false;
  });

  const banners = computed(() => {
    const currentRouteName = router.currentRoute.value.name;
    if (currentRouteName && typeof currentRouteName === 'string') {
      if (
        (currentRouteName.startsWith('checkout') ||
          currentRouteName === 'delivery-instructions' ||
          currentRouteName === 'delivery-address') &&
        !basketStore.hasVoucher
      ) {
        return marketingBannersStore.getActiveCheckoutBanners;
      }

      if (currentRouteName === 'product-box' && !basketStore.hasVoucher) {
        return marketingBannersStore.getActiveProductBoxBanners;
      }

      if (
        [
          'my-account-dashboard',
          'upcoming-orders',
          'order-history',
          'pasta-plan',
          'details',
          'rewards',
          'order',
        ].includes(currentRouteName)
      ) {
        return marketingBannersStore.getActiveMyAccountBanners;
      }

      if (['pasta-now', 'pasta-type'].includes(currentRouteName)) {
        return marketingBannersStore.getActivePastaNowMenuBanners;
      }

      if (currentRouteName === 'pasta-now-search-address')
        return marketingBannersStore.getActiveAddressLookupBanners;

      if (currentRouteName.startsWith('pasta-now-')) {
        return marketingBannersStore.getActivePastaNowCheckoutBanners;
      }
    }
    return [];
  });

  const filteredBanners = computed(() => {
    const customerType = isExistingCustomer.value ? 'existing' : 'new';
    return banners.value.filter(banner => banner.attributes.customerType === customerType);
  });

  watch(
    filteredBanners,
    bannerList => {
      if (bannerList.length) {
        currentBanner.value = bannerList[0];
        if (bannerList.length > 1) {
          swapInterval.value = setInterval(() => {
            swapToNextBanner();
          }, 5000);
        }
      } else {
        currentBanner.value = null;
        clearInterval(swapInterval.value);
      }
    },
    { immediate: true }
  );

  const backgroundColor = computed(() => {
    return currentBanner.value?.attributes.bgColor
      ? currentBanner.value.attributes.bgColor
      : '#576458';
  });

  const hasMultipleBanners = computed(() => filteredBanners.value.length > 1);

  const resetTimer = () => {
    clearInterval(swapInterval.value);
    swapInterval.value = setInterval(() => {
      swapToNextBanner();
    }, 5000);
  };

  const swapToNextBanner = () => {
    if (filteredBanners.value.length > 1 && currentBanner.value) {
      resetTimer();
      const currentIndex = filteredBanners.value.indexOf(currentBanner.value);
      const nextIndex = currentIndex + 1 === filteredBanners.value.length ? 0 : currentIndex + 1;
      currentBanner.value = filteredBanners.value[nextIndex];
    }
  };

  const swapToPreviousBanner = () => {
    if (filteredBanners.value.length > 1 && currentBanner.value) {
      resetTimer();
      const currentIndex = filteredBanners.value.indexOf(currentBanner.value);
      const nextIndex = currentIndex - 1 > 0 ? 0 : currentIndex - 1;
      currentBanner.value = filteredBanners.value[nextIndex];
    }
  };

  watch(
    currentBanner,
    newValue => {
      if (newValue) {
        setIsMounted(true);
      }
    },
    { immediate: true }
  );

  onUnmounted(() => {
    clearInterval(swapInterval.value);
    setIsMounted(false);
  });
</script>
