import {
  CreateOneOffOrderServerResponse,
  CreateSubscriptionResponse,
  NormalizedSubscriptions,
  PaymentServerResponse,
} from '@pasta-evangelists/pasta-types';
import { axios } from '@/utils';
import normalize from '@/utils/normalize';
import { ApiResponse, ObjectToCamel } from '@pasta-evangelists/pasta-types';
import { decamelizeKeys } from 'humps';
import * as Sentry from '@sentry/vue';

export interface CreateSubscriptionParams {
  deliveryNote: string;
  shippingAddressId: string;
  billingAddressId: string;
  scheduleAttributes: {
    frequency: number;
    firstDelivery: string;
    deliveryDay: string;
  };
}

export const createSubscription = async (
  payload: CreateSubscriptionParams
): ApiResponse<NormalizedSubscriptions> => {
  try {
    const result = await axios.post<ObjectToCamel<CreateSubscriptionResponse>>(
      '/subscriptions',
      decamelizeKeys(payload)
    );
    return { data: normalize<NormalizedSubscriptions>(result.data), error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong!' } };
  }
};

interface ServerCreateOrderResponse {
  payment_intent_client_secret: string | null;
  order_ids: string[];
  payment: PaymentServerResponse;
}

interface CreateOrderPayload {
  subscriptionId: string;
  basketId: string;
  paymentMethodId?: string;
}

export const createOrder = async (
  payload: CreateOrderPayload
): ApiResponse<ServerCreateOrderResponse> => {
  try {
    const result = await axios.post<ObjectToCamel<ServerCreateOrderResponse>>(
      '/orders/subscription',
      decamelizeKeys(payload)
    );
    return { data: result.data, error: null };
  } catch (e) {
    Sentry.withScope(scope => {
      scope.setExtra('payload', payload);
      scope.setExtra('Axios error', e);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scope.setExtra('Server Response', (e as any)?.response?.data);
      Sentry.captureException(new Error('Order Failed'));
    });
    return { data: null, error: { message: 'Something went wrong!' } };
  }
};

interface CreateOneOffOrderPayload {
  basketId: string;
  deliveryDate: string;
  shippingAddressId: string;
  billingAddressId: string;
  paymentMethodId?: string;
  paymentMethodType?: string;
  paymentProcessor?: string;
  deliveryNote?: string;
}

export const createOneOffOrder = async (
  payload: CreateOneOffOrderPayload
): ApiResponse<CreateOneOffOrderServerResponse> => {
  try {
    const result = await axios.post<ObjectToCamel<CreateOneOffOrderServerResponse>>(
      '/orders/one_off',
      decamelizeKeys(payload)
    );
    return { data: result.data, error: null };
  } catch (e) {
    Sentry.withScope(scope => {
      scope.setExtra('payload', payload);
      scope.setExtra('Axios error', e);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scope.setExtra('Server Response', (e as any)?.response?.data);
      Sentry.captureException(new Error('Order Failed'));
    });
    return { data: null, error: { message: 'Something went wrong!' } };
  }
};

interface CreateOrderForBasketParams {
  basketId: string;
  billingAddressId?: string;
  paymentMethodId?: string;
  paymentMethodType?: string;
  paymentProcessorName?: string;
}

export const createOrderForBasket = async (
  params: CreateOrderForBasketParams
): ApiResponse<CreateOneOffOrderServerResponse> => {
  try {
    const result = await axios.post('orders/recipe_kits', decamelizeKeys(params));
    return { data: result.data, error: null };
  } catch (e) {
    Sentry.withScope(scope => {
      scope.setExtra('payload', params);
      scope.setExtra('Axios error', e);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scope.setExtra('Server Response', (e as any)?.response?.data);
      Sentry.captureException(new Error('Order Failed'));
    });
    return { data: null, error: { message: 'Something went wrong!' } };
  }
};
