import { useQuery } from '@tanstack/vue-query';
import * as PaymentApi from '@/api/payment';
import { queries } from '@/api/queries';
import currentCustomerStore from '@/stores/currentCustomerStore';

const useGetPaymentMethods = () => {
  const customerStore = currentCustomerStore();
  const enabled = computed(() => !!customerStore.email);

  return useQuery({
    ...queries.payments.all,
    queryFn: () => PaymentApi.getPaymentMethods(),
    enabled,
  });
};

export default useGetPaymentMethods;
