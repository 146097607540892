import {
  Subscription,
  SubscriptionListServerResponse,
  SubscriptionServerResponse,
  SubscriptionStateServerResponse,
  UpdateSchedule,
  UpdateScheduleResponse,
  NormalizedSubscriptions,
  IngredientListResponse,
  Ingredient,
  PromiseResponse,
} from '@pasta-evangelists/pasta-types';
import { axios } from '@/utils/index';
import { ObjectToCamel, ApiResponse } from '@pasta-evangelists/pasta-types';
import { Camelized, decamelizeKeys } from 'humps';
import normalize from '@/utils/normalize';

export interface SubscriptionParams {
  name: string | null;
  deliveryNote: string;
  shippingAddressId: string | null;
  billingAddressId: string | null;
  paymentMethodId: string | null;
  numberOfRecipes: number | null;
  cocktails: boolean | null;
  numberOfCocktails: number;
  glutenFree: boolean;
  doublePortion: boolean | null;
  pork: boolean;
  beef: boolean;
  lamb: boolean;
  game: boolean;
  crustaceans: boolean;
  fish: boolean;
  nuts: boolean;
  vegetarian: boolean;
  vegan: boolean;
  molluscs: boolean;
  recipeVariety: boolean;
}

interface ScheduleParams {
  deliveryDay: string;
  frequency: number;
}

export interface PauseReason {
  pauseReason: string;
  pauseNote: string;
}

export const getSubscriptions = async (): PromiseResponse<NormalizedSubscriptions> => {
  try {
    const { data } = await axios.get<ObjectToCamel<SubscriptionListServerResponse>>(
      'subscriptions'
    );
    return normalize<NormalizedSubscriptions>(data);
  } catch (e) {
    throw new Error('Unable to retrieve subscriptions');
  }
};

interface updateSubscriptionParams {
  subId: string;
  params: SubscriptionParams;
}

export const updateSubscription = async ({
  subId,
  params,
}: updateSubscriptionParams): PromiseResponse<Subscription> => {
  try {
    const { data } = await axios.put<ObjectToCamel<SubscriptionServerResponse>>(
      `subscriptions/${subId}`,
      decamelizeKeys(params)
    );
    return data.data;
  } catch (e) {
    throw new Error('Unable to update subscription');
  }
};

interface updateScheduleParams {
  subId: string;
  params: ScheduleParams;
}

export const updateSubscriptionSchedule = async ({
  subId,
  params,
}: updateScheduleParams): PromiseResponse<UpdateSchedule> => {
  try {
    const { data } = await axios.put<ObjectToCamel<UpdateScheduleResponse>>(
      `subscriptions/${subId}/schedule`,
      decamelizeKeys(params)
    );
    return data;
  } catch (e) {
    throw new Error('Unable to update subscription schedule');
  }
};

interface PauseSubscriptionParams {
  subId: string;
  params: PauseReason;
}

export const pauseSubscription = async ({
  subId,
  params,
}: PauseSubscriptionParams): PromiseResponse<NormalizedSubscriptions> => {
  try {
    const { data } = await axios.post<ObjectToCamel<SubscriptionStateServerResponse>>(
      `subscriptions/${subId}/pause`,
      decamelizeKeys(params)
    );
    return normalize<NormalizedSubscriptions>(data);
  } catch (e) {
    throw new Error('Unable to pause subscription');
  }
};

export const activateSubscription = async (
  subId: string
): PromiseResponse<NormalizedSubscriptions> => {
  try {
    const { data } = await axios.post<ObjectToCamel<SubscriptionStateServerResponse>>(
      `subscriptions/${subId}/activate`
    );
    return normalize<NormalizedSubscriptions>(data);
  } catch (e) {
    throw new Error('Unable to activate subscription');
  }
};

export const cancelSubscription = async (
  subId: string,
  reason: string
): ApiResponse<NormalizedSubscriptions> => {
  const params = {
    cancellation_reason: reason,
  };
  try {
    const { data } = await axios.post<ObjectToCamel<SubscriptionStateServerResponse>>(
      `subscriptions/${subId}/cancel`,
      params
    );
    return { data: normalize<NormalizedSubscriptions>(data), error: null };
  } catch (e) {
    return { data: null, error: { message: 'Unable to cancel subscription' } };
  }
};

interface ChangeCardForSubscriptionsParams {
  newPaymentMethodId: string;
  oldPaymentMethodId?: string;
}

export const changeCardForSubscriptions = async (
  params: ChangeCardForSubscriptionsParams
): PromiseResponse<true> => {
  try {
    await axios.post('subscriptions/change_payment_method', decamelizeKeys(params));
    return true;
  } catch (e) {
    throw new Error('Unable to change card for subscriptions');
  }
};

export const getIngredients = async (): ApiResponse<Ingredient[]> => {
  try {
    const result = await axios.get<Camelized<IngredientListResponse>>('/ingredients');
    return { data: result.data.data, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Unable to retrieve ingredients' } };
  }
};

export const getIngredientsForSubscription = async (
  id: string
): ApiResponse<Record<string, boolean>> => {
  try {
    const result = await axios.get(`/subscriptions/${id}/ingredient_preferences`);
    return { data: decamelizeKeys(result.data), error: null };
  } catch (e) {
    return { data: null, error: { message: 'Unable to retrieve ingredients' } };
  }
};

interface UpgradeIngredientsParams {
  subscriptionId: string;
  ingredients: Record<string, boolean>;
}

export const updateIngredientsForSubscription = async (
  params: UpgradeIngredientsParams
): ApiResponse<Record<string, boolean>> => {
  try {
    const result = await axios.post(
      `/subscriptions/${params.subscriptionId}/ingredient_preferences`,
      decamelizeKeys(params.ingredients)
    );
    return { data: decamelizeKeys(result.data), error: null };
  } catch (e) {
    return { data: null, error: { message: 'Unable to update ingredients' } };
  }
};
