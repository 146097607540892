import { Address } from '@/stores/addressStore';
import { axios } from '@/utils';
import { ObjectToCamel, PromiseResponse } from '@pasta-evangelists/pasta-types';
import { decamelizeKeys } from 'humps';

export interface SaveAddressParams {
  address1: string;
  address2: string;
  city: string;
  zip: string;
  phone: string;
  region: string;
  regionCode: string;
  company: string;
  firstName: string;
  lastName: string;
  country: string;
  countryCode?: string;
}

export interface ServerAddressResponse {
  id: string;
  type: 'address';
  attributes: {
    address1: string;
    address2: string;
    city: string;
    zip: string;
    country: string;
    country_code: string;
    phone: string;
    first_name: string;
    last_name: string;
    is_default: boolean;
    region: string;
    region_code: string;
    company: string;
    ext_id: number;
    lat: string | null;
    lon: string | null;
  };
  relationships: {
    customer: {
      data: {
        id: string;
        type: 'customer';
      };
    };
  };
}

export interface ServerAddressDataResponse {
  data: ServerAddressResponse;
}

export const saveAddress = async (
  address: SaveAddressParams
): PromiseResponse<ServerAddressResponse> => {
  try {
    const { data } = await axios.post<ObjectToCamel<ServerAddressDataResponse>>(
      '/addresses',
      decamelizeKeys(address)
    );
    return data.data;
  } catch (e) {
    throw new Error('Something went wrong!');
  }
};

export const updateAddress = async (address: Address): PromiseResponse<ServerAddressResponse> => {
  try {
    const { data } = await axios.put<ObjectToCamel<ServerAddressDataResponse>>(
      `/addresses/${address.id}`,
      decamelizeKeys({ ...address, default: address.isDefault })
    );
    return data.data;
  } catch (e) {
    throw new Error('Something went wrong!');
  }
};

interface GetAddressesResponse {
  data: ServerAddressResponse[];
}

export const retriveAddress = async (): PromiseResponse<ServerAddressResponse[]> => {
  try {
    const { data } = await axios.get<ObjectToCamel<GetAddressesResponse>>('/addresses');
    return data.data;
  } catch (e) {
    throw new Error("Couldn't load addresses list");
  }
};

export const deleteAddress = async (addressId: string): PromiseResponse<true> => {
  try {
    await axios.delete<ObjectToCamel<ServerAddressDataResponse>>(`/addresses/${addressId}`);
    return true;
  } catch (e) {
    throw new Error('Something went wrong');
  }
};

export const setAddressAsDefault = async (addressId: string): PromiseResponse<true> => {
  try {
    await axios.post<ObjectToCamel<ServerAddressDataResponse>>(`/addresses/${addressId}/default`);
    return true;
  } catch (e) {
    throw new Error('Something went wrong');
  }
};
