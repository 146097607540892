import { SELECTED_SUB_STORE_KEY } from '@/constants/utils';
import useSubscriptionStore from '@/stores/subscriptionStore';

const useSelectedSubStorage = () => {
  const subscriptionStore = useSubscriptionStore();
  const savedSelectedId = ref(localStorage.getItem(SELECTED_SUB_STORE_KEY));

  watch(
    () => subscriptionStore.selectedId,
    selectedId => {
      if (selectedId && savedSelectedId.value !== selectedId) {
        localStorage.setItem(SELECTED_SUB_STORE_KEY, selectedId);
        savedSelectedId.value = selectedId;
      }
    },
    { immediate: true }
  );

  return savedSelectedId;
};

export default useSelectedSubStorage;
