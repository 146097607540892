import {
  NormalizedRestaurantMenu,
  notEmpty,
  TakeawayMenuProduct,
} from '@pasta-evangelists/pasta-types';

interface MenuParams {
  menu: Ref<NormalizedRestaurantMenu | null | undefined>;
  snoozedProducts: Ref<TakeawayMenuProduct[] | null | undefined>;
}

const useMenuHelper = ({ snoozedProducts, menu }: MenuParams) => {
  const snoozedProductIds = computed(() => {
    if (!snoozedProducts.value || !snoozedProducts.value.length) return [];
    return snoozedProducts.value.map(product => product.id);
  });

  const getSubProductsForProduct = (productId: string) => {
    const product = menu.value?.takeawayMenuProduct[productId];
    if (product && product.attributes.subProducts.length) {
      const subProducts = [...product.attributes.subProducts];
      return subProducts
        .sort((a, b) => a.position - b.position)
        .map(subProduct =>
          Object.values(menu.value!.takeawayMenuProduct).find(
            product => product.relationships.product.data.id === subProduct.productId
          )
        )
        .filter(notEmpty);
    }
    return [];
  };

  const getTakeawayProductForProductId = (productId: string) => {
    if (!menu.value) return null;

    return (
      Object.values(menu.value.takeawayMenuProduct).find(
        takeawayProduct => takeawayProduct.relationships.product.data.id === productId
      ) || null
    );
  };

  return { snoozedProductIds, getSubProductsForProduct, getTakeawayProductForProductId };
};

export default useMenuHelper;
