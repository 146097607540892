const message = ref('');

const useFlashMessage = ({ timer = 3000 } = {}) => {
  const timeout = ref<ReturnType<typeof setTimeout> | null>(null);

  const showMessage = (value: string) => {
    message.value = value;
    timeout.value = setTimeout(() => (message.value = ''), timer);
  };

  const close = () => {
    if (timeout.value) {
      clearTimeout(timeout.value);
    }
    message.value = '';
  };

  onBeforeUnmount(() => {
    if (timeout.value) {
      clearTimeout(timeout.value);
    }
  });

  return {
    message: readonly(message),
    showMessage,
    close,
  };
};

export default useFlashMessage;
