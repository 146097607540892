import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import 'vue3-carousel/dist/carousel.css';
import './index.css';
import router from './router';
import { setupWorker } from 'msw/browser';
import handlers from '../server/handlers';
import { trackNavigationEvent, trackCheckoutPages } from './utils/analytics';
import * as Sentry from '@sentry/vue';
import { createGtm } from '@gtm-support/vue-gtm';
import fingerprintjs from './plugins/fingerprintjs';
import localStoragePiniaState from './plugins/localStorageState';
import { DELIVERY_LOCAL_STORE_KEY, SENTRY_ERRORS_TO_IGNORE } from './constants/utils';
import { serializeToLocalStorage } from './stores/deliveryStore';
import facebookSdk from './plugins/facebookSdk';
import googleAuth from './plugins/googleAuth';
import appleLogin from './plugins/appleLogin';
import stripejs from './plugins/stripejs';
import { VueQueryPlugin, VueQueryPluginOptions } from '@tanstack/vue-query';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { install } from 'resize-observer';
import { isAxiosError } from 'axios';
import dixajs from './plugins/dixa';

if (process.env.NODE_ENV === 'development') {
  const worker = setupWorker(...handlers);

  worker.start({ onUnhandledRequest: 'bypass' });
}

const app = createApp(App);

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tracePropagationTargets: [
      'localhost',
      'pensa-qa.pastaevangelists.com',
      'pensa.pastaevangelists.com',
    ],
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration({
        blockAllMedia: false,
        maskAllText: false,
        maskAllInputs: false,
        networkCaptureBodies: true,
        networkDetailAllowUrls: [/(?:.*\.)?pastaevangelists\.com/],
      }),
    ],
    ignoreErrors: SENTRY_ERRORS_TO_IGNORE,
    tracesSampleRate: 0.2,
    normalizeDepth: 11,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const pinia = createPinia();

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
          if (isAxiosError(error) && error.response?.status === 429) {
            return false; // Don't retry if the response code is 429
          }

          return failureCount < 3;
        },
      },
    },
  },
};
app.use(VueQueryPlugin, vueQueryPluginOptions);

pinia.use(
  localStoragePiniaState({
    key: DELIVERY_LOCAL_STORE_KEY,
    parser: serializeToLocalStorage,
    storeToWatch: 'deliveryStore',
  })
);

app.use(pinia);
app.use(router);
app.use(stripejs, {
  pk: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
});
app.use(
  createGtm({
    id: 'GTM-W3DQ5CH',
    queryParams: {
      gtm_auth: import.meta.env.VITE_GTM_AUTH,
      gtm_preview: import.meta.env.VITE_GTM_PREVIEW,
      gtm_cookies_win: 'x',
    },
    enabled: !import.meta.env.DEV,
    loadScript: !import.meta.env.DEV,
    vueRouter: router,
  })
);

app.use(appleLogin, { clientId: import.meta.env.VITE_APPLE_CLIENT_ID });
app.use(fingerprintjs, {
  token: import.meta.env.VITE_FINGERPRINT_JS_TOKEN,
  endpoint: 'https://metrics.pastaevangelists.com',
  disabled: import.meta.env.DEV,
});

app.use(facebookSdk, {
  appId: import.meta.env.VITE_FACEBOOK_APP_ID,
});

app.use(googleAuth);

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBTT948P3ZKCwWmsstu2Al2mLbiWTL26lY',
  },
});

app.use(dixajs, {
  key: import.meta.env.VITE_DIXA_API_ID,
  hideOnLoad: true,
});

trackNavigationEvent(router);
trackCheckoutPages(router);

if (!window.ResizeObserver) install();

/**
  When we do a release customers that were using the website
  will have a old version of the routes, causing it to 404 if
  the route was never fetched.
  To fix this we need to force the users to reload the page
  and download the new assets.
 */
router.onError(err => {
  if (
    err instanceof TypeError &&
    err?.message?.startsWith('Failed to fetch dynamically imported module')
  ) {
    Sentry.captureException(new Error('Route not found'));
    Promise.resolve(err);
    // Fully reload page so we can fetch the new routes
    location.reload();
  }
});

app.mount('#app');
