import { useQuery } from '@tanstack/vue-query';
import * as RestaurantApi from '@/api/restaurants';
import { queries } from '@/api/queries';

const useGetRestaurant = (restaurantId: Ref<string | null>) => {
  const enabled = computed(() => !!restaurantId.value);
  return useQuery({
    ...queries.takeaway.restaurant(restaurantId),
    queryFn: () => RestaurantApi.getRestaurantById(restaurantId.value!),
    enabled,
  });
};

export default useGetRestaurant;
