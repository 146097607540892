<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" :is-open="isOpen" :initial-focus="initialFocus" @close="handleClose">
      <div class="fixed inset-0 z-50">
        <div class="min-h-dvh text-center bg-sienna-muted/70 backdrop-blur-[6px]">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block align-bottom h-dvh sm:align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95 translate-y-full"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95 translate-y-full"
          >
            <div :class="modalClasses">
              <div
                ref="headerRef"
                class="flex"
                :class="{
                  'border-b border-b-dark-linen pb-4 pt-6': hasTitleDivider,
                }"
              >
                <div
                  class="flex"
                  :class="{
                    'px-6': hasTitleDivider,
                  }"
                >
                  <button
                    v-if="hasGoBack"
                    class="p-1 mr-2 rounded-full bg-linen group hover:bg-light-linen w-fit"
                    @click="emit('goBack')"
                  >
                    <ChevronLeftIcon
                      class="size-6 text-burnt-sienna group-hover:text-terracota-dark"
                    />
                  </button>
                  <DialogTitle v-if="title" as="h3" class="text-2xl font-brand text-terracota-dark">
                    {{ title }}
                  </DialogTitle>
                </div>
                <slot name="closeBtn">
                  <div
                    v-if="!hideCloseButton"
                    class="absolute z-40 flex items-center justify-end flex-1 px-2 py-1 top-2 right-3"
                    :class="{ 'top-5 right-4': hasTitleDivider }"
                  >
                    <button
                      cy-data="modal-close-btn"
                      class="flex items-center p-1 rounded-full bg-linen group hover:bg-light-linen"
                      @click="handleClose"
                    >
                      <XIcon class="size-6 text-burnt-sienna group-hover:text-terracota-dark" />
                    </button>
                  </div>
                </slot>
              </div>
              <div ref="contentRef" :class="{ 'mt-4': !noMarginTop }">
                <slot />
              </div>
            </div>
          </TransitionChild>
          <slot name="footer" />
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
  export default {
    inheritAttrs: false,
  };
</script>

<script setup lang="ts">
  import usePreventScroll from '@/composables/usePreventScroll';
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  } from '@headlessui/vue';
  import { XIcon, ChevronLeftIcon } from '@heroicons/vue/solid';
  import { twMerge } from 'tailwind-merge';
  import useNavigationCloseModal from '@/composables/useNavigationCloseModal';
  import { trackCustomEvent } from '@/utils/analytics';

  interface Props {
    isOpen: boolean;
    title?: string;
    modalName?: string;
    hideCloseButton?: boolean;
    disableCloseOnBackBtn?: boolean;
    noMarginTop?: boolean;
    hasGoBack?: boolean;
    hasTitleDivider?: boolean;
    initialFocus?: HTMLElement | null | undefined;
  }

  interface Emits {
    (e: 'update:isOpen', value: boolean): void;
    (e: 'goBack'): void;
  }

  const props = defineProps<Props>();
  const emit = defineEmits<Emits>();
  const attrs = useAttrs();

  const headerRef = ref<HTMLHtmlElement>();
  const contentRef = ref<HTMLHtmlElement>();

  const isContentBiggerThenScreen = computed(() => {
    const contentHeight = contentRef.value?.clientHeight ?? 0;
    const headerHeight = headerRef.value?.clientHeight ?? 0;
    const screenHeight = window.innerHeight;
    return contentHeight + headerHeight > screenHeight - 65;
  });

  const modalClasses = computed(() =>
    twMerge(
      'fixed bottom-0 left-0 right-0 inline-block w-full pb-6 max-w-md overflow-y-auto text-left align-middle transition-all transform bg-light-linen shadow-xl rounded-sm sm:static sm:bottom-auto sm:max-h-[90vh]',
      props.hasTitleDivider ? '' : 'p-6',
      attrs?.class as string,
      isContentBiggerThenScreen.value ? 'top-4' : ''
    )
  );

  const isOpen = computed(() => props.isOpen);

  const handleClose = () => {
    if (props.hideCloseButton) {
      return;
    }
    emit('update:isOpen', false);
  };

  useNavigationCloseModal({
    isOpen,
    handleClose,
    isDisabled: props.disableCloseOnBackBtn ?? false,
  });

  usePreventScroll(isOpen);

  watch(props, value => {
    if (value.isOpen)
      trackCustomEvent('modal_shown', { modal_shown: `${props.modalName || props.title}` });
  });
</script>
