import { useQuery } from '@tanstack/vue-query';
import * as AddressApi from '@/api/address';
import { queries } from '@/api/queries';
import { Address, ObjectToCamel } from '@pasta-evangelists/pasta-types';
import { convertAddressIntoState } from '@/stores/addressStore';
import currentCustomerStore from '@/stores/currentCustomerStore';

const convertAddressServerResponseIntoState = (
  response: ObjectToCamel<AddressApi.ServerAddressResponse>[]
): Address[] =>
  response.reduce((acc, curr) => {
    acc.push(convertAddressIntoState(curr));
    return acc;
  }, [] as Address[]);

const loadAddresses = async () => {
  try {
    const result = await AddressApi.retriveAddress();

    const validAddresses = result.filter(
      address =>
        address.attributes.address1 ||
        address.attributes.address2 ||
        address.attributes.phone ||
        address.attributes.city ||
        address.attributes.zip
    );

    return convertAddressServerResponseIntoState(validAddresses);
  } catch (e) {
    throw new Error("Couldn't load addresses list");
  }
};

const useGetAddresses = () => {
  const customerStore = currentCustomerStore();
  const enabled = computed(() => !!customerStore.email);

  return useQuery({
    ...queries.addresses.all,
    queryFn: () => loadAddresses(),
    enabled,
  });
};

export default useGetAddresses;
