import { BasketItem } from '@pasta-evangelists/pasta-types';
import { Product } from '@pasta-evangelists/pasta-types';
import { StripeError } from '@stripe/stripe-js';

interface FormatToStringParams {
  price: number | string;
  hideSymbol?: boolean;
  removeDecimal?: boolean;
}
export const formatToString = ({ price, hideSymbol, removeDecimal }: FormatToStringParams) => {
  const number = parseFloat(price.toString());

  // removes decimal in case it ends in zero, ex: 10.00 -> 10
  if (removeDecimal) {
    return `${hideSymbol ? '' : '£'}${number.toFixed(number % 1 !== 0 ? 2 : 0)}`;
  }

  return `${hideSymbol ? '' : '£'}${number.toFixed(2)}`;
};

export const getPriceInPounds = (item: BasketItem) => {
  if (item.attributes.isAddon) {
    return 'Free';
  }
  const price =
    parseFloat(item.attributes.price) * item.attributes.quantity -
    parseFloat(item.attributes.discount || '0');

  return `£${Math.max(price, 0).toFixed(2)}`;
};

export const getSavingBetweenVariants = (product: Product) => {
  const foundSinglePortionVariant = product.variants.find(variant => !variant.isDouble);
  const singlePortionPriceInCents = foundSinglePortionVariant
    ? parseFloat(foundSinglePortionVariant.price) * 100
    : undefined;

  const foundDoublePortionVariant = product.variants.find(variant => variant.isDouble);

  const doublePortionPriceInCents = foundDoublePortionVariant
    ? parseFloat(foundDoublePortionVariant.price) * 100
    : undefined;

  if (!singlePortionPriceInCents || !doublePortionPriceInCents) {
    return '';
  }

  return Math.round(100 - ((doublePortionPriceInCents / 2) * 100) / singlePortionPriceInCents);
};

export const isPaymentIntentForFutureUse = (paymentIntent: string) => {
  return paymentIntent.startsWith('seti_');
};

export const isFraudulentStripeError = (error: StripeError) => {
  const fraudErrorCodes = ['stolen_card', 'lost_card', 'fraudulent', 'merchant_blacklist'];
  return error.decline_code && fraudErrorCodes.includes(error.decline_code);
};
