import * as DeliveryApi from '@/api/delivery';
import { queries } from '@/api/queries';
import { useQuery } from '@tanstack/vue-query';

type UseGetDeliveryDatesParams = Ref<{
  beginDate: string;
  postcode?: string;
  endDate?: string;
  productVariantIds?: number[];
}>;

const useGetDeliveryDates = (params: UseGetDeliveryDatesParams) => {
  return useQuery({
    ...queries.deliveryDates.byParams(params),
    queryFn: () =>
      DeliveryApi.getDeliveryDates({
        postcode: params.value.postcode,
        beginDate: params.value.beginDate,
        endDate: params.value.endDate,
        productVariantIds: params.value.productVariantIds,
      }),
  });
};

export default useGetDeliveryDates;
