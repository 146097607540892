import { App } from 'vue';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { FINGERPRINT_PLUGIN_LOAD, FINGERPRINT_PLUGIN_STATE } from '@/constants/injections';
// eslint-disable-next-line vue/prefer-import-from-vue
import { NOOP } from '@vue/shared';

interface Options {
  token: string;
  endpoint: string;
  disabled?: boolean;
}

interface Result {
  confidence: number;
  visitorId: string;
  visitorFound: boolean;
}

export interface FingerprintPluginState {
  isLoading: boolean;
  isEnabled: boolean;
  result: Result | null;
}

const state = reactive<FingerprintPluginState>({
  isLoading: false,
  isEnabled: true,
  result: null,
});

export const useFingerPrint = () => {
  const load = inject(FINGERPRINT_PLUGIN_LOAD, NOOP);
  return { ...toRefs(state), getFingerprintData: load };
};

export default {
  install: async (app: App, options: Options) => {
    if (options.disabled) return;

    state.isLoading = true;
    app.provide(FINGERPRINT_PLUGIN_STATE, state);
    const { token, endpoint } = options;
    const fingerPrintInstance = FingerprintJS.load({ region: 'eu', token, endpoint });

    const loadInfo = () => {
      fingerPrintInstance
        .then(fp => fp.get())
        .then(result => {
          state.result = {
            confidence: result.confidence.score,
            visitorFound: result.visitorFound,
            visitorId: result.visitorId,
          };
          state.isLoading = false;
        });
    };

    app.provide(FINGERPRINT_PLUGIN_LOAD, loadInfo);
  },
};
