import { NEW_CUSTOMER_CUSTOM_DIMENSION_KEY } from '@/constants/utils';
import useCurrentCustomerStore from '@/stores/currentCustomerStore';
import { useStorage, StorageSerializers } from '@vueuse/core';
import dayjs from 'dayjs';

interface NewCustomerObject {
  isNewCustomer: 'Unverified' | 'New' | 'Existing';
  timestamp: string;
}

const useNewCustomerTrigger = () => {
  const customerStore = useCurrentCustomerStore();
  const newCustomerObject = useStorage<null | NewCustomerObject>(
    NEW_CUSTOMER_CUSTOM_DIMENSION_KEY,
    null,
    undefined,
    { serializer: StorageSerializers.object }
  );

  const isOlderThen2Hours = computed(
    () =>
      newCustomerObject.value &&
      dayjs(newCustomerObject.value.timestamp).isBefore(dayjs().subtract(2, 'hour'))
  );

  watch(
    () => customerStore.email,
    email => {
      const isNewCustomer = email ? (customerStore.newCustomer ? 'New' : 'Existing') : 'Unverified';

      if (!newCustomerObject.value) {
        newCustomerObject.value = { isNewCustomer, timestamp: new Date().toISOString() };
        return;
      }

      if (newCustomerObject.value.isNewCustomer === 'Existing') {
        return;
      }

      if (
        newCustomerObject.value.isNewCustomer === 'Unverified' &&
        isNewCustomer !== 'Unverified'
      ) {
        newCustomerObject.value = { isNewCustomer, timestamp: new Date().toISOString() };
        return;
      }

      if (
        newCustomerObject.value.isNewCustomer === 'New' &&
        isNewCustomer === 'Existing' &&
        isOlderThen2Hours.value
      ) {
        newCustomerObject.value = { isNewCustomer, timestamp: new Date().toISOString() };
        return;
      }
    },
    { immediate: true }
  );
};

export default useNewCustomerTrigger;
