import { useMutation, useQueryClient } from '@tanstack/vue-query';
import * as PaymentApi from '@/api/payment';
import { MutationConfig } from '@/types';
import { queries } from '@/api/queries';
import { PaymentMethod, Subscription } from '@pasta-evangelists/pasta-types';
import { useCloned } from '@vueuse/core';
import useSubscriptionStore from '@/stores/subscriptionStore';
import usePaymentStore from '@/stores/paymentStore';

type SkipOptions = {
  config?: MutationConfig<typeof PaymentApi.deletePaymentMethod>;
};

const useDeletePaymentMethod = ({ config }: SkipOptions = {}) => {
  const queryClient = useQueryClient();
  const subscriptionStore = useSubscriptionStore();
  const paymentStore = usePaymentStore();
  return useMutation({
    ...config,
    mutationFn: PaymentApi.deletePaymentMethod,
    onMutate: id => {
      const paymentMethods =
        queryClient.getQueryData<PaymentMethod[]>(queries.payments.all.queryKey) || [];
      const foundCardAtIndex = paymentMethods.findIndex(paymentMethod => paymentMethod.id === id);

      const card = paymentStore.getPaymentMethodCopyFromId(id);
      const subscriptionsThatUseCard = subscriptionStore.findSubscriptionsWithPaymentMethodId(id);

      queryClient.setQueryData<PaymentMethod[]>(queries.payments.all.queryKey, paymentMethods => {
        const { cloned: tempPayments } = useCloned(paymentMethods, { manual: true });
        if (tempPayments.value)
          tempPayments.value = tempPayments.value.filter(paymentMethod => paymentMethod.id !== id);
        return tempPayments.value;
      });

      if (subscriptionsThatUseCard.length > 0 && paymentStore.defaultMethod) {
        subscriptionsThatUseCard.forEach(subscription => {
          subscriptionStore.updateSubscriptionPaymentMethod({
            paymentMethodId: paymentStore.defaultMethod!.id,
            subscriptionId: subscription.id,
          });
        });
      }

      return { card, foundCardAtIndex, subscriptionsThatUseCard };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (_, __, { card, foundCardAtIndex, subscriptionsThatUseCard }: any) => {
      if (card) {
        queryClient.setQueryData<PaymentMethod[]>(queries.payments.all.queryKey, paymentMethods => {
          const { cloned: tempPayments } = useCloned(paymentMethods, { manual: true });
          if (tempPayments.value) tempPayments.value.splice(foundCardAtIndex, 0, card);
          return tempPayments.value;
        });
      }

      if (subscriptionsThatUseCard.length > 0 && paymentStore.defaultMethod) {
        (subscriptionsThatUseCard as Subscription[]).forEach(subscription => {
          subscriptionStore.updateSubscriptionPaymentMethod({
            paymentMethodId: paymentStore.defaultMethod!.id,
            subscriptionId: subscription.id,
          });
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(queries.payments.all);
      queryClient.invalidateQueries(queries.subscriptions.all);
    },
  });
};

export default useDeletePaymentMethod;
