import { registerOptimizeExperiment } from '@/api/optimize';
import currentCustomerStore from '@/stores/currentCustomerStore';
import { trackCustomEvent } from '@/utils/analytics';
import { useCookies } from '@vueuse/integrations/useCookies';
import * as Sentry from '@sentry/vue';

const useABTestDimension = (name: string) => {
  const cookies = useCookies(['nf_ab']);

  const netlifyCookie = cookies.get('nf_ab');

  if (!netlifyCookie) {
    Sentry.addBreadcrumb({
      category: 'netlify_variant',
      message: netlifyCookie,
      level: 'info',
    });
  }

  const customerStore = currentCustomerStore();
  const isMasterBranch =
    import.meta.env.VITE_BRANCH === 'master' ||
    import.meta.env.VITE_BRANCH === 'development' ||
    import.meta.env.VITE_BRANCH === 'main';

  const experimentId = `${name}_${isMasterBranch ? 0 : 1}`;

  window.netlify_experiment_id = experimentId;

  Sentry.addBreadcrumb({
    category: 'AB_test_triggered',
    message: experimentId,
    level: 'info',
  });

  trackCustomEvent('AB_test_triggered', {
    experiment_ID: experimentId,
    experiment_variant: isMasterBranch ? '0' : '1',
  });

  watch(
    () => customerStore.email,
    newEmail => {
      if (newEmail) {
        registerOptimizeExperiment({
          experimentId: name,
          variantId: isMasterBranch ? '0' : '1',
        });
      }
    },
    { immediate: true }
  );

  return;
};

export default useABTestDimension;
