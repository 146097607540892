import { axios } from '@/utils';
import normalize from '@/utils/normalize';
import { ApiResponse, CourierInstructions, ObjectToCamel } from '@pasta-evangelists/pasta-types';
import { decamelizeKeys } from 'humps';

export interface GetDeliveryDatesParams {
  beginDate: string;
  postcode?: string;
  endDate?: string;
  productVariantIds?: number[];
}

interface UnavailableProduct {
  product: number;
  dates: string[];
}

export interface GetDeliveryDatesServerResponse {
  unavailableDates: string[];
  unavailableProducts: UnavailableProduct[];
}

export const getDeliveryDates = async (
  params: GetDeliveryDatesParams
): Promise<GetDeliveryDatesServerResponse> => {
  const result = await axios.post<GetDeliveryDatesServerResponse>(
    '/constraints',
    decamelizeKeys(params)
  );
  return result.data;
};

export interface CheckForBlackoutDatesParams {
  postcode: string;
  deliveryDates: string[];
}

export interface CheckForBlackoutDatesServerResponse {
  postcode: string;
  deliveryDates: { deliveryDate: string; originalDeliveryDate: string | null }[];
}

export const checkForBlackoutDates = async (params: CheckForBlackoutDatesParams) => {
  try {
    const result = await axios.post<CheckForBlackoutDatesServerResponse>(
      '/delivery_dates',
      decamelizeKeys(params)
    );
    return { data: result.data, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong' } };
  }
};

export type CourierInstructionsParams =
  | {
      deliveryDate: string;
      postcode: string;
    }
  | undefined;

interface CourierInstructionsServerResponse {
  data: CourierInstructions;
  meta: {
    currentPage: number;
    lastPage: number;
    nextPage: number | null;
    perPage: number;
    prevPage: number | null;
    totalCount: number;
  };
}

export const getCourierInstructions = async (
  courierParams: CourierInstructionsParams
): ApiResponse<CourierInstructions> => {
  try {
    const result = await axios.get<ObjectToCamel<CourierInstructionsServerResponse>>(
      'courier_delivery_instructions',
      {
        params: decamelizeKeys(courierParams),
      }
    );
    return { data: result.data.data, error: null };
  } catch (e) {
    return { data: null, error: { message: 'Something went wrong!' } };
  }
};

interface DeliveryFeeConfigServerResponse {
  data: {
    id: string;
    type: 'delivery_fee_config';
    attributes: {
      name: string;
      price: string;
      threshold: string | null;
    };
    relationships: {
      children: {
        data: {
          id: string;
          type: 'delivery_fee_config_item';
        }[];
      };
    };
  };
  included: {
    id: string;
    type: 'delivery_fee_config_item';
    attributes: {
      name: string;
      price: string;
      threshold: null | string;
      weekDay: {
        index: number;
        name: string;
      };
    };
  }[];
}

export interface DeliveryFeeConfigItem {
  id: string;
  type: 'delivery_fee_config_item';
  attributes: {
    name: string;
    price: string;
    threshold: null | string;
    weekDay: {
      index: number;
      name: string;
    };
  };
}

export interface DeliveryFeeConfigNormalized {
  deliveryFeeConfig: Record<
    string,
    {
      id: '2';
      type: 'delivery_fee_config';
      attributes: {
        name: string;
        price: string;
        threshold: null | string;
      };
      relationships: {
        children: {
          data: {
            id: string;
            type: 'delivery_fee_config_item';
          }[];
        };
      };
    }
  >;
  deliveryFeeConfigItem?: Record<string, DeliveryFeeConfigItem>;
}

export const getDeliveryFees = async (): Promise<DeliveryFeeConfigNormalized> => {
  const result = await axios.get<DeliveryFeeConfigServerResponse>('delivery_fee_config');
  return normalize<DeliveryFeeConfigNormalized>(result.data);
};
