import currentCustomerStore from '@/stores/currentCustomerStore';
import pastaNowBasketStore from '@/stores/pastaNowBasketStore';
import { ManualAddressInputPayload } from '@/components/BaseAddressInputs.vue';
import { Coordinates } from '@/types';
import useUpdateTakeawayBasket from '@/api/mutations/pensa/updateTakeawayBasket';
import useCreateBasket from '@/api/mutations/pensa/createBasket';
import dayjs from 'dayjs';
import { RANDOM_UK_PHONE_NUMBER } from '@/constants/utils';
import useCheckDeliveryEstimation from '@/api/mutations/pensa/checkDeliveryEstimation';

interface UpdateBasketParams {
  address: ManualAddressInputPayload;
  coordinates: Coordinates;
  restaurantId: string;
  timeSlot: string;
  fulfillmentType?: 'delivery' | 'pickup';
}

const useChangePastaNowAddress = () => {
  const pastaNowBasket = pastaNowBasketStore();
  const customerStore = currentCustomerStore();
  const updateBasketMutation = useUpdateTakeawayBasket();
  const createBasketMutation = useCreateBasket();
  const checkDeliveryEstimate = useCheckDeliveryEstimation();

  const updateBasket = async ({
    coordinates,
    address,
    restaurantId,
    timeSlot,
    fulfillmentType,
  }: UpdateBasketParams) => {
    if (!pastaNowBasket.basketId) throw new Error('Has error and basket is destroyed');
    const fulfillment = fulfillmentType || pastaNowBasket.fulfillmentType || undefined;

    await updateBasketMutation.mutateAsync({
      id: pastaNowBasket.basketId,
      deliveryDate: pastaNowBasket.deliveryDate || '',
      timeSlot,
      restaurantId: restaurantId,
      fulfillmentType: fulfillment,
      address: {
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        zip: address.zip,
        phone: '',
        firstName: address.firstName || customerStore.firstName || '',
        lastName: address.lastName || customerStore.lastName || '',
        lat: coordinates.lat,
        lon: coordinates.lon,
        company: address.company,
        country: address.country,
        countryCode: address.countryCode || 'UK',
        region: address.region,
        regionCode: address.regionCode,
      },
    });

    return true;
  };

  const clearBasketAndCreateNewOne = async ({
    address,
    restaurantId,
    coordinates,
    fulfillmentType,
  }: Omit<UpdateBasketParams, 'timeSlot'>) => {
    await pastaNowBasket.clearBasket();

    await checkDeliveryEstimate.mutateAsync({
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      lat: coordinates.lat,
      lon: coordinates.lon,
      zip: address.zip,
      phone: RANDOM_UK_PHONE_NUMBER,
      restaurantId: restaurantId,
      timeSlot: 'asap',
      country: address.country,
    });

    await createBasketMutation.mutateAsync({
      fulfillmentType: fulfillmentType || 'delivery',
      deliveryDate: dayjs().format('YYYY-MM-DD'),
      timeSlot: 'asap',
      restaurantId: restaurantId,
      type: 'TakeawayBasket',
      address: {
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        zip: address.zip,
        phone: '',
        firstName: address.firstName || customerStore.firstName || '',
        lastName: address.lastName || customerStore.lastName || '',
        lat: coordinates.lat,
        lon: coordinates.lon,
        company: address.company,
        country: address.country,
        countryCode: address.countryCode || 'UK',
        region: address.region,
        regionCode: address.regionCode,
      },
    });
  };

  return { updateBasket, clearBasketAndCreateNewOne };
};

export default useChangePastaNowAddress;
